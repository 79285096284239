import React, {Component} from 'react';
import Header from '../componentes/Header';
import Sidebar from '../componentes/Sidebar';
import Content from '../componentes/Content';
import Footer from '../componentes/Footer';


class Home extends Component {
  render () {
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <Content />
        <Footer />
      </div>
    );
  }
}

export default Home;
