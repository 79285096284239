import React, { Component } from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import { Redirect, NavLink } from 'react-router-dom';
import EmailValidator from 'email-validator';

class AddUsuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: '',
      usuario: '',
      senha: '',
      funcao: 'Supervisor',
      status: 'Ativo',
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeUsuario = this.onChangeUsuario.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeSenha = this.onChangeSenha.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onChangeNome(e) {
    this.setState({
      nome: e.target.value,
    });
  }

  onChangeSenha(e) {
    this.setState({
      senha: e.target.value,
    });
  }

  onChangeUsuario(e) {
    this.setState({
      usuario: e.target.value,
    });
  }

  onChangeStatus(e) {
    this.setState({
      status: e.target.value,
    });
  }

  salvar() {
    var ok = true;
    if (!EmailValidator.validate(this.state.usuario)) {
      ok = false;
      alert('email invalido,');
    }

    if (ok) {
      var md5 = require('md5');
      var data = {
        nome: this.state.nome,
        usuario: this.state.usuario,
        funcao: this.state.funcao,
        status: this.state.status,
        senha: md5(this.state.senha),
      };

      ApiService.salvarusuario(data)
        .then(response => {
          this.setState({
            submitted: true,
          });
          console.log(response.data);
        })
        .catch(e => {
          this.setState({ message: 'Erro ao cadastrar usuario' });
          console.log(e);
        });
    }
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/usuarios" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: '640px' }}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Adicionar Usuario</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Adicionar Usuario
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomeusuario">Nome:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="nomeusuario"
                        placeholder="Insira o nome "
                        required
                        value={this.state.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{ width: '250px' }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="usuario">Email:</label>
                      <input
                        type="email"
                        maxLength="100"
                        className="form-control"
                        id="usuario"
                        placeholder="Insira o email"
                        required
                        value={this.state.usuario}
                        onChange={this.onChangeUsuario}
                        name="usuario"
                        style={{ width: '250px' }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="senha">Senha:</label>
                      <input
                        type="password"
                        maxLength="100"
                        className="form-control"
                        id="senha"
                        placeholder="Insira a senha"
                        required
                        value={this.state.senha}
                        onChange={this.onChangeSenha}
                        name="senha"
                        style={{ width: '15%' }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        className="form-control select2"
                        style={{ width: '10%' }}
                        value={this.state.status}
                        onChange={this.onChangeStatus}
                        name="status"
                      >
                        <option defaultValue value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>
                  </div>


                  <div className="card-footer">
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <button className="btn btn-primary" onClick={this.salvar}>
                              Adicionar
                            </button>
                          

                            <NavLink to={'/usuarios/'} className="nav-link">
                              <button className="btn btn-danger" onClick={this.voltar}>
                                Voltar
                              </button>
                            </NavLink>
                          </div>
                        
                  </div>


                </div>
              </div>
            </div>
          </section>

        </div>
        <Footer />
      </div>
    );
  }
}

export default AddUsuarios;
