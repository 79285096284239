import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import {Redirect, NavLink} from 'react-router-dom';

class AddSubCategorias extends Component {
  constructor (props) {
    super (props);
    this.state = {
      nome: '',
      status: 'Ativo',
      categoria: null,
      message: null,
      submitted: false,
      categorias: [],
    };
    this.salvar = this.salvar.bind (this);
    this.onChangeNome = this.onChangeNome.bind (this);
    this.onChangeStatus = this.onChangeStatus.bind (this);
    this.onChangeCategoria = this.onChangeCategoria.bind (this);
  }
  componentDidMount () {
    window.scrollTo (0, 0);

    ApiService.categorias ()
      .then (response => {
        this.setState ({
          categorias: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao listar categorias'});
        console.log (e);
      });
  }

  onChangeNome (e) {
    this.setState ({
      nome: e.target.value,
    });
  }

  onChangeStatus (e) {
    this.setState ({
      status: e.target.value,
    });
  }

  onChangeCategoria (e) {
    this.setState ({
      categoria: e.target.value,
    });
  }

  salvar () {
    var data = {
      nome: this.state.nome,
      status: this.state.status,
      categoria: {id: this.state.categoria},
    };

    ApiService.salvarsubcategoria (data)
      .then (response => {
        this.setState ({
          submitted: true,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao cadastrar subcategoria'});
        console.log (e);
      });
  }

  render () {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/subcategorias" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '640px'}}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Adicionar SubCategoria</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Adicionar SubCategoria
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomecategoria">
                        Nome da SubCategoria:
                      </label>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="nomecategoria"
                        placeholder="Insira o nome da nova subcategoria"
                        required
                        value={this.state.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{ width: '460px' }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Categoria:</label>
                      <select
                        required
                        className="form-control select2"
                        style={{ width: '460px' }}
                        value={this.state.categoria}
                        onChange={this.onChangeCategoria}
                        name="categoria"
                      >
                        <option defaultValue>Selecione</option>
                        {this.state.categorias &&
                          this.state.categorias.map (categoria => (
                            <option value={categoria.id}>
                              {categoria.id + '- ' + categoria.nome}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        className="form-control select2"
                        value={this.state.status}
                        onChange={this.onChangeStatus}
                        name="status"
                        style={{ width: '12%' }}
                      >
                        <option defaultValue value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>

                    

                  </div>

                  <div className="card-footer">
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <button className="btn btn-primary" onClick={this.salvar}>
                      Adicionar
                    </button>
                                   
                    <NavLink to={'/subcategorias/'} className="nav-link">
                      <button className="btn btn-danger" onClick={this.voltar}>
                        Voltar
                      </button>
                    </NavLink>
                    </div>
                        
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default AddSubCategorias;
