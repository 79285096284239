import React, {Component} from 'react';
import {instanceOf} from 'prop-types';
import {withCookies, Cookies} from 'react-cookie';
import {Redirect} from 'react-router-dom';

class Sair extends Component {
  static propTypes = {
    cookies: instanceOf (Cookies).isRequired,
  };

  state = {
    user: this.props.cookies.get ('userd')||"",
  };


  componentDidMount () {
    this.sair ();
  }

  sair () {
    localStorage.setItem('cliente','');
    const {cookies} = this.props;
    cookies.set ('userd', "", {path: '/'});
  }

  render () {
    return <Redirect to="/login" />;
  }
}

export default withCookies (Sair);
