import React, { Component } from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import { Redirect, NavLink } from 'react-router-dom';
import ImageUploader from 'react-images-upload';

class VerDestaques extends Component {
  constructor(props) {
    super(props);
    console.log('akiiiiaaa');
    this.state = {
      destaque: {
        id: null,
        nome: '',
        link: '',
        pictures: [],
        status: '',
        dataaltera: null,
        usuarioaltera: null,
      },
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
  }
  componentDidMount() {
    console.log(this.props.match.params.id + 'kkkkk');
    ApiService.destaque(this.props.match.params.id)
      .then(response => {
        this.setState({
          destaque: response.data,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao atualizar destaque' });
        console.log(e);
      });


    ApiService.fotosdestaque(this.props.match.params.id)
      .then(response => {
        this.setState({
          pictures: response.data,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao listar fotos do destaque' });
        console.log(e);
      });
    window.scrollTo(0, 0);
  }

  onChangeNome(e) {
    const nomex = e.target.value;

    this.setState(prevState => ({
      destaque: {
        ...prevState.destaque,
        nome: nomex,
      },
    }));
  }

  onChangeStatus(e) {
    const statusx = e.target.value;

    this.setState(prevState => ({
      destaque: {
        ...prevState.destaque,
        status: statusx,
      },
    }));
  }

  salvar() {
    var data = {
      id: this.state.destaque.id,
      nome: this.state.destaque.nome,
      status: this.state.destaque.status,
    };

    ApiService.atualizardestaque(data)
      .then(response => {
        this.setState({
          submitted: true,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao atualizar destaque' });
        console.log(e);
      });
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/destaques" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: '640px' }}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Ver Destaque</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Ver Destaque
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomedestaque">Código do Destaque:</label>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={this.state.destaque.id}
                        name="id"
                        disabled
                        style={{ width: '10%' }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="nomedestaque">Nome do Destaque:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="nomedestaque"
                        placeholder="Insira o nome da nova destaque"
                        required
                        value={this.state.destaque.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{ width: '400px' }}
                      />
                    </div>


                    <div className="form-group">
                      <label htmlFor="linkdestaque">Link do Destaque:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="500"
                        className="form-control"
                        id="linkdestaque"
                        placeholder="Insira o link da nova destaque"
                        required
                        value={this.state.destaque.link}
                        onChange={this.onChangeNome}
                        name="link"
                        style={{ width: '400px' }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        disabled={true}
                        className="form-control select2"
                        style={{ width: '12%' }}
                        value={this.state.destaque.status}
                        onChange={this.onChangeStatus}
                        name="status"
                      >
                        <option value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>

                    {this.state.pictures &&
                      <div className="form-group">
                        <img
                          src={
                            'data:image/jpeg;base64,' + this.state.pictures.img
                          }
                          width="900px"
                          height="300px"
                          alt={this.state.pictures.foto}
                        />

                      </div>}
                  </div>

                  <div className="card-footer">
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <NavLink
                              to={`/editar-destaque/${this.state.destaque.id}`}
                              className="nav-link"
                            >
                              <button type="button" className="btn btn-info">
                                Editar
                              </button>
                            </NavLink>
                          
                            <NavLink to={'/destaques/'} className="nav-link">
                              <button className="btn btn-danger" onClick={this.voltar}>
                                Voltar
                              </button>
                            </NavLink>
                          </div>                        

                  </div>

                </div>
              </div>
            </div>
          </section>

        </div>
        <Footer />
      </div>
    );
  }
}

export default VerDestaques;
