import React, { Component } from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import { Redirect, NavLink } from 'react-router-dom';
import {cpf} from 'cpf-cnpj-validator';
import InputMask from 'react-input-mask';

class EditFretes extends Component {
  constructor(props) {
    super(props);
    console.log('akiiiiaaa');
    this.state = {
      frete: {
        id: null,
        nome: '',
        status: '',
        regiao:'',
        valor:0,
        dataaltera: null,
        usuarioaltera: null,
      },
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeRegiao = this.onChangeRegiao.bind (this);
    this.onChangeValor = this.onChangeValor.bind (this);
  }
  componentDidMount() {
    console.log(this.props.match.params.id + 'kkkkk');
    ApiService.frete(this.props.match.params.id)
      .then(response => {
        this.setState({
          frete: response.data,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao atualizar frete' });
        console.log(e);
      });
    window.scrollTo(0, 0);
  }

  onChangeRegiao (e) {
    const nomex = e.target.value;

    this.setState(prevState => ({
      frete: {
        ...prevState.frete,
        regiao: nomex
      }
    }));
  }

  
  onChangeValor(e) {
    const nomex = e.target.value;

    this.setState(prevState => ({
      frete: {
        ...prevState.frete,
        valor: nomex
      }
    }));
  }
 

  onChangeNome(e) {
    const nomex = e.target.value;

    this.setState(prevState => ({
      frete: {
        ...prevState.frete,
        nome: nomex
      }
    }));
  }

  onChangeStatus(e) {
    const statusx = e.target.value;

    this.setState(prevState => ({
      frete: {
        ...prevState.frete,
        status: statusx
      }
    }));
  }

  salvar() {

    var ok = true;

  

    if (this.state.frete.nome.length === 0) {
      ok = false;
      alert (' nome invalido,');
    }
    if (ok) {

    var data = {
      id: this.state.frete.id,
      nome: this.state.frete.nome,
      regiao:this.state.frete.regiao,
      status: this.state.frete.status,
      valor:this.state.frete.valor,
    };

    ApiService.atualizarfrete(data)
      .then(response => {
        this.setState({
          submitted: true,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao atualizar frete' });
        console.log(e);
      });
    }
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/fretes" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: '640px' }}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Editar Frete</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Editar Frete
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                    
                  <div className="card-body">



                  <div className="form-group">
                      <label htmlFor="nomefrete">Código do Frete:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.frete.id}
                        name="id"
                        disabled
                        style={{width: '12%'}}
                      />
                    </div>   

                  <div className="form-group">
                      <label htmlFor="nomefrete">Nome do Frete:</label>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="nomefrete"
                        placeholder="Insira o nome do frete"
                        required
                        value={this.state.frete.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{width: '300px'}}
                      />
                    </div> 

                   

                    <div className="form-group">
                      <label htmlFor="nomefrete">Regiao:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="nomefrete"
                        placeholder="Insira o endereco do frete"
                        required
                        value={this.state.frete.regiao}
                        onChange={this.onChangeRegiao}
                        name="nome"
                        style={{ width: '250px' }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="valor">
                        Valor:
                      </label>
                      <input
                        type="number"
                        min="0.00"
                        max={
                          this.state.percentualreal==='Percentual'
                            ? '99.99'
                            : '10000.00'
                        }
                        step="0.01"
                        className="form-control"
                        id="Valor"
                        required
                        placeholder="Insira o Valor "
                        value={this.state.frete.valor}
                        onChange={this.onChangeValor}
                        name="Valor"
                        style={{width: '12%'}}
                      />
                    </div>
                                 
                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        className="form-control select2"
                        style={{ width: '100%' }}
                        value={this.state.frete.status}
                        onChange={this.onChangeStatus}
                        name="status"
                        style={{width: '12%'}}
                      >
                        <option value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>
                  </div>

                  <div className="card-footer">
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <button className="btn btn-primary" onClick={this.salvar}>
                              Salvar
                            </button>
                         


                            <NavLink to={'/fretes/'} className="nav-link">
                              <button className="btn btn-danger" onClick={this.voltar}>
                                Voltar
                            </button>
                            </NavLink>
                          </div>
                        
                  </div>

                </div>

              </div>

            </div>

          </section>

        </div>
        <Footer />
      </div>
    );
  }
}

export default EditFretes;
