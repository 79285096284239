import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import {Redirect, NavLink} from 'react-router-dom';
import {cpf} from 'cpf-cnpj-validator';
import InputMask from 'react-input-mask';

class EditMotoBoys extends Component {
  constructor (props) {
    super (props);
    console.log ('akiiiiaaa');
    this.state = {
      motoboy: {
        id: null,
        nome: '',
        status: '',
        dataaltera: null,
        usuarioaltera: null,
      },
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind (this);
    this.onChangeNome = this.onChangeNome.bind (this);
    this.onChangeStatus = this.onChangeStatus.bind (this);
    this.onChangeEndereco = this.onChangeEndereco.bind (this);
    this.onChangeCpf = this.onChangeCpf.bind (this);
    this.onChangePlaca = this.onChangePlaca.bind (this);
    this.onChangeModelo = this.onChangeModelo.bind (this);
    this.onChangeSenhaapp = this.onChangeSenhaapp.bind (this);
  }
  componentDidMount () {
    console.log (this.props.match.params.id + 'kkkkk');
    ApiService.motoboy (this.props.match.params.id)
      .then (response => {
        this.setState ({
          motoboy: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao atualizar motoboy'});
        console.log (e);
      });
    window.scrollTo (0, 0);
  }

  onChangeSenhaapp (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      motoboy: {
        ...prevState.motoboy,
        senhaapp: nomex,
      },
    }));
  }

  onChangePlaca (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      motoboy: {
        ...prevState.motoboy,
        placa: nomex,
      },
    }));
  }

  onChangeModelo (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      motoboy: {
        ...prevState.motoboy,
        modelo: nomex,
      },
    }));
  }

  onChangeEndereco (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      motoboy: {
        ...prevState.motoboy,
        endereco: nomex,
      },
    }));
  }

  onChangeCpf (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      motoboy: {
        ...prevState.motoboy,
        cpf: nomex,
      },
    }));
  }

  onChangeNome (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      motoboy: {
        ...prevState.motoboy,
        nome: nomex,
      },
    }));
  }

  onChangeStatus (e) {
    const statusx = e.target.value;

    this.setState (prevState => ({
      motoboy: {
        ...prevState.motoboy,
        status: statusx,
      },
    }));
  }

  salvar () {
    var ok = true;

    if (
      !cpf.isValid (
        this.state.motoboy.cpf
          .replace ('-', '')
          .replaceAll ('\.', '')
          .replace ('_', '')
      )
    ) {
      ok = false;
      alert (' cpf invalido.');
    }

    if (this.state.motoboy.nome.length === 0) {
      ok = false;
      alert (' nome invalido,');
    }
    if (ok) {
      var data = {
        id: this.state.motoboy.id,
        nome: this.state.motoboy.nome,
        placa: this.state.motoboy.placa,
        modelo: this.state.motoboy.modelo,
        status: this.state.motoboy.status,
        cpf: this.state.motoboy.cpf
          .replace ('-', '')
          .replaceAll ('\.', '')
          .replace ('_', ''),
        endereco: this.state.motoboy.endereco,
        senhaapp: this.state.motoboy.senhaapp,
      };

      ApiService.atualizarmotoboy (data)
        .then (response => {
          this.setState ({
            submitted: true,
          });
          console.log (response.data);
        })
        .catch (e => {
          this.setState ({message: 'Erro ao atualizar motoboy'});
          console.log (e);
        });
    }
  }

  render () {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/motoboys" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '640px'}}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Editar MotoBoy</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Editar MotoBoy
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomemotoboy">Código do MotoBoy:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.motoboy.id}
                        name="id"
                        disabled
                        style={{width: '13%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomemotoboy">Nome do MotoBoy:</label>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="nomemotoboy"
                        placeholder="Nome do entregador"
                        required
                        value={this.state.motoboy.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{width: '300px'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomemotoboy">CPF:</label>

                      <InputMask
                        id="cpf"
                        placeholder="Digite o CPF"
                        className="form-control"
                        mask="999.999.999-99"
                        style={{width: '13%'}}
                        value={this.state.motoboy.cpf}
                        onChange={this.onChangeCpf}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomemotoboy">Endereço:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="nomemotoboy"
                        placeholder="Insira o endereco do motoboy"
                        required
                        value={this.state.motoboy.endereco}
                        onChange={this.onChangeEndereco}
                        name="nome"
                        style={{width: '400px'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomemotoboy">Placa:</label>
                      <input
                        type="text"
                        maxLength="10"
                        className="form-control"
                        id="nomemotoboy"
                        placeholder="Insira a placa"
                        required
                        value={this.state.motoboy.placa}
                        onChange={this.onChangePlaca}
                        name="nome"
                        style={{width: '13%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomemotoboy">Modelo:</label>
                      <input
                        type="text"
                        maxLength="20"
                        className="form-control"
                        id="nomemotoboy"
                        placeholder="Insira o modelo"
                        required
                        value={this.state.motoboy.modelo}
                        onChange={this.onChangeModelo}
                        name="nome"
                        style={{width: '13%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomemotoboy">Senha App:</label>
                      <input
                        type="password"
                        maxLength="100"
                        className="form-control"
                        id="nomemotoboy"
                        placeholder="Senha Motoboy"
                        required
                        value={this.state.motoboy.senhaapp}
                        onChange={this.onChangeSenhaapp}
                        name="nome"
                        style={{width: '13%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        className="form-control select2"
                        style={{width: '100%'}}
                        value={this.state.motoboy.status}
                        onChange={this.onChangeStatus}
                        name="status"
                        style={{width: '13%'}}
                      >
                        <option value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>
                  </div>

                  <div className="card-footer">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <button className="btn btn-primary" onClick={this.salvar}>
                        Salvar
                      </button>

                      <NavLink to={'/motoboys/'} className="nav-link">
                        <button
                          className="btn btn-danger"
                          onClick={this.voltar}
                        >
                          Voltar
                        </button>
                      </NavLink>
                    </div>

                  </div>

                </div>

              </div>

            </div>

          </section>

        </div>
        <Footer />
      </div>
    );
  }
}

export default EditMotoBoys;
