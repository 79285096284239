import {Polyline, GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer} from '@react-google-maps/api';
import React, {Component, Image} from 'react';

const mapContainerStyle = {
  height: '400px',
  width: '800px',
};

const center = {
  lat: -22.224278333333338,
  lng: -45.92028333333333,
};

const onLoad = polyline => {
  console.log ('polyline: ', polyline);
};

const path = [
  {
    lat: -22.224278333333338,
    lng: -45.92028333333333,
  },
  {
    lat: -22.2277513,
    lng: -45.92028333333333,
  },
];

const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  paths: [
    {
      lat: -22.224278333333338,
      lng: -45.92028333333333,
    },
    {
      lat: -22.2277513,
      lng: -45.92028333333333,
    },
  ],
  zIndex: 1,
};
class TesteMapa extends Component {
  constructor (props) {
    super(props)

    this.state = {
      response: null,
      travelMode: 'DRIVING',
      origin:  {
        lat: -22.224278333333338,
        lng: -45.92028333333333,
      },
      destination: {
        lat: -22.2277513,
        lng: -45.92028333333333,
      }
    }

    this.directionsCallback = this.directionsCallback.bind(this)
  }

  directionsCallback (response) {
    console.log(response)

    if (response !== null) {
      if (response.status === 'OK') {
        this.setState(
          () => ({
            response
          })
        )
      } else {
        console.log('response: ', response)
      }
    }
  }
  render () {
    return (
      <LoadScript googleMapsApiKey={'AIzaSyCFkh_qWb43L2hFec0g-DcxvEUwDL0tSDE'}>
        <GoogleMap
          id="marker-example"
          mapContainerStyle={mapContainerStyle}
          zoom={15}
          center={center}
        >
          

          {
              (
                this.state.destination !== '' &&
                this.state.origin !== ''
              ) && (
                <DirectionsService
                  // required
                  options={{ 
                    destination: this.state.destination,
                    origin: this.state.origin,
                    travelMode: this.state.travelMode
                  }}
                  // required
                  callback={this.directionsCallback}
                  // optional
                  onLoad={directionsService => {
                    console.log('DirectionsService onLoad directionsService: ', directionsService)
                  }}
                  // optional
                  onUnmount={directionsService => {
                    console.log('DirectionsService onUnmount directionsService: ', directionsService)
                  }}
                />
              )
            }

            {
              this.state.response !== null && (
                <DirectionsRenderer
                  // required
                  options={{ 
                    directions: this.state.response
                  }}
                  // optional
                  onLoad={directionsRenderer => {
                    console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                  }}
                  // optional
                  onUnmount={directionsRenderer => {
                    console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                  }}
                />
              )
            }

        </GoogleMap>
      </LoadScript>
    );
  }
}

export default TesteMapa;
