import React from 'react';
import {Switch, Route, BrowserRouter, Redirect} from 'react-router-dom';
import Home from './pages/Home';
//
import Clientes from './pages/clientes/Clientes';
import VerClientes from './pages/clientes/VerClientes';
//
import Pedidos from './pages/pedidos/Pedidos';
import VerPedidos from './pages/pedidos/VerPedidos';
//
import Categorias from './pages/categorias/Categorias';
import AddCategorias from './pages/categorias/AddCategorias';
import EditCategorias from './pages/categorias/EditCategorias';
import VerCategorias from './pages/categorias/VerCategorias';
//
import Usuarios from './pages/usuarios/Usuarios';
import AddUsuarios from './pages/usuarios/AddUsuarios';
import EditUsuarios from './pages/usuarios/EditUsuarios';
import VerUsuarios from './pages/usuarios/VerUsuarios';
//
import Destaques from './pages/destaques/Destaques';
import AddDestaques from './pages/destaques/AddDestaques';
import EditDestaques from './pages/destaques/EditDestaques';
import VerDestaques from './pages/destaques/VerDestaques';
//
import SubCategorias from './pages/subcategorias/SubCategorias';
import AddSubCategorias from './pages/subcategorias/AddSubCategorias';
import EditSubCategorias from './pages/subcategorias/EditSubCategorias';
import VerSubCategorias from './pages/subcategorias/VerSubCategorias';
//
import Produtos from './pages/produtos/Produtos';
import AddProdutos from './pages/produtos/AddProdutos';
import EditProdutos from './pages/produtos/EditProdutos';
import VerProdutos from './pages/produtos/VerProdutos';
//
import Descontos from './pages/descontos/Descontos';
import AddDescontos from './pages/descontos/AddDescontos';
import EditDescontos from './pages/descontos/EditDescontos';
import VerDescontos from './pages/descontos/VerDescontos';
import Login from './componentes/Login';
import Sair from './componentes/Sair';
import EditLoja from './pages/loja/EditLoja';
import Newsletters from './pages/newsletter/Newsletters';
import Emails from './pages/emails/Emails';
import VerEmails from './pages/emails/VerEmails';
import Transacaos from './pages/transacoes/Transacaos';
import VerTransacaos from './pages/transacoes/VerTransacao';
import Eventos from './pages/eventos/Eventos';
import VerEventos from './pages/eventos/VerEventos';
import Esitef from './pages/loja/Esitef';
import Cielo from './pages/loja/Cielo';
import Correios from './pages/loja/Correios';
import EditRedes from './pages/loja/EditRedes';
import EditChat from './pages/loja/EditChat';
import Pontos from './pages/pontos/Pontos';
import AddPontos from './pages/pontos/AddPontos';
import EditPontos from './pages/pontos/EditPontos';
import VerPontos from './pages/pontos/VerPontos';
import MotoBoys from './pages/motoboy/MotoBoys';
import AddMotoBoys from './pages/motoboy/AddMotoBoys';
import EditMotoBoys from './pages/motoboy/EditMotoBoys';
import VerMotoBoys from './pages/motoboy/VerMotoBoys';
import CashBacks from './pages/cashback/CashBacks';
import AddCashBacks from './pages/cashback/AddCashBacks';
import EditCashBacks from './pages/cashback/EditCashBacks';
import VerCashBacks from './pages/cashback/VerCashBacks';
import Fretes from './pages/frete/Fretes';
import AddFretes from './pages/frete/AddFretes';
import EditFretes from './pages/frete/EditFretes';
import VerFretes from './pages/frete/VerFretes';
import EditCores from './pages/loja/EditCores';
import EditLogos from './pages/loja/EditLogos';
import Vendas from './pages/relatorios/Vendas';
import VendasCliente from './pages/relatorios/VendasCliente';
import VendasPagamento from './pages/relatorios/VendasPagamento';
import EditEmail from './pages/loja/EditEmail';
import EditNFE from './pages/loja/EditNFE';
import EditGooglemaps from './pages/loja/EditGooglemaps';
import Rastreio from './pages/motoboy/Rastreio';
import TesteMapa from './pages/motoboy/TesteMapa';
import VerContatos from './pages/contatos/VerContatos';
import Contatos from './pages/contatos/Contatos';
import ConfigBin from './pages/loja/ConfigBin';
import Rede from './pages/loja/Rede';
import GetNet from './pages/loja/GetNet';
import TransacaosBin from './pages/transacoes-bin/TransacaosBin';
import VerTransacaosBin from './pages/transacoes-bin/VerTransacaoBin';
import TransacaosCielo from './pages/transacoes-cielo/TransacaosCielo';
import VerTransacaosCielo from './pages/transacoes-cielo/VerTransacaoCielo';
import TransacaosRede from './pages/transacoes-rede/TransacaosRede';
import TransacaosGetNet from './pages/transacoes-getnet/TransacaosGetNet';
import VerTransacaosRede from './pages/transacoes-rede/VerTransacaoRede';
import AddContatos from './pages/contatos/AddContatos';
import EditContatos from './pages/contatos/EditContatos';
import VerTransacaosGetNet from './pages/transacoes-getnet/VerTransacaoGetNet';
//

export default function Routes () {
  return (
    <BrowserRouter>
      <Switch>

        <Route exact path="/">
          <Redirect to="/home" />
        </Route>

        <Route path="/home" component={Home} />

        <Route path="/vendas-periodo" component={Vendas} />
        <Route path="/vendas-cliente" component={VendasCliente} />
        <Route path="/vendas-pagamento" component={VendasPagamento} />

        <Route path="/newsletters" component={Newsletters} />

        <Route path="/cores-loja" component={EditCores} />        
        <Route path="/logos-loja" component={EditLogos} />
        <Route path="/informacoes-loja" component={EditLoja} />
        <Route path="/configuracao-esitef" component={Esitef} />
        <Route path="/configuracao-cielo" component={Cielo} />
        <Route path="/configuracao-correios" component={Correios} />
        <Route path="/configuracao-bin" component={ConfigBin} />
        <Route path="/configuracao-email" component={EditEmail} />
        <Route path="/configuracao-nfe" component={EditNFE} />
        <Route path="/configuracao-redes" component={EditRedes} />
        <Route path="/configuracao-tawkto" component={EditChat} />
        <Route path="/configuracao-rede" component={Rede} />
        <Route path="/configuracao-getnet" component={GetNet} />
        <Route path="/configuracao-googlemaps" component={EditGooglemaps} />

        <Route path="/login" component={Login} />
        <Route path="/sair" component={Sair} />

        <Route path="/emails" component={Emails} />
        <Route path="/ver-email/:id" component={VerEmails} />

        <Route path="/contatos" component={Contatos} />
        <Route path="/ver-contato/:id" component={VerContatos} />
        <Route path="/adicionar-contato" component={AddContatos} />
        <Route path="/editar-contato" component={EditContatos} />

        <Route path="/eventos" component={Eventos} />
        <Route path="/ver-evento/:id" component={VerEventos} />

        <Route path="/transacoes-getnet" component={TransacaosGetNet} />
        <Route path="/ver-transacao-getnet/:id" component={VerTransacaosGetNet} />
        
        <Route path="/transacoes-rede" component={TransacaosRede} />
        <Route path="/ver-transacao-rede/:id" component={VerTransacaosRede} />

        <Route path="/transacoes-cielo" component={TransacaosCielo} />
        <Route path="/ver-transacao-cielo/:id" component={VerTransacaosCielo} />

        <Route path="/transacoes-bin" component={TransacaosBin} />
        <Route path="/ver-transacao-bin/:id" component={VerTransacaosBin} />

        <Route path="/transacoes" component={Transacaos} />
        <Route path="/ver-transacao/:id" component={VerTransacaos} />

        <Route path="/pedidos" component={Pedidos} />
        <Route path="/ver-pedido/:id" component={VerPedidos} />

        <Route path="/clientes" component={Clientes} />
        <Route path="/ver-cliente/:id" component={VerClientes} />

        <Route path="/categorias" component={Categorias} />
        <Route path="/adicionar-categoria" component={AddCategorias} />
        <Route path="/editar-categoria/:id" component={EditCategorias} />
        <Route path="/ver-categoria/:id" component={VerCategorias} />

        <Route path="/motoboys" component={MotoBoys} />
        <Route path="/adicionar-motoboy" component={AddMotoBoys} />
        <Route path="/editar-motoboy/:id" component={EditMotoBoys} />
        <Route path="/ver-motoboy/:id" component={VerMotoBoys} />
        <Route path="/rastreio" component={Rastreio} />
        <Route path="/teste-mapa" component={TesteMapa} />
        
        <Route path="/fretes" component={Fretes} />
        <Route path="/adicionar-frete" component={AddFretes} />
        <Route path="/editar-frete/:id" component={EditFretes} />
        <Route path="/ver-frete/:id" component={VerFretes} />

        <Route path="/usuarios" component={Usuarios} />
        <Route path="/adicionar-usuario" component={AddUsuarios} />
        <Route path="/editar-usuario/:id" component={EditUsuarios} />
        <Route path="/ver-usuario/:id" component={VerUsuarios} />

        <Route path="/descontos" component={Descontos} />
        <Route path="/adicionar-desconto" component={AddDescontos} />
        <Route path="/editar-desconto/:id" component={EditDescontos} />
        <Route path="/ver-desconto/:id" component={VerDescontos} />

        <Route path="/pontos" component={Pontos} />
        <Route path="/adicionar-ponto" component={AddPontos} />
        <Route path="/editar-ponto/:id" component={EditPontos} />
        <Route path="/ver-ponto/:id" component={VerPontos} />

        <Route path="/cashbacks" component={CashBacks} />
        <Route path="/adicionar-cashback" component={AddCashBacks} />
        <Route path="/editar-cashback/:id" component={EditCashBacks} />
        <Route path="/ver-cashback/:id" component={VerCashBacks} />

        <Route path="/destaques" component={Destaques} />
        <Route path="/adicionar-destaque" component={AddDestaques} />
        <Route path="/editar-destaque/:id" component={EditDestaques} />
        <Route path="/ver-destaque/:id" component={VerDestaques} />

        <Route path="/subcategorias" component={SubCategorias} />
        <Route path="/adicionar-subcategoria" component={AddSubCategorias} />
        <Route path="/editar-subcategoria/:id" component={EditSubCategorias} />
        <Route path="/ver-subcategoria/:id" component={VerSubCategorias} />

        <Route path="/produtos" component={Produtos} />
        <Route path="/adicionar-produto" component={AddProdutos} />
        <Route path="/editar-produto/:id" component={EditProdutos} />
        <Route path="/ver-produto/:id" component={VerProdutos} />



        
      </Switch>
    </BrowserRouter>
  );
}
