import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import {Redirect, NavLink} from 'react-router-dom';
//import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import Moment from 'react-moment';

class VerTransacaosBin extends Component {
  constructor (props) {
    super (props);
    console.log ('akiiiiaaa');
    this.state = {
      transacao: {
        id: null,
        merchant_usn: '',
        order_id: '',
        rstatus: '',
        status: '',
        rcode: '',
        code: '',
        nit: '',
        amount: '',
        authorizer_id: '',
        acquirer_name: '',
        authorizer_date: '',
        authorization_number: '',
        payment_date: '',
        payment_type: '',
        host_usn: '',
        customer_receipt: '',
        installments: '',
        dataaltera: null,
        usuarioaltera: null,
        esitef_usn: '',
        sitef_usn: '',
      },
      message: null,
      submitted: false,
    };

    this.consultar = this.consultar.bind (this);
  }
  consultar () {
    ApiService.consultatransancaobin(this.state.transacao)
      .then (response => {
        this.setState ({
          transacao: response.data,
        });
        this.setState ({message: response.data.aprovalcode});
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao consultar transacao'});
        console.log (e);
      });
      window.scrollTo (0, 0);
  }

  componentDidMount () {
    console.log (this.props.match.params.id + 'kkkkk');

    ApiService.transancaobin (this.props.match.params.id)
      .then (response => {
        this.setState ({
          transacao: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao atualizar transacao'});
        console.log (e);
      });
    window.scrollTo (0, 0);
  }

  render () {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/transacaos-bin" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '640px'}}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Ver Transação Bin</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Ver Transação Bin
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            {this.state.message &&
              <div className="alert alert-danger alert-dismissible">
                {this.state.message}
              </div>}
            <div className="row">

              <div className="col-md-5">
                <div className="card card-primary">

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nometransacao">Código Transação:</label>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={this.state.transacao.id}
                        name="id"
                   
                        //style={{ width: "12%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomeevento">Data Hora:</label>
                      <div style={{padding: '7px', alignItems: 'flex-start'}}>
                        <Moment format="DD/MM/YYYY HH:mm:ss">
                          {this.state.transacao.dataaltera}
                        </Moment>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="nometransacao">Transaction Id:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="nometransacao"
                        required
                        value={this.state.transacao.transactionid}
                        name="nome"
                        //style={{ width: "50%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="assunto">Pedido:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="assunto"
                        required
                        value={this.state.transacao.pedido}
                        name="assunto"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="assunto">Status:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="assunto"
                        required
                        value={
                          this.state.transacao.status 
                        }
                        name="assunto"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <div className="card card-primary">

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="assunto">Message:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="assunto"
                        required
                        value={
                          this.state.transacao.responsemessage
                        }
                        name="assunto"
                      />
                    </div>

                    {this.state.transacao.referencenumber &&
                      <div className="form-group">
                        <label htmlFor="assunto">Reference Number:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.referencenumber}
                          name="assunto"
                        />
                      </div>}

                    <div className="form-group">
                      <label htmlFor="assunto">Valor:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="assunto"
                        required
                        value={this.state.transacao.valor}
                        name="assunto"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="assunto">Parcelas:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="assunto"
                        required
                        value={this.state.transacao.parcelas}
                        name="assunto"
                      />
                    </div>

                  
                    {this.state.transacao.bandeira &&
                    <div className="form-group">
                      <label htmlFor="assunto">Bandeira/Cartao:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="assunto"
                        required
                        value={this.state.transacao.bandeira+' - '
                        +(this.state.transacao.finalcartao!==undefined?this.state.transacao.finalcartao:'')}
                        name="assunto"
                      />
                    </div>}
                 
                  </div>
                </div>
              </div>

            
                <div className="col-md-12">
                  <div className="card card-primary">

                    <div className="card-body">

                    <div className="form-group">
                        <label htmlFor="assunto">Erro:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.error}
                          name="assunto"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="assunto">Tipo Pagamento:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.tipo}
                          name="assunto"
                        />
                      </div>

                     

                      <div className="form-group">
                        <label htmlFor="assunto">Data de efetivação:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.tdatef}
                          name="assunto"
                        />
                      </div>

                      
                      <div className="form-group">
                        <label htmlFor="assunto">Approval code:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.aprovalcode}
                          name="assunto"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="assunto">P Approval code:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.paprovalcode}
                          name="assunto"
                        />
                      </div>

                      
                      <div className="form-group">
                        <label htmlFor="assunto">Avs Response:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.avsresponse}
                          name="assunto"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="assunto">Terminal Id:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.terminalid}
                          name="assunto"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="assunto">Transaction Time:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.transactiontime}
                          name="assunto"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="assunto">Response Code:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.responsecode}
                          name="assunto"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="assunto">Ccv Response:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.ccvresponse}
                          name="assunto"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="assunto">Calcula juros:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.calculajuros}
                          name="assunto"
                        />
                      </div>


                   
                    </div>
                  </div>
                </div>
            </div>

            <div className="card-footer">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >

                {(this.state.transacao.transactionid!==undefined) &&
                  <button className="btn btn-info" onClick={this.consultar}>
                    Consultar
                  </button>}
                <NavLink to={'/transacoes-bin/'} className="nav-link">
                  <button className="btn btn-danger" onClick={this.voltar}>
                    Voltar
                  </button>
                </NavLink>
              </div>
            </div>
          </section>

        </div>
        <Footer />

      </div>
    );
  }
}

export default VerTransacaosBin;
