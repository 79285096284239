import React, { Component } from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import { Redirect, NavLink } from 'react-router-dom';

class VerCashBacks extends Component {
  constructor(props) {
    super(props);
    console.log('akiiiiaaa');
    this.state = {
      cashback: {
        itens: [],
        id: null,
        nome: '',
        status: '',
        percentualreal: 'Percentual',
        valor: 0,
      //  dataexpiracao: new Date(),
        itens: [],
        dataaltera: null,
        usuarioaltera: null,
      },
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
  }
  componentDidMount() {
    console.log(this.props.match.params.id + 'kkkkk');
    ApiService.cashback(this.props.match.params.id)
      .then(response => {
        this.setState({
          cashback: response.data,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao atualizar cashback' });
        console.log(e);
      });
      
    window.scrollTo(0, 0);
  }

  onChangeNome(e) {
    const nomex = e.target.value;

    this.setState(prevState => ({
      cashback: {
        ...prevState.cashback,
        nome: nomex,
      },
    }));
  }

  onChangeStatus(e) {
    const statusx = e.target.value;

    this.setState(prevState => ({
      cashback: {
        ...prevState.cashback,
        status: statusx,
      },
    }));
  }

  salvar() {
    var data = {
      id: this.state.cashback.id,
      nome: this.state.cashback.nome,
      status: this.state.cashback.status,
    };

    ApiService.atualizarcashback(data)
      .then(response => {
        this.setState({
          submitted: true,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao atualizar cashback' });
        console.log(e);
      });
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/cashbacks" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: '640px' }}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Ver CashBack</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Ver CashBack
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                  <div style={{display: 'flex',  justifyContent:'flex-start', alignItems:'center'}}>
                    <div className="form-group">
                      <label htmlFor="nomecashback">Código do CashBack:</label>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={this.state.cashback.id}
                        name="id"
                        disabled
                        style={{width: '180px'}}
                      />
                    </div>

                    &nbsp; &nbsp;

                    <div className="form-group">
                      <label htmlFor="nomecashback">Nome da CashBack:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="nomecashback"
                        placeholder="Insira o nome da nova cashback"
                        required
                        value={this.state.cashback.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{width:"460px"}}
                      />
                    </div>       

                    &nbsp; &nbsp;           

                    <div className="form-group">
                      <label>Tipo:</label>
                      <select
                        disabled={true}
                        className="form-control select2"
                        style={{width: '180px'}}
                        value={this.state.cashback.percentualreal}
                        onChange={this.onChangePercentual}
                        name="percentual"
                      >
                        <option value="Percentual">
                          Percentual
                        </option>
                        <option value="Real">Reais</option>
                      </select>
                    </div>

                    &nbsp; &nbsp;

                    <div className="form-group">
                      <label htmlFor="valor">
                        Valor:
                      </label>
                      <input
                        type="number"
                        min="0.00"
                        max={
                          this.state.cashback.percentualreal==='Percentual'
                            ? '99.99'
                            : '10000.00'
                        }
                        step="0.01"
                        className="form-control"
                        id="Valor"
                        disabled={true}
                        placeholder="Insira o Valor "
                        value={this.state.cashback.valor}
                        onChange={this.onChangeValor}
                        name="Valor"
                        style={{width: '180px'}}
                      />
                    </div>
                  </div>

                  <div style={{display: 'flex',  justifyContent:'flex-start', alignItems:'center'}}>      
                    <div className="form-group">
                      <label htmlFor="valor">
                      Equivale em R$:
                      </label>
                      <input
                        type="number"
                        min="1.00"
                        max={"1000"}
                        step="1"
                        disabled={true}
                        className="form-control"
                        id="Valor"
                        required
                        placeholder="Insira o equivalente "
                        value={this.state.cashback.equivale}
                        onChange={this.onChangeEquivale}
                        name="Valor"
                        style={{width: '180px'}}
                      />
                    </div>     

                    &nbsp; &nbsp;              

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        disabled={true}
                        className="form-control select2"
                        style={{width: '180px'}}
                        value={this.state.cashback.status}
                        onChange={this.onChangeStatus}
                        name="status"
                      >
                        <option value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>
                  </div>
                  </div>      
                  </div>
                </div>              

              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body">

                  <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Tipo</th>
                          <th>Codigo</th>
                          <th>Nome</th>
                     
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.cashback.itens &&
                          this.state.cashback.itens.map (item => (
                            <tr
                              key={
                                item.categoria!==undefined
                                  ? item.categoria.id
                                  : item.subcategoria.id
                              }
                            >
                              <td>{item.tipo}</td>
                              <td>
                                {item.categoria!==undefined
                                  ? item.categoria.id
                                  : item.subcategoria.id}
                              </td>
                              <td>
                                {item.categoria!==undefined
                                  ? item.categoria.nome
                                  : item.subcategoria.nome}
                              </td>                              
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    </div> 
                  </div>

                  <div className="card-footer">
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <NavLink
                              to={`/editar-cashback/${this.state.cashback.id}`}
                              className="nav-link"
                            >
                              <button type="button" className="btn btn-info">
                                Editar
                             </button>
                            </NavLink>                          

                            <NavLink to={'/cashbacks/'} className="nav-link">
                              <button className="btn btn-danger" onClick={this.voltar}>
                                Voltar
                             </button>
                            </NavLink>
                          </div>                        
                  </div>                
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default VerCashBacks;
