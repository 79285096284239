import axios from "axios";
import ConfigJson from "./config.json";

class ApiService {
  apiurl() {
    var url = window.location.href;
    //console.log(window.location)
    let domain = window.location.hostname;
    console.log('dominio '+domain)
    var api = this.serverapi(window.location.href)
    console.log('api '+api)
    if (api == undefined) {
      if (url.includes("https://")) {
        return "https://" + domain + ":" + ConfigJson.porta_tomcat_seguro;
      } else {
        return "http://" + domain + ":" + ConfigJson.porta_tomcat;
      }
    } else {
      return api;
    }
  }

  header() {
    //console.log ('site url' + window.location.href);
    var h = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        tid: this.site(window.location.href),
      },
      timeout: 30000,
    };
    return h;
  }


  headersemtempo() {
    //console.log ('site url' + window.location.href);
    var h = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        tid: this.site(window.location.href),
      },
      // timeout: 30000,
    };
    return h;
  }

  headerc() {
    //console.log ('site url' + window.location.href);
    var h = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "POST, GET",
      },
      timeout: 30000,
    };
    return h;
  }


  serverapi(url) {
    //   console.log(url);
    // console.log(ConfigJson.sites)
    var site = ConfigJson.sites.filter((l) => {
      return url.includes(l.dominio.toLowerCase());
    });
    //  console.log(site)
    if (site.length > 0) {
      return site[0].api;
    } else {
      return undefined
    }
  }


  site(url) {
    //   console.log(url);
    // console.log(ConfigJson.sites)
    var site = ConfigJson.sites.filter((l) => {
      return url.includes(l.dominio.toLowerCase());
    });
    //  console.log(site)
    if (site.length > 0) {
      return site[0].cnpj;
    } else {
      window.location.assign("http://google.com");
    }
  }

  headerd() {
    var h = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        tid: this.site(window.location.href),
      },
      timeout: 10000,
      responseType: "arraybuffer",
    };
    return h;
  }

  //PEDIDOS
  ultimos() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/pedidos/ultimos",
      this.header()
    );
    return g;
  }

  //PEDIDOS
  pedidosenviados() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/pedidos/enviados",
      this.header()
    );
    return g;
  }

  directions(url) {
    var g = axios.get(url, this.headerc());
    return g;
  }

  pedidosenviadosx() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/pedidos/enviadosx",
      this.header()
    );
    return g;
  }

  ultimoscli(email) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/pedidos/ultimos/cliente/" + email,
      this.header()
    );
    return g;
  }

  pedidos() {
    var g = axios.get(this.apiurl() + "/ecommerce/pedidos", this.header());
    return g;
  }

  pedido(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/pedidos/" + id,
      this.header()
    );
    console.log(g);
    return g;
  }

  //CLIENTES
  clientes() {
    var g = axios.get(this.apiurl() + "/ecommerce/clientes", this.header());
    return g;
  }

  cliente(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/clientes/id/" + id,
      this.header()
    );
    console.log(g);
    return g;
  }

  //CATEGORIAS
  pcategorias(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/categorias/pesquisar/p",
      data,
      this.header()
    );
    return g;
  }

  categorias() {
    var g = axios.get(this.apiurl() + "/ecommerce/categorias", this.header());
    return g;
  }

  categoria(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/categorias/" + id,
      this.header()
    );
    console.log(g);
    return g;
  }

  salvarcategoria(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/categorias",
      data,
      this.header()
    );
    return g;
  }

  atualizarcategoria(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/categorias",
      data,
      this.header()
    );
    return g;
  }

  //SUBCATEGORIAS
  psubcategorias(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/subcategorias/pesquisar/p",
      data,
      this.header()
    );
    return g;
  }

  segmento() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/lojas/segmento",
      this.header()
    );
    return g;
  }

  subcategorias() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/subcategorias",
      this.header()
    );
    return g;
  }

  subcategoria(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/subcategorias/" + id,
      this.header()
    );
    console.log(g);
    return g;
  }

  salvarsubcategoria(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/subcategorias",
      data,
      this.header()
    );
    return g;
  }

  atualizarsubcategoria(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/subcategorias",
      data,
      this.header()
    );
    return g;
  }

  //PRODUTOS

  pprodutos(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/produtos/pesquisar/p",
      data,
      this.header()
    );
    return g;
  }

  produtospesquisar(valor) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/produtos/pesquisar/" + valor,
      this.header()
    );
    return g;
  }

  importar() {
    var loja = {};
    loja.cnpjLoja = "";
    var g = axios.put(
      this.apiurl() + "/ecommerce/produtos/loja/importacao",
      loja,
      this.headersemtempo()
    );
    return g;
  }

  produtos() {
    var g = axios.get(this.apiurl() + "/ecommerce/produtos", this.header());
    return g;
  }

  produto(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/produtos/" + id,
      this.header()
    );
    console.log(g);
    return g;
  }

  kits(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/produtos/listarkit/" + id,
      this.header()
    );
    console.log(g);
    return g;
  }

  salvarproduto(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/produtos",
      data,
      this.header()
    );
    return g;
  }

  fotosproduto(produto) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/produtos/fotos/" + produto,
      this.header()
    );
    return g;
  }

  uploadproduto(file, produto, nome, padrao) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("produto", produto);
    formData.append("nome", nome);
    formData.append("padrao", padrao);

    var g = axios.post(
      this.apiurl() + "/ecommerce/produtos/upload",
      formData,
      this.header()
    );
    return g;
  }

  atualizarfoto(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/produtos/fotos",
      data,
      this.header()
    );
    return g;
  }

  excluirfoto(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/produtos/excluirfotos",
      data,
      this.header()
    );
    return g;
  }

  atualizarproduto(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/produtos",
      data,
      this.header()
    );
    return g;
  }

  //DESTAQUES
  destaques() {
    var g = axios.get(this.apiurl() + "/ecommerce/destaques", this.header());
    return g;
  }

  destaque(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/destaques/" + id,
      this.header()
    );
    console.log(g);
    return g;
  }

  salvardestaque(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/destaques",
      data,
      this.header()
    );
    return g;
  }

  atualizardestaque(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/destaques",
      data,
      this.header()
    );
    return g;
  }

  uploaddestaque(file, destaque, nome) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("destaque", destaque);
    formData.append("nome", nome);

    var g = axios.post(
      this.apiurl() + "/ecommerce/destaques/upload",
      formData,
      this.header()
    );
    return g;
  }

  fotosdestaque(destaque) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/destaques/foto/" + destaque,
      this.header()
    );
    return g;
  }

  //DESCONTOS
  descontos() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/descontopromocao/desconto",
      this.header()
    );
    return g;
  }

  pontos() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/descontopromocao/ponto",
      this.header()
    );
    return g;
  }

  cashbacks() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/descontopromocao/cashback",
      this.header()
    );
    return g;
  }

  desconto(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/descontopromocao/" + id,
      this.header()
    );
    console.log(g);
    return g;
  }

  ponto(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/descontopromocao/" + id,
      this.header()
    );
    console.log(g);
    return g;
  }

  cashback(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/descontopromocao/" + id,
      this.header()
    );
    console.log(g);
    return g;
  }

  salvardesconto(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/descontopromocao/desconto",
      data,
      this.header()
    );
    return g;
  }

  atualizardesconto(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/descontopromocao/desconto",
      data,
      this.header()
    );
    return g;
  }

  ean(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/produtos/ean/" + id,
      this.header()
    );
    console.log(g);
    return g;
  }

  //USUARIOS
  usuarios() {
    var g = axios.get(this.apiurl() + "/ecommerce/usuarios", this.header());
    return g;
  }

  usuario(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/usuarios/" + id,
      this.header()
    );
    console.log(g);
    return g;
  }

  salvarusuario(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/usuarios",
      data,
      this.header()
    );
    return g;
  }

  login(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/usuarios/login",
      data,
      this.header()
    );
    return g;
  }

  atualizarusuario(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/usuarios",
      data,
      this.header()
    );
    return g;
  }

  //LOJAS
  testenfe(data) {
    console.log(data);
    var g = axios.post(
      this.apiurl() + "/ecommerce/lojas/loja/testenfe",
      data,
      this.header()
    );
    console.log(g);
    return g;
  }

  testeemail(data) {
    console.log(data);
    var g = axios.post(
      this.apiurl() + "/ecommerce/lojas/loja/testeemail",
      data,
      this.header()
    );
    console.log(g);
    return g;
  }

  loja() {
    var g = axios.get(this.apiurl() + "/ecommerce/lojas/loja", this.header());
    console.log(g);
    return g;
  }

  lojalogos() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/lojas/foto/loja",
      this.header()
    );
    console.log(g);
    return g;
  }

  atualizargooglemaps(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/lojas/googlemaps",
      data,
      this.header()
    );
    return g;
  }

  atualizarloja(data) {
    var g = axios.put(this.apiurl() + "/ecommerce/lojas", data, this.header());
    return g;
  }

  atualizarlojaemail(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/lojas/email",
      data,
      this.header()
    );
    return g;
  }

  atualizarlojanfe(data) {
    console.log(data);
    var g = axios.put(
      this.apiurl() + "/ecommerce/lojas/nfe",
      data,
      this.header()
    );
    return g;
  }

  atualizarloja2(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/lojas/esitef",
      data,
      this.header()
    );
    return g;
  }

  atualizarlojarede(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/lojas/rede",
      data,
      this.header()
    );
    return g;
  }


  atualizarlojagetnet(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/lojas/getnet",
      data,
      this.header()
    );
    return g;
  }

  atualizarloja6(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/lojas/cielo",
      data,
      this.header()
    );
    return g;
  }

  atualizarloja7(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/lojas/correios",
      data,
      this.header()
    );
    return g;
  }

  atualizarlojabin(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/lojas/bin",
      data,
      this.header()
    );
    return g;
  }

  atualizarloja3(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/lojas/redes",
      data,
      this.header()
    );
    return g;
  }

  atualizarloja5(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/lojas/cores",
      data,
      this.header()
    );
    return g;
  }

  atualizarloja4(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/lojas/idtawkto",
      data,
      this.header()
    );
    return g;
  }

  newsletters() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/categorias/newsletters",
      this.header()
    );
    return g;
  }

  criarnfe(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/nfes/criar",
      data,
      this.header()
    );
    return g;
  }

  iniciarnfe(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/nfes/iniciar",
      data,
      this.header()
    );
    return g;
  }

  emitirnfe(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/nfes/emitir",
      data,
      this.header()
    );
    return g;
  }

  danfenfe(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/nfes/danfe",
      data,
      this.header()
    );
    return g;
  }

  downloadnfe(pedido, tipo) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/nfes/downloadFile/" + pedido + "/" + tipo,
      this.headerd()
    );
    return g;
  }

  downloadnfesimp(pedido, tipo) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/nfes/downloadFilesimp/" + pedido + "/" + tipo,
      this.headerd()
    );
    return g;
  }

  evento(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/eventos/" + id,
      this.header()
    );
    console.log(g);
    return g;
  }

  eventos() {
    var g = axios.get(this.apiurl() + "/ecommerce/eventos", this.header());
    return g;
  }

  eventosx() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/eventos/listarpx/lista",
      this.header()
    );
    return g;
  }

  eventosp(pedido) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/eventos/listarp/" + pedido,
      this.header()
    );
    return g;
  }

  emails() {
    var g = axios.get(this.apiurl() + "/ecommerce/emails/", this.header());
    return g;
  }

  contatos() {
    var g = axios.get(this.apiurl() + "/ecommerce/contatos/", this.header());
    return g;
  }

  contato(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/contatos/" + id,
      this.header()
    );
    console.log(g);
    return g;
  }

  email(id) {
    var g = axios.get(this.apiurl() + "/ecommerce/emails/" + id, this.header());
    console.log(g);
    return g;
  }

  //esitef
  criarpagamento(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/esitef/criar",
      data,
      this.header()
    );
    return g;
  }

  transacoes() {
    var g = axios.get(this.apiurl() + "/ecommerce/esitef", this.header());
    return g;
  }

  transacoesultimos() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/esitef/ultimos",
      this.header()
    );
    return g;
  }

  transacoesx() {
    var g = axios.get(this.apiurl() + "/ecommerce/esitef/todos", this.header());
    return g;
  }

  consultatransancao(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/esitef/consultar",
      data,
      this.header()
    );
    console.log(g);
    return g;
  }

  transancao(id) {
    var g = axios.get(this.apiurl() + "/ecommerce/esitef/" + id, this.header());
    console.log(g);
    return g;
  }

  transancaosp(pedido) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/esitef/listarp/" + pedido,
      this.header()
    );
    return g;
  }

  ptransacoes(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/esitef/pesquisar/p",
      data,
      this.header()
    );
    return g;
  }

  //bin
  transacoesbin() {
    var g = axios.get(this.apiurl() + "/ecommerce/bin", this.header());
    return g;
  }

  transacoescielo() {
    var g = axios.get(this.apiurl() + "/ecommerce/cielo", this.header());
    return g;
  }

  transacoesrede() {
    var g = axios.get(this.apiurl() + "/ecommerce/rede", this.header());
    return g;
  }

  transacoesgetnet() {
    var g = axios.get(this.apiurl() + "/ecommerce/getnet", this.header());
    return g;
  }

  transacoesultimosbin() {
    var g = axios.get(this.apiurl() + "/ecommerce/bin/ultimos", this.header());
    return g;
  }

  transacoesultimosgetnet() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/getnet/ultimos",
      this.header()
    );
    return g;
  }

  transacoesultimosrede() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/rede/ultimos",
      this.header()
    );
    return g;
  }

  transacoesultimoscielo() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/cielo/ultimos",
      this.header()
    );
    return g;
  }

  transacoesxbin() {
    var g = axios.get(this.apiurl() + "/ecommerce/bin/todos", this.header());
    return g;
  }

  consultatransancaobin(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/bin/consultar",
      data,
      this.header()
    );
    console.log(g);
    return g;
  }

  consultatransancaogetnet(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/getnet/consultar",
      data,
      this.header()
    );
    console.log(g);
    return g;
  }


  consultatransancaorede(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/rede/consultar",
      data,
      this.header()
    );
    console.log(g);
    return g;
  }

  consultatransancaocielo(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/cielo/consultar",
      data,
      this.header()
    );
    console.log(g);
    return g;
  }

  transancaobin(id) {
    var g = axios.get(this.apiurl() + "/ecommerce/bin/" + id, this.header());
    console.log(g);
    return g;
  }

  transancaocielo(id) {
    var g = axios.get(this.apiurl() + "/ecommerce/cielo/" + id, this.header());
    console.log(g);
    return g;
  }

  transancaorede(id) {
    var g = axios.get(this.apiurl() + "/ecommerce/rede/" + id, this.header());
    console.log(g);
    return g;
  }

  transancaogetnet(id) {
    var g = axios.get(this.apiurl() + "/ecommerce/getnet/" + id, this.header());
    console.log(g);
    return g;
  }

  transancaospgetnet(pedido) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/getnet/listarp/" + pedido,
      this.header()
    );
    return g;
  }

  transancaosprede(pedido) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/rede/listarp/" + pedido,
      this.header()
    );
    return g;
  }

  transancaospcielo(pedido) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/cielo/listarp/" + pedido,
      this.header()
    );
    return g;
  }

  transancaospbin(pedido) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/bin/listarp/" + pedido,
      this.header()
    );
    return g;
  }

  ptransacoesbin(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/bin/pesquisar/p",
      data,
      this.header()
    );
    return g;
  }

  ptransacoescielo(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/cielo/pesquisar/p",
      data,
      this.header()
    );
    return g;
  }

  ptransacoesgetnet(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/getnet/pesquisar/p",
      data,
      this.header()
    );
    return g;
  }

  ptransacoesrede(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/rede/pesquisar/p",
      data,
      this.header()
    );
    return g;
  }

  //

  pontosp(pedido) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/pedidos/listarp/pontos/" + pedido,
      this.header()
    );
    return g;
  }

  cashbacksp(pedido) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/pedidos/listarp/cashbacks/" + pedido,
      this.header()
    );
    return g;
  }

  ppedidos(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/pedidos/pesquisar/p",
      data,
      this.header()
    );
    return g;
  }

  pclientes(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/clientes/pesquisar/p",
      data,
      this.header()
    );
    return g;
  }

  //motoboys
  pmotoboys(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/motoboys/pesquisar/p",
      data,
      this.header()
    );
    return g;
  }

  motoboysa() {
    var g = axios.get(
      this.apiurl() + "/ecommerce/motoboys/lista/ativos",
      this.header()
    );
    return g;
  }

  motoboys() {
    var g = axios.get(this.apiurl() + "/ecommerce/motoboys", this.header());
    return g;
  }

  motoboy(id) {
    var g = axios.get(
      this.apiurl() + "/ecommerce/motoboys/" + id,
      this.header()
    );
    console.log(g);
    return g;
  }

  salvarmotoboy(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/motoboys",
      data,
      this.header()
    );
    return g;
  }

  atualizarmotoboy(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/motoboys",
      data,
      this.header()
    );
    return g;
  }

  //fretes
  pfretes(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/fretes/pesquisar/p",
      data,
      this.header()
    );
    return g;
  }

  fretes() {
    var g = axios.get(this.apiurl() + "/ecommerce/fretes", this.header());
    return g;
  }

  frete(id) {
    var g = axios.get(this.apiurl() + "/ecommerce/fretes/" + id, this.header());
    console.log(g);
    return g;
  }

  salvarfrete(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/fretes",
      data,
      this.header()
    );
    return g;
  }

  atualizarfrete(data) {
    var g = axios.put(this.apiurl() + "/ecommerce/fretes", data, this.header());
    return g;
  }

  uploadloja(file, nome, file3, nome3) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("nome", nome);
    formData.append("file3", file3);
    formData.append("nome3", nome3);

    var g = axios.post(
      this.apiurl() + "/ecommerce/lojas/upload",
      formData,
      this.header()
    );
    return g;
  }

  vendaperiodo(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/pedidos/pesquisar/venda-periodo",
      data,
      this.header()
    );
    return g;
  }

  vendapagamento(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/pedidos/pesquisar/venda-pagamento",
      data,
      this.header()
    );
    return g;
  }

  vendacliente(data) {
    var g = axios.post(
      this.apiurl() + "/ecommerce/pedidos/pesquisar/venda-cliente",
      data,
      this.header()
    );
    return g;
  }

  uploadlojapfx(file) {
    let formData = new FormData();
    console.log(file);
    formData.append("file", file);

    var g = axios.put(
      this.apiurl() + "/ecommerce/lojas/nfe/pfx",
      formData,
      this.header()
    );
    return g;
  }

  uploadlojapfxbin(file) {
    let formData = new FormData();
    console.log(file);
    formData.append("file", file);

    var g = axios.put(
      this.apiurl() + "/ecommerce/lojas/bin/jks",
      formData,
      this.header()
    );
    return g;
  }

  cancelarpedido(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/pedidos/cancelamento",
      data,
      this.header()
    );
    return g;
  }

  enviarpedido(data) {
    var g = axios.put(
      this.apiurl() + "/ecommerce/pedidos/enviar",
      data,
      this.header()
    );
    return g;
  }
}

export default new ApiService();
