import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import ApiService from '../../service/ApiService';
import Moment from 'react-moment';

class ListaPedidos extends Component {
  constructor (props) {
    super (props);
    this.state = {
      pedidos: [],
      message: null,
      campo: 'numeropedido',
      valor: '',
    };
    this.listar = this.listar.bind (this);
     //
     this.pesquisar = this.pesquisar.bind (this);
     this.onChangeCampo = this.onChangeCampo.bind (this);
     this.onChangeValor = this.onChangeValor.bind (this);
  }

  onChangeCampo (e) {
    this.setState ({
      campo: e.target.value,
    });
  }

  onChangeValor (e) {
    this.setState ({
      valor: e.target.value,
    });
  }

  pesquisar () {
    this.setState ({message: ''});
    if (this.state.valor.length > 0) {
      var data = {
        campo: this.state.campo,
        valor: this.state.valor,
      };
      console.log(data);
      ApiService.ppedidos (data)
        .then (response => {
          this.setState ({
            pedidos: response.data,
          });
          console.log (response.data);
        })
        .catch (e => {
          this.setState ({message: 'Erro ao pesquisar pedidos'});
          console.log (e);
        });
    }else{
      this.setState ({message: 'digite valor a ser pesquisado'});
    }
  }

  componentDidMount () {
    this.listar ();
  }

  listar () {
    ApiService.pedidos ()
      .then (response => {
        this.setState ({
          pedidos: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao listar pedidos'});
        console.log (e);
      });
  }

  badge (status) {
    switch (status) {
      case "Enviado":
        return "badge badge-success";
      case "Pendente":
        return 'badge badge-info';
      case "Pendente.":
        return 'badge badge-info';
      case "NFe Gerada.":
        return 'badge badge-primary';
      case "Cancelado":
        return 'badge badge-danger';
      case "Processando.":
        return 'badge badge-warning';
        case "Processando":
        return 'badge badge-warning';
      case "Danfe Gerada":
        return 'badge badge-primary';
      case "Pago":
        return "badge badge-success";
      case "Em Transito":
        return "badge badge-success";
      default:
        return '';
    }
  }

 

  render () {
    return (
      <div className="content-wrapper">

        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Pedidos</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Início</a></li>
                  <li className="breadcrumb-item active">Pedidos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="col-sm-12">
                      <td />
                    </div>
                    <div className="row">
                      <div className="col-lg-3">

                        <div className="form-group">
                          <label>Campo:</label>
                          <select
                            className="form-control select2"
                            value={this.state.campo}
                            onChange={this.onChangeCampo}
                            name="status"
                          >
                            <option defaultValue value="id">Codigo</option>
                            <option value="nome">Nome</option>
                            <option value="numeropedido">Numero Pedido</option>
                            <option value="status">Status</option>
                            <option value="total">Total</option>
                          </select>
                        </div>

                      </div>
                      <div className="col-lg-6">

                        <div className="form-group">
                          <label>Valor:</label>
                          <input
                            type="text"
                            maxLength="50"
                            className="form-control"
                            id="nomecategoria"
                            placeholder="Valor a ser pesquisado"
                            required
                            value={this.state.valor}
                            onChange={this.onChangeValor}
                            name="nome"
                          />
                        </div>

                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label style={{visibility: 'hidden'}}>Valor:</label>
                          <button
                            onClick={this.pesquisar}
                            className="btn btn-block btn-primary"
                          >
                            Pesquisar
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Codigo</th>
                          <th>Numero</th>
                          <th>Nome</th>
                          <th>Data e Hora</th>
                          <th>Frete</th>
                          <th>Total</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>

                        {this.state.pedidos &&
                          this.state.pedidos.map (pedido => (
                            <tr key={pedido.id}>
                              <td style={{fontSize:"14.5px"}}>{pedido.id}</td>
                              <td style={{fontSize:"14.5px"}}>{pedido.numeropedido}</td>
                              <td style={{fontSize:"14.5px"}}>{pedido.nome}</td>                             
                              <td style={{fontSize:"14.5px"}}>
                                <Moment format="DD/MM/YYYY HH:mm:ss">
                                {pedido.datapedido}
                                </Moment>
                              </td>
                              <td style={{fontSize:"14.5px"}}>R${Number(pedido.frete).toFixed(2)}</td>     
                              <td style={{fontSize:"14.5px"}}>R${Number(pedido.total).toFixed(2)}</td>                              
                              
                              <td className={this.badge (pedido.status)} 
                              style={{width:"90px", marginTop:"10%", marginLeft:"3%", padding: "5px 10px"}}>
                                {pedido.status=='Processando'?'Pendente':pedido.status}
                              </td>
                              
                              <td >
                                <div className="btn-group">

                                  <NavLink
                                    to={`/ver-pedido/${pedido.id}`}
                                    className="nav-link"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                    >
                                      Ver
                                    </button>
                                  </NavLink>

                                </div>
                              </td>
                            </tr>
                          ))}

                      </tbody>

                    </table>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </section>

      </div>
    );
  }
}

export default ListaPedidos;
