import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import ApiService from '../../service/ApiService';

class ListaDestaques extends Component {
  constructor (props) {
    super (props);
    this.state = {
      destaques: [],
      message: null,
    };
    this.listar = this.listar.bind (this);
  }

  componentDidMount () {
    this.listar ();
  }

  listar () {
    ApiService.destaques ()
      .then (response => {
        this.setState ({
          destaques: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao listar destaques'});
        console.log (e);
      });
  }

  render () {
    return (
      <div className="content-wrapper">

        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Destaques</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Início</a></li>
                  <li className="breadcrumb-item active">Destaques</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                      <td>
                        <NavLink to="/adicionar-destaque" className="nav-link">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                          >
                            Inserir Novo Destaque
                          </button>
                        </NavLink>
                      </td>
                    </div>
                  </div>

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Codigo</th>
                          <th>Nome do Destaque</th>
                          <th>Status do Destque</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>

                        {this.state.destaques &&
                          this.state.destaques.map (destaque => (
                            <tr key={destaque.id}>
                              <td>{destaque.id}</td>
                              <td>{destaque.nome}</td>
                              <td style={{color:destaque.status!=='Ativo'?'red':'green'}}>{destaque.status}</td>
                            
                              <td>
                                <div className="btn-group">
                                  <NavLink
                                    to={`/editar-destaque/${destaque.id}`}
                                    className="nav-link"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-info"
                                    >
                                      Editar
                                    </button>
                                  </NavLink>
                                  <NavLink
                                    to={`/ver-destaque/${destaque.id}`}
                                    className="nav-link"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                    >
                                      Ver
                                    </button>
                                  </NavLink>

                                </div>
                              </td>
                            </tr>
                          ))}

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ListaDestaques;
