import React, { Component } from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import { Redirect, NavLink } from 'react-router-dom';

class VerUsuarios extends Component {
  constructor(props) {
    super(props);
    console.log('akiiiiaaa');
    this.state = {
      usuario: {
        id: null,
        nome: '',
        usuario: '',
        senha: '',
        funcao: 'Supervisor',
        status: '',
        dataaltera: null,
        usuarioaltera: null,
      },
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
  }
  componentDidMount() {
    console.log(this.props.match.params.id + 'kkkkk');
    ApiService.usuario(this.props.match.params.id)
      .then(response => {
        this.setState({
          usuario: response.data,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao atualizar usuario' });
        console.log(e);
      });
    window.scrollTo(0, 0);
  }

  onChangeNome(e) {
    const nomex = e.target.value;

    this.setState(prevState => ({
      usuario: {
        ...prevState.usuario,
        nome: nomex,
      },
    }));
  }

  onChangeStatus(e) {
    const statusx = e.target.value;

    this.setState(prevState => ({
      usuario: {
        ...prevState.usuario,
        status: statusx,
      },
    }));
  }

  salvar() {
    var data = {
      id: this.state.usuario.id,
      nome: this.state.usuario.nome,
      status: this.state.usuario.status,
    };

    ApiService.atualizarusuario(data)
      .then(response => {
        this.setState({
          submitted: true,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao atualizar usuario' });
        console.log(e);
      });
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/usuarios" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: '640px' }}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Ver Usuario</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Ver Usuario
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomeusuario">Código do Usuário:</label>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={this.state.usuario.id}
                        name="id"
                  
                        style={{ width: '10%' }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="nomeusuario">Nome:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="nomeusuario"
                        placeholder="Insira o nome do novo usuario"
                        required
                        value={this.state.usuario.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{ width: '250px' }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="usuario">Email:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="usuario"
                        placeholder="Insira o nome do novo usuario"
                        required
                        value={this.state.usuario.usuario}
                        onChange={this.onChangeNome}
                        name="usuario"
                        style={{ width: '250px' }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        disabled={true}
                        className="form-control select2"
                        style={{ width: '10%' }}
                        value={this.state.usuario.status}
                        onChange={this.onChangeStatus}
                        name="status"
                      >
                        <option value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>
                  </div>


                  <div className="card-footer">
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <NavLink
                              to={`/editar-usuario/${this.state.usuario.id}`}
                              className="nav-link"
                            >
                              <button type="button" className="btn btn-info">
                                Editar
                    </button>
                            </NavLink>
                          

                            <NavLink to={'/usuarios/'} className="nav-link">
                              <button className="btn btn-danger" onClick={this.voltar}>
                                Voltar
                      </button>
                            </NavLink>
                          </div>
                        
                  </div>


                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default VerUsuarios;
