import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import {Redirect, NavLink} from 'react-router-dom';
import {cpf} from 'cpf-cnpj-validator';
import InputMask from 'react-input-mask';

class AddFretes extends Component {
  constructor (props) {
    super (props);
    this.state = {
      nome: '',
      regiao: '',
      status: 'Ativo',
      valor:0,
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind (this);
    this.onChangeNome = this.onChangeNome.bind (this);
    this.onChangeStatus = this.onChangeStatus.bind (this);
    this.onChangeRegiao = this.onChangeRegiao.bind (this);
    this.onChangeValor = this.onChangeValor.bind (this);
   
  }
  componentDidMount () {
    window.scrollTo (0, 0);
  }

  onChangeValor(e) {
    this.setState ({
      valor: e.target.value,
    });
  }

  onChangeRegiao(e) {
    this.setState ({
      regiao: e.target.value,
    });
  }

  onChangeNome (e) {
    this.setState ({
      nome: e.target.value,
    });
  }

  onChangeStatus (e) {
    this.setState ({
      status: e.target.value,
    });
  }

  salvar () {
    var ok = true;


    if (this.state.nome.length === 0) {
      ok = false;
      alert (' nome invalido,');
    }
    if (ok) {
      var data = {
        nome: this.state.nome,
        status: this.state.status,
        regiao:this.state.regiao,
        valor:this.state.valor,
      };

      ApiService.salvarfrete (data)
        .then (response => {
          this.setState ({
            submitted: true,
          });
          console.log (response.data);
        })
        .catch (e => {
          this.setState ({message: 'Erro ao cadastrar frete'});
          console.log (e);
        });
    }
  }

  render () {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/fretes" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '640px'}}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Adicionar Frete</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Adicionar Frete
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomefrete">Nome do Frete:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="nomefrete"
                        placeholder="Insira o nome do frete"
                        required
                        value={this.state.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{width: '300px'}}
                      />
                    </div>

                  

                    <div className="form-group">
                      <label htmlFor="nomefrete">Regiao:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="nomefrete"
                        placeholder="Insira o regiao do frete"
                        required
                        value={this.state.regiao}
                        onChange={this.onChangeRegiao}
                        name="nome"
                        style={{width: '250px'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="valor">
                        Valor:
                      </label>
                      <input
                        type="number"
                        min="0.00"
                        max={
                          this.state.percentualreal==='Percentual'
                            ? '99.99'
                            : '10000.00'
                        }
                        step="0.01"
                        className="form-control"
                        id="Valor"
                        required
                        placeholder="Insira o Valor "
                        value={this.state.valor}
                        onChange={this.onChangeValor}
                        name="Valor"
                        style={{width: '12%'}}
                      />
                    </div>

                   

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        className="form-control select2"
                        value={this.state.status}
                        onChange={this.onChangeStatus}
                        name="status"
                        style={{width: '12%'}}
                      >
                        <option defaultValue value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>
                  </div>

                  <div className="card-footer">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <button className="btn btn-primary" onClick={this.salvar}>
                        Adicionar
                      </button>

                      <NavLink to={'/fretes/'} className="nav-link">
                        <button
                          className="btn btn-danger"
                          onClick={this.voltar}
                        >
                          Voltar
                        </button>
                      </NavLink>
                    </div>

                  </div>

                </div>

              </div>

            </div>

          </section>

        </div>
        <Footer />
      </div>
    );
  }
}

export default AddFretes;
