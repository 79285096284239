import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import {Redirect, NavLink} from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import Moment from 'react-moment';

class VerContatos extends Component {
  constructor (props) {
    super (props);
    console.log ('akiiiiaaa');
    this.state = {
      email: {
        id: null,
        email: '',
        nome: '',
        mensagem: '',
        dataaltera: null,
        usuarioaltera: null,
      },
      message: null,
      submitted: false,
    };
  }
  componentDidMount () {
    console.log (this.props.match.params.id + 'kkkkk');
    ApiService.contato (this.props.match.params.id)
      .then (response => {
        this.setState ({
          email: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao atualizar email'});
        console.log (e);
      });
    window.scrollTo (0, 0);
  }

  render () {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/contatos" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '640px'}}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Ver Contato</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Ver Contato
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomeemail">Código:</label>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={this.state.email.id}
                        name="id"
                        disabled
                        style={{ width: '10%' }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomeevento">Data Hora:</label>
                      <div>
                      <Moment format="DD/MM/YYYY HH:mm:ss">
                        {this.state.email.dataaltera}
                      </Moment>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomeemail">Email:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="nomeemail"
                        placeholder="Insira o nome da nova email"
                        required
                        value={this.state.email.email}
                        name="nome"
                        style={{ width: '460px' }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="assunto">Nome:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="assunto"
                        placeholder="Insira o nome da nova email"
                        required
                        value={this.state.email.nome}
                        name="assunto"
                        style={{ width: '460px' }}
                      />
                    </div>

                    <label htmlFor="exampleInputPassword1">Mensagem:</label>
                    <SunEditor setContents={this.state.email.mensagem} height="300" disable={true}/>
                    <br/>
                    
                  </div>

                  <div className="card-footer">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >

                      <NavLink to={'/contatos/'} className="nav-link">
                        <button
                          className="btn btn-danger"
                          onClick={this.voltar}
                        >
                          Voltar
                        </button>
                      </NavLink>

                    </div>

                  </div>
                </div>
              </div>
            </div>

          </section>

        </div>
        <Footer />

      </div>
    );
  }
}

export default VerContatos;
