import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import ApiService from '../../service/ApiService';
import 'moment/locale/pt-br';
import 'rc-datepicker/lib/style.css';
import {DatePickerInput} from 'rc-datepicker';
import Moment from 'react-moment';
import moment from 'moment';

class ListaVendas extends Component {
  constructor (props) {
    super (props);
    this.state = {
      vendas: [],
      datai: new Date (),
      dataf: new Date (),
      message: null,
      campo: 'nome',
      valor: '',
    };
    this.listar = this.listar.bind (this);
  }

  onChangeDatai = (jsDate, dateString) => {
    this.setState ({
      datai: jsDate,
    });
  };

  onChangeDataf = (jsDate, dateString) => {
    this.setState ({
      dataf: jsDate,
    });
  };

  componentDidMount () {
    var d = new moment ();
    var startMonth = d.clone ().startOf ('month');
    var endMonth = d.clone ().endOf ('month');
    console.log (startMonth, endMonth);
    this.state.datai = startMonth;
    this.state.dataf = endMonth;
    this.setState ({datai: startMonth});

    this.setState ({dataf: endMonth});

    this.listar ();
  }

  listar () {
    this.setState ({message: ''});
    var data = {
      datai: this.state.datai,
      dataf: this.state.dataf,
    };
    console.log (data);
    ApiService.vendaperiodo (data)
      .then (response => {
        this.setState ({
          vendas: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao listar vendas'});
        console.log (e);
      });
  }

  render () {
    return (
      <div className="content-wrapper">

        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Vendas por Periodo</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Início</a></li>
                  <li className="breadcrumb-item active">Vendas por Periodo</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <td />
                    </div>

                  </div>

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                <div className="card-body">  
                <div style={{display: 'flex',  justifyContent:'flex-start', alignItems:'center'}}>
                  <div className="form-group">
                    <label htmlFor="datai">Data Inicial:</label>
                    <DatePickerInput
                      id="datai"
                      name="datai"
                      value={this.state.datai}
                      onChange={this.onChangeDatai}
                      style={{width: '180px'}}
                    />
                  </div>

                  &nbsp;&nbsp;

                  <div className="form-group">
                    <label htmlFor="dataf">Data Final:</label>
                    <DatePickerInput
                      id="dataf"
                      name="dataf"
                      value={this.state.dataf}
                      onChange={this.onChangeDataf}
                      style={{width: '180px'}}
                    />
                  </div>

                  &nbsp;&nbsp;

                  <div className="form-group">                  
                    <button className="btn btn-primary" onClick={this.listar} style={{marginTop:"38%"}}>
                    &nbsp;&nbsp;Filtrar&nbsp;&nbsp;
                    </button>
                  </div>
                  </div>
                </div>

                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Qtd Finalizadas</th>
                          <th>Valor Finalizadas</th>
                          <th>Qtd Nao Finalizadas</th>
                          <th>Valor Nao Finalizadas</th>
                        </tr>
                      </thead>
                      <tbody>

                        {this.state.vendas &&
                          this.state.vendas.map (venda => (
                            <tr key={venda.datahora}>
                              <td>
                                {' '} <Moment format="DD/MM/YYYY">
                                  {venda.datahora}
                                </Moment>
                              </td>
                              <td>{venda.qtdfinalizada}</td>
                              <td>
                                R$ {Number (venda.valorfinalizada).toFixed (2)}
                              </td>
                              <td>{venda.qtdnaofinalizada}</td>
                              <td>
                                R$
                                {' '}
                                {Number (venda.valornaofinalizada).toFixed (2)}
                              </td>
                            </tr>
                          ))}

                      </tbody>
                      <tfoot>
                        <tr>
                          <th scope="row">Totais</th>
                          <td style={{fontWeight: 'bold'}}>
                            {this.state.vendas.reduce (
                              (prev, next) => prev + next.qtdfinalizada,
                              0
                            )}
                          </td>
                          <td style={{fontWeight: 'bold'}}>
                            R$
                            {' '}
                            {Number (
                              this.state.vendas.reduce (
                                (prev, next) => prev + next.valorfinalizada,
                                0
                              )
                            ).toFixed (2)}
                          </td>
                          <td style={{fontWeight: 'bold'}}>
                            {this.state.vendas.reduce (
                              (prev, next) => prev + next.qtdnaofinalizada,
                              0
                            )}
                          </td>
                          <td style={{fontWeight: 'bold'}}>
                            R$
                            {' '}
                            {Number (
                              this.state.vendas.reduce (
                                (prev, next) => prev + next.valornaofinalizada,
                                0
                              )
                            ).toFixed (2)}
                          </td>
                        </tr>
                      </tfoot>

                    </table>
                    <br/>
                    <button className="btn btn-danger">
                      PDF
                    </button>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </section>

      </div>
    );
  }
}

export default ListaVendas;
