import React, { Component } from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import { Redirect, NavLink } from 'react-router-dom';

class AddCashBacks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo: 'categoria',
      itens: [],
      categorias: [],
      subcategorias: [],
      categoria: null,
      subcategoria: null,
      nome: '',
      status: 'Ativo',
      percentualreal: 'Percentual',
      valor: 0,
      equivale: 0,
      // dataexpiracao: null,
      itens: [],
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind(this);
    this.excluir = this.excluir.bind(this);
    this.adicionar = this.adicionar.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangePercentual = this.onChangePercentual.bind(this);
    this.onChangeValor = this.onChangeValor.bind(this);
    this.onChangeEquivale = this.onChangeEquivale.bind(this);
    this.onChangeDataexpiracao = this.onChangeDataexpiracao.bind(this);
    this.onChangeTipo = this.onChangeTipo.bind(this);
    this.onChangeCategoria = this.onChangeCategoria.bind(this);
    this.onChangeSubCategoria = this.onChangeSubCategoria.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    ApiService.categorias()
      .then(response => {
        this.setState({
          categorias: response.data,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao listar categorias' });
        console.log(e);
      });

    ApiService.subcategorias()
      .then(response => {
        this.setState({
          subcategorias: response.data,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao listar subcategorias' });
        console.log(e);
      });
  }

  onChangeTipo(e) {
    this.setState({
      tipo: e.target.value,
    });
  }

  onChangeSubCategoria(e) {
    this.setState({
      subcategoria: e.target.value,
    });
  }

  onChangeCategoria(e) {
    this.setState({
      categoria: e.target.value,
    });
  }

  onChangeNome(e) {
    this.setState({
      nome: e.target.value,
    });
  }

  onChangeStatus(e) {
    this.setState({
      status: e.target.value,
    });
  }

  onChangePercentual(e) {
    this.setState({
      percentualreal: e.target.value,
    });
  }

  onChangeValor(e) {
    this.setState({
      valor: e.target.value,
    });
  }

  onChangeEquivale(e) {
    this.setState({
      equivale: e.target.value,
    });
  }

  onChangeDataexpiracao(e) {
    this.setState({
      dataexpiracao: e.target.value,
    });
  }

  excluir(item) {
    var pos = 0;
    for (var i = 0; i < this.state.itens.length; i++) {
      var t = this.state.itens[i];
      if (item.tipo==='categoria') {
        if (item.categoria.id===t.categoria.id) {
          break;
        }
      }
      if (item.tipo==='subcategoria') {
        if (item.subcategoria.id===t.subcategoria.id) {
          break;
        }
      }
      pos++;
    }
    this.state.itens.splice(pos, 1);
    this.setState({ itens: this.state.itens });
  }

  adicionar() {
    var ok = true;
    var p = {};
    p.tipo = this.state.tipo;
    if (this.state.tipo==='categoria') {
      p.categoria = {};
      if (this.state.categoria===null) {
        ok = false;
      } else {
        var h = this.state.categoria.split('##');
        p.categoria.id = h[0];
        p.categoria.nome = h[1];
      }
    }
    if (this.state.tipo==='subcategoria') {
      p.subcategoria = {};
      if (this.state.subcategoria===null) {
        ok = false;
      } else {
        var h = this.state.subcategoria.split('##');
        p.subcategoria.id = h[0];
        p.subcategoria.nome = h[1];
      }
    }
    if (ok) {
      for (var i = 0; i < this.state.itens.length; i++) {
        var j = this.state.itens[i];
        if (j.categoria!==undefined) {
          if (
            this.state.tipo==='categoria' &&
            j.categoria.id===p.categoria.id
          ) {
            ok = false;
            break;
          }
        }
        if (j.subcategoria!==undefined) {
          if (
            this.state.tipo==='subcategoria' &&
            j.subcategoria.id===p.subcategoria.id
          ) {
            ok = false;
            break;
          }
        }
      }
      if (ok) {
        this.state.itens.push(p);
        this.setState({ itens: this.state.itens });
      }
    }
    console.log(this.state.itens);
  }

  salvar() {
    var ok = true;

    if (this.state.valor <= 0) {
      ok = false;
      alert('valor invalido');
    }

    if (this.state.equivale <= 0) {
      ok = false;
      alert('equivale invalido');
    }

    if (this.state.nome.length <= 0) {
      ok = false;
      alert('nome invalido');
    }



    if (this.state.itens.length <= 0) {
      ok = false;
      alert('adicione pelo menos um item');
    }

    if (ok) {
      var data = {
        nome: this.state.nome,
        status: this.state.status,
        tipo: 'cashback',
        percentualreal: this.state.percentualreal,
        valor: this.state.valor,
        equivale: this.state.equivale,
        itens: this.state.itens,
      };

      ApiService.salvardesconto(data)
        .then(response => {
          this.setState({
            submitted: true,
          });
          console.log(response.data);
        })
        .catch(e => {
          this.setState({ message: 'Erro ao cadastrar cashback' });
          console.log(e);
        });
    }
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/cashbacks" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: '640px' }}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Adicionar CashBack</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Adicionar CashBack
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                  <div style={{display: 'flex',  justifyContent:'flex-start', alignItems:'center'}}>
                    <div className="form-group">
                      <label htmlFor="nomecashback">Nome do CashBack:</label>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="nomecashback"
                        placeholder="Nome Do Novo CashBack"
                        required
                        value={this.state.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{ width: '370px' }}
                      />
                    </div>

                    &nbsp; &nbsp; 

                    <div className="form-group">
                      <label>Tipo:</label>
                      <select
                        className="form-control select2"
                        style={{ width: '180px' }}
                        value={this.state.percentualreal}
                        onChange={this.onChangePercentual}
                        name="percentual"
                      >
                        <option defaultValue value="Percentual">Percentual</option>
                        <option value="Real">Reais</option>
                      </select>
                    </div>

                    &nbsp; &nbsp; 

                    <div className="form-group">
                      <label htmlFor="valor">
                        Valor:
                      </label>
                      <input
                        type="number"
                        min="0.00"
                        max={
                          this.state.percentualreal==='Percentual'
                            ? '99.99'
                            : '10000.00'
                        }
                        step="0.01"
                        className="form-control"
                        id="Valor"
                        required
                        placeholder="Insira o Valor "
                        value={this.state.valor}
                        onChange={this.onChangeValor}
                        name="Valor"
                        style={{ width: '144px' }}
                      />
                    </div>
                  </div>

                  <div style={{display: 'flex',  justifyContent:'flex-start', alignItems:'center'}}>    
                    <div className="form-group">
                      <label htmlFor="valor">
                        Equivale em R$:
                      </label>
                      <input
                        type="number"
                        min="1.00"
                        max={"1000"}
                        step="1"
                        className="form-control"
                        id="Valor"
                        required
                        placeholder="Insira o equivalente "
                        value={this.state.equivale}
                        onChange={this.onChangeEquivale}
                        name="Valor"
                        style={{ width: '130px' }}
                      />
                    </div>

                    &nbsp; &nbsp; 

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        className="form-control select2"
                        style={{ width: '130px' }}
                        value={this.state.status}
                        onChange={this.onChangeStatus}
                        name="status"
                      >
                        <option defaultValue value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>

                    &nbsp; &nbsp; 

                    <div className="form-group">
                      <label>Tipo:</label>
                      <select
                        className="form-control select2"
                        value={this.state.tipo}
                        onChange={this.onChangeTipo}
                        name="status"
                        style={{ width: '140px' }}
                      >
                        <option defaultValue value="categoria">
                          Categoria
                          </option>
                        <option value="subcategoria">Subcategoria</option>
                      </select>
                    </div>

                    &nbsp; &nbsp; 

                    {this.state.tipo==='categoria' &&
                      <div className="form-group">
                        <label>Categoria:</label>
                        <select
                          required
                          className="form-control select2"
                          value={this.state.categoria}
                          onChange={this.onChangeCategoria}
                          name="categoria"
                          style={{ width: '280px' }}
                        >
                          <option defaultValue>Selecione</option>
                          {this.state.categorias &&
                            this.state.categorias.map(categoria => (
                              <option
                                value={categoria.id + '##' + categoria.nome}
                              >
                                {categoria.id + '- ' + categoria.nome}
                              </option>
                            ))}
                        </select>
                      </div>}

                    {this.state.tipo==='subcategoria' &&
                      <div className="form-group">
                        <label>SubCategoria:</label>
                        <select
                          required
                          className="form-control select2"
                          value={this.state.subcategoria}
                          onChange={this.onChangeSubCategoria}
                          name="categoria"
                          style={{ width: '280px' }}
                        >
                          <option defaultValue>Selecione</option>
                          {this.state.subcategorias &&
                            this.state.subcategorias.map(subcategoria => (
                              <option
                                value={
                                  subcategoria.id + '##' + subcategoria.nome
                                }
                              >
                                {subcategoria.id + '- ' + subcategoria.nome}
                              </option>
                            ))}
                        </select>
                      </div>}
                    </div>  
                     <hr/>  

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <button
                        className="btn btn-primary"
                        onClick={() => this.adicionar()}
                      >
                        Adicionar Item
                        </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Tipo</th>
                          <th>Codigo</th>
                          <th>Nome</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.itens &&
                          this.state.itens.map(item => (
                            <tr
                              key={
                                item.categoria!==undefined
                                  ? item.categoria.id
                                  : item.subcategoria.id
                              }
                            >
                              <td>{item.tipo}</td>
                              <td>
                                {item.categoria!==undefined
                                  ? item.categoria.id
                                  : item.subcategoria.id}
                              </td>
                              <td>
                                {item.categoria!==undefined
                                  ? item.categoria.nome
                                  : item.subcategoria.nome}
                              </td>
                              <td>
                                <button
                                  className="btn btn-danger"
                                  onClick={() => this.excluir(item)}
                                >
                                  excluir
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                                
                <div className="card-footer">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <button className="btn btn-primary" onClick={this.salvar}>
                      Salvar
                      </button>

                    <NavLink to={'/cashbacks/'} className="nav-link">
                      <button
                        className="btn btn-danger"
                        onClick={this.voltar}
                      >
                        Voltar
                        </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default AddCashBacks;
