import React, { Component } from "react";
import Header from "../../componentes/Header";
import Sidebar from "../../componentes/Sidebar";
import Footer from "../../componentes/Footer";
import ApiService from "../../service/ApiService";
import { Redirect, NavLink } from "react-router-dom";

const checkboxes = [
  {
    name: "branco",
    key: "branco",
    label: "Branco",
    cor: "#EEF2F8",
  },
  {
    name: "perola",
    key: "perola",
    label: "Pérola",
    cor: "#E5D2B3",
  },
  {
    name: "taupe",
    key: "taupe",
    label: "Taupe",
    cor: "#D2B179",
  },
  {
    name: "caramelo",
    key: "caramelo",
    label: "Caramelo",
    cor: "#FFB74D",
  },

  {
    name: "whisky",
    key: "whisky",
    label: "Whisky",
    cor: "#8b4513",
  },
  {
    name: "cafe-fosco",
    key: "cafe-fosco",
    label: "Cafe fosco",
    cor: "#4d2719",
  },
  {
    name: "cafe-brilho",
    key: "cafe-brilho",
    label: "Cafe brilho",
    cor: "#86442d",
  },
  {
    name: "chumbo",
    key: "chumbo",
    label: "Chumbo",
    cor: "#607d8b",
  },
  {
    name: "preto-brilho",
    key: "preto-brilho",
    label: "Preto brilho",
    cor: "#4d4d4d",
  },
  {
    name: "preto-fosco",
    key: "preto-fosco",
    label: "Preto fosco",
    cor: "#333333",
  },
];

class VerProdutos extends Component {
  constructor(props) {
    super(props);
    console.log("akiiiiaaa");
    this.state = {
      cores: new Map(),
      segmento: "0",
      produto: {
        id: null,
        nome: "",
        status: "",
        subcategoria: null,
        laboratorio: "",
        dosagem: "",
        preco: 0.0,
        desconto: 0.0,
        promocao: 0.0,
        quantidade: "",
        dataaltera: null,
        usuarioaltera: null,
        pictures: [],
        ean: "",
        produtofk: null,
        produtosi: [],
        tipo: "Normal",
      },
      produtos: [],
      produtoi: "",
      nome: "",
      subcategorias: [],
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind(this);
    this.onChangecores = this.onChangecores.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeCategoria = this.onChangeCategoria.bind(this);
    this.onChangeLaboratorio = this.onChangeLaboratorio.bind(this);
    this.onChangeDosagem = this.onChangeDosagem.bind(this);
    this.onChangeIntroducao = this.onChangeIntroducao.bind(this);
    this.onChangeCaracteristica = this.onChangeCaracteristica.bind(this);
    this.onChangePreco = this.onChangePreco.bind(this);
    this.onChangeDesconto = this.onChangeDesconto.bind(this);
    this.onChangePromocao = this.onChangePromocao.bind(this);
    this.onChangeQuantidade = this.onChangeQuantidade.bind(this);
  }
  componentDidMount() {
    ApiService.produto(this.props.match.params.id)
      .then((response) => {
        this.setState({
          produto: response.data,
        });
        if (this.state.produto.cores != undefined) {
          var fg = this.state.produto.cores.split(",");
          for (let i = 0; i < fg.length; i++) {
            if (fg[i].length > 0) {
              this.state.cores.set(fg[i], true);
            }
          }
        }
        console.log(this.state.cores);
        this.setState({
          cores: this.state.cores,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao atualizar produto" });
        console.log(e);
      });

    if (this.state.produto.tipo === "Kit") {
      ApiService.produtos()
        .then((response) => {
          this.setState({
            produtos: response.data,
          });
          console.log(response.data);
        })
        .catch((e) => {
          this.setState({ message: "Erro ao listar produtos" });
          console.log(e);
        });
    }

    ApiService.fotosproduto(this.props.match.params.id)
      .then((response) => {
        this.setState({
          pictures: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao listar fotos do produto" + e });
        console.log(e);
      });

    ApiService.subcategorias()
      .then((response) => {
        this.setState({
          subcategorias: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao listar subcategorias" });
        console.log(e);
      });

    ApiService.segmento()
      .then((response) => {
        this.setState({
          segmento: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao consultar segmento" });
        console.log(e);
      });

    window.scrollTo(0, 0);
  }

  onChangeQuantidade(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        quantidade: nomex,
      },
    }));
  }

  onChangeDesconto(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        desconto: nomex,
      },
    }));
  }

  onChangePromocao(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        promocao: nomex,
      },
    }));
  }

  onChangePreco(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        preco: nomex,
      },
    }));
  }

  onChangeLaboratorio(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        laboratorio: nomex,
      },
    }));
  }

  onChangeDosagem(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        dosagem: nomex,
      },
    }));
  }

  onChangeIntroducao(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        introducao: nomex,
      },
    }));
  }

  onChangeCaracteristica(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        caracteristica: nomex,
      },
    }));
  }

  onChangecores(e) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      cores: prevState.cores.set(item, isChecked),
    }));
  }

  onChangeNome(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        nome: nomex,
      },
    }));
  }

  onChangeCategoria(e) {
    const categoriax = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        subcategoria: { id: categoriax },
      },
    }));
  }

  onChangeStatus(e) {
    const statusx = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        status: statusx,
      },
    }));
  }

  salvar() {
    var data = {
      id: this.state.produto.id,
      nome: this.state.produto.nome,
      status: this.state.produto.status,
      subcategoria: { id: this.state.produto.subcategoria.id },
      quantidade: this.state.produto.quantidade,
      preco: this.state.produto.preco,
      desconto: this.state.produto.desconto,
      promocao: this.state.produto.promocao,
      laboratorio: this.state.produto.laboratorio,
      dosagem: this.state.produto.dosagem,
    };

    ApiService.atualizarproduto(data)
      .then((response) => {
        this.setState({
          submitted: true,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao atualizar produto" });
        console.log(e);
      });
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/produtos" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: "640px" }}>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Ver Produto</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Ver Produto</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  {this.state.message && (
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>
                  )}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomecategoria">Código do Produto:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.produto.id}
                        name="id"
                        disabled={true}
                        style={{ width: "25%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Tipo</label>
                      <select
                        disabled={true}
                        className="form-control select2"
                        style={{ width: "25%" }}
                        value={this.state.produto.tipo}
                        onChange={this.onChangeTipo}
                        name="tipo"
                      >
                        <option defaultValue value="Normal">
                          Normal
                        </option>
                        <option value="Kit">Kit</option>
                      </select>
                    </div>

                    {this.state.produto.tipo !== "Kit" && (
                      <div className="form-group">
                        <label htmlFor="ean">
                          Código de Barras:{" "}
                          {this.state.produto.produtofk !== null
                            ? " - Produto ERP:" + this.state.produto.produtofk
                            : ""}
                        </label>
                        <input
                          disabled={true}
                          type="number"
                          maxLength="14"
                          className="form-control"
                          id="ean"
                          placeholder="Insira codigo de barras do produto"
                          required
                          value={this.state.produto.ean}
                          onChange={this.onChangeEan}
                          name="ean"
                          style={{ width: "460px" }}
                        />
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="nomecategoria">Nome do Produto:</label>
                      <input
                        type="text"
                        maxLength="50"
                        disabled={true}
                        className="form-control"
                        id="nomecategoria"
                        placeholder="Insira o nome da nova produto"
                        required
                        value={this.state.produto.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{ width: "460px" }}
                      />
                    </div>

                    <div className="form-group">
                      <label>SubCategoria:</label>
                      <select
                        disabled={true}
                        required
                        className="form-control select2"
                        style={{ width: "460px" }}
                        value={
                          this.state.produto.subcategoria !== null
                            ? this.state.produto.subcategoria.id
                            : ""
                        }
                        onChange={this.onChangeCategoria}
                        name="categoria"
                      >
                        {this.state.subcategorias &&
                          this.state.subcategorias.map((subcategoria) => (
                            <option value={subcategoria.id}>
                              {subcategoria.id +
                                "- " +
                                subcategoria.nome +
                                " - Categoria: " +
                                subcategoria.categoria.nome}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card card-primary">
                  {this.state.message && (
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>
                  )}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="laboratorio">Fabricante:</label>
                      <input
                        type="text"
                        maxLength="100"
                        disabled={true}
                        className="form-control"
                        id="laboratorio"
                        placeholder="Insira o  laboratorio"
                        value={this.state.produto.laboratorio}
                        onChange={this.onChangeLaboratorio}
                        name="laboratorio"
                        style={{ width: "460px" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="dosagem">Dosagem:</label>
                      <input
                        type="text"
                        maxLength="100"
                        disabled={true}
                        className="form-control"
                        id="dosagem"
                        placeholder="Insira a dosagem"
                        value={this.state.produto.dosagem}
                        onChange={this.onChangeDosagem}
                        name="dosagem"
                        style={{ width: "460px" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="quantidade">Quantidade:</label>
                      <input
                        type="text"
                        disabled={true}
                        maxLength="100"
                        className="form-control"
                        id="quantidade"
                        placeholder="Insira a quantidade "
                        value={this.state.produto.quantidade}
                        onChange={this.onChangeQuantidade}
                        name="quantidade"
                        style={{ width: "460px" }}
                      />
                    </div>

                    {this.state.produto.tipo === "Kit" && (
                      <div className="form-group">
                        <label>Produtos do Kit</label>

                        <table
                          id="example1"
                          className="table table-bordered table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Codigo</th>
                              <th>Nome</th>
                              <th>Preço</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.produto.produtosi &&
                              this.state.produto.produtosi.map((produto) => (
                                <tr key={produto.produto_fk}>
                                  <td>{produto.produto_fk}</td>
                                  <td>{produto.nome}</td>
                                  <td>{Number(produto.preco).toFixed(2)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="preco">Preço:</label>
                      <input
                        type="number"
                        min="0.00"
                        max="10000.00"
                        disabled={true}
                        step="0.01"
                        className="form-control"
                        id="dosagem"
                        placeholder="Insira o preco "
                        value={this.state.produto.preco}
                        onChange={this.onChangePreco}
                        name="preco"
                        style={{ width: "25%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="desconto">Valor Desconto(R$):</label>
                      <input
                        type="number"
                        min="0.00"
                        disabled={true}
                        max="10000.00"
                        step="0.01"
                        className="form-control"
                        id="desconto"
                        placeholder="Insira o desconto "
                        value={this.state.produto.desconto}
                        onChange={this.onChangeDesconto}
                        name="desconto"
                        style={{ width: "25%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="card card-primary">
                  {this.state.message && (
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>
                  )}

                  <div className="card-body">
                    {this.state.segmento == "11" && (
                      <div className="form-group">
                        <div>
                          {/*
                          <label htmlFor="">Opções do Produto:</label>
                          <div>
                            <h6
                              style={{ fontWeight: "bold", color: "#878787" }}
                            >
                              OPÇÕES DE LUGARES{" "}
                            </h6>
                            <label /> Poltrona 1,05C x 0,85A x 0,85P -R$900,00
                            <br />
                            <label /> 2 Lugares 1,60C x 0,85A x 0,85P -R$300,00
                            <br />
                            <label /> 3 Lugares 2,10C x 0,85A x 0,85P
                            <br />
                            <label /> 4 Lugares 2,50C x 0,85A x 0,85P +R$959,00
                            <br />
                            <label /> 5 Lugares 3,25C x 0,85A x 0,85P
                            +R$1.600,00
                          </div>
                          <br />
                          <div>
                            <h6
                              style={{ fontWeight: "bold", color: "#878787" }}
                            >
                              OPÇÕES DE COMPRAR TAMBÉM{" "}
                            </h6>
                            <label /> Poltrona 1,05C x 0,85A x 0,85P +R$1.690,00
                            <br />
                            <label /> 2 Lugares 1,60C x 0,85A x 0,85P
                            +R$2.290,00
                            <br />
                            <label /> 3 Lugares 2,10C x 0,85A x 0,85P
                            +R$2.590,00
                            <br />
                            <label /> 4 Lugares 2,50C x 0,85A x 0,85P
                            +R$3.549,00
                            <br />
                            <label /> 5 Lugares 3,25C x 0,85A x 0,85P
                            +R$4.190,00
                          </div>
                            */}
                          <br />
                          <div>
                          <label htmlFor="valormontagem">Montagem de Fábrica:</label>
                          <input
                            type="number"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            disabled={true}
                            className="form-control"
                            id="valormontagem"
                            placeholder="Insira o valormontagem "
                            value={this.state.produto.valormontagem}
                            onChange={this.onChangevalormontagem}
                            name="valormontagem"
                            style={{ width: "20%" }}
                          />
                            <br />
                          </div>
                        </div>
                        <br />
                        <label htmlFor="dosagem">Cores do Produto:</label>
                        <div class="row">
                          {checkboxes.map((item) => (
                            <div class="col-md-1">
                              <div class="custom-control custom-checkbox image-checkbox">
                                <label key={item.key}>
                                  <input
                                    disabled={true}
                                    type="checkbox"
                                    name={item.name}
                                    checked={this.state.cores.get(item.name)}
                                    onChange={this.onChangecores}
                                  />
                                  <div
                                    style={{
                                      height: "70px",
                                      width: "70px",
                                      backgroundColor: item.cor,
                                    }}
                                  />

                                  {item.label}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="prazo">Prazo Entrega:</label>
                      <input
                        type="text"
                        maxLength="20"
                        disabled={true}
                        className="form-control"
                        id="prazo"
                        value={this.state.produto.prazoentrega}
                        onChange={this.onChangePrazoentrega}
                        name="prazo"
                        style={{ width: "70%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        disabled={true}
                        className="form-control select2"
                        style={{ width: "20%" }}
                        value={this.state.produto.status}
                        onChange={this.onChangeStatus}
                        name="status"
                      >
                        <option defaultValue value="Ativo">
                          Ativo
                        </option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="introducao">Introdução:</label>
                      <textarea
                        disabled={true}
                        type="text"
                        maxLength="500"
                        rows={2}
                        cols={5}
                        className="form-control"
                        id="introducao"
                        placeholder="Insira a introducao"
                        value={this.state.produto.introducao}
                        onChange={this.onChangeIntroducao}
                        name="introducao"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="dosagem">Caracteristica:</label>
                      <textarea
                        type="text"
                        disabled={true}
                        maxLength="500"
                        rows={2}
                        cols={5}
                        className="form-control"
                        id="caracteristica"
                        placeholder="Insira a caracteristica"
                        value={this.state.produto.caracteristica}
                        onChange={this.onChangeCaracteristica}
                        name="caracteristica"
                      />
                    </div>

                    {this.state.pictures &&
                      this.state.pictures.map((image) => (
                        <div style={{ padding: "10px", float: "left" }}>
                          <img
                            src={"data:image/jpeg;base64," + image.data}
                            width="209px"
                            height="209px"
                            alt={image.nome}
                          />
                          <br />
                          {image.padrao ? "Padrao" : ""}
                        </div>
                      ))}
                  </div>

                  <div className="card-footer">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <NavLink
                        to={`/editar-produto/${this.state.produto.id}`}
                        className="nav-link"
                      >
                        <button type="button" className="btn btn-info">
                          Editar
                        </button>
                      </NavLink>

                      <NavLink to={"/produtos/"} className="nav-link">
                        <button
                          className="btn btn-danger"
                          onClick={this.voltar}
                        >
                          Voltar
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default VerProdutos;
