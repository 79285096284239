import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import cep from 'cep-promise';
import { NavLink } from 'react-router-dom';


class EditLoja extends Component {
  constructor (props) {
    super (props);
    console.log ('akiiiiaaa');
    this.state = {
      loja: {
        id: null,
        nome: '',
        titulo: '',
        cnpj: '',
        email: '',
        telefone: '',
        cep: '',
        logradouro: '',
        numero: '',
        bairro: '',
        cidade: '',
        uf: '',
        dataaltera: null,
        usuarioaltera: null,
        merchant_id: '',
        merchant_key: '',
        maxparcelas: 1,
        valorminimo: 0,
        segmento:null,
        tipointegracao:'',
        prazoentrega:'',
        manutencao:'nao',
      },
      ufs: [
        'AC',
        'AL',
        'AM',
        'AP',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MG',
        'MS',
        'MT',
        'PA',
        'PB',
        'PE',
        'PI',
        'PR',
        'RJ',
        'RN',
        'RO',
        'RR',
        'RS',
        'SC',
        'SE',
        'SP',
        'TO',
      ],
      message: null,
      message2: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind (this);
    this.onChangeNome = this.onChangeNome.bind (this);
    this.onChangePrazoentrega = this.onChangePrazoentrega.bind (this);
    this.onChangeSegmento = this.onChangeSegmento.bind (this);
    this.onChangetipointegracao = this.onChangetipointegracao.bind (this);
    this.onChangeCnpj = this.onChangeCnpj.bind (this);
    this.onChangeCep = this.onChangeCep.bind (this);
    this.onChangeManutencao = this.onChangeManutencao.bind (this);
    this.onChangeLogradouro = this.onChangeLogradouro.bind (this);
    this.onChangeNumero = this.onChangeNumero.bind (this);
    this.onChangeTitulo = this.onChangeTitulo.bind (this);
    this.onChangeBairro = this.onChangeBairro.bind (this);
    this.onChangeCidade = this.onChangeCidade.bind (this);
    this.onChangeUf = this.onChangeUf.bind (this);
    this.onChangeEmail = this.onChangeEmail.bind (this);
    this.onChangeTelefone = this.onChangeTelefone.bind (this);
    this.onChangemi = this.onChangemi.bind (this);
    this.onChangemk = this.onChangemk.bind (this);
  }
  componentDidMount () {
    ApiService.loja ()
      .then (response => {
        this.setState ({
          loja: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao atualizar loja'});
        console.log (e);
      });
    window.scrollTo (0, 0);
  }
  onChangeSegmento(e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        segmento: nomex,
      },
    }));
  }

  onChangetipointegracao(e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        tipointegracao: nomex,
      },
    }));
  }

  onChangePrazoentrega (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        prazoentrega: nomex,
      },
    }));
  }

  onChangeManutencao (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        manutencao: nomex,
      },
    }));
  }

  onChangeNome (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        nome: nomex,
      },
    }));
  }

  onChangeTitulo (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        titulo: nomex,
      },
    }));
  }

  onChangeCep2 (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        cep: nomex,
      },
    }));
  }


  onChangeCep (e) {
    const nomex = e.target.value;
    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        cep: nomex,
      },
    }));
    //  console.log(e.target.value.replace ('-','').replace ('_','').length);
    if (nomex.replace ('-', '').replace ('_', '').length === 8) {
    
      // console.log('asfdsdf');
      cep (nomex.replace ('-', '').replace ('_', ''))
        .then (response => {
          console.log (response);
          this.setState (prevState => ({
            loja: {
              ...prevState.loja,
              numero: '',
            },
          }));
          this.setState (prevState => ({
            loja: {
              ...prevState.loja,
              complemento: '',
            },
          }));
          this.setState (prevState => ({
            loja: {
              ...prevState.loja,
              uf: response.state,
            },
          }));
          this.setState (prevState => ({
            loja: {
              ...prevState.loja,
              cidade: response.city,
            },
          }));
          this.setState (prevState => ({
            loja: {
              ...prevState.loja,
              bairro: response.neighborhood,
            },
          }));
          this.setState (prevState => ({
            loja: {
              ...prevState.loja,
              logradouro: response.street,
            },
          }));
        
        
        })
        .catch (e => {
   
          console.log (e);
        });
    }
  }

  onChangeLogradouro (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        logradouro: nomex,
      },
    }));
  }

  onChangemi (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        merchant_id: nomex,
      },
    }));
  }

  onChangemk (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        merchant_key: nomex,
      },
    }));
  }

  onChangeNumero (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        numero: nomex,
      },
    }));
  }

  onChangeBairro (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        bairro: nomex,
      },
    }));
  }

  onChangeCidade (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        cidade: nomex,
      },
    }));
  }

  onChangeUf (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        uf: nomex,
      },
    }));
  }

  onChangeEmail (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        email: nomex,
      },
    }));
  }

  onChangeTelefone (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        telefone: nomex,
      },
    }));
  }

  onChangeCnpj (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        cnpj: nomex,
      },
    }));
  }

  salvar () {
    var data = {
      id: this.state.loja.id,
      nome: this.state.loja.nome,
      titulo: this.state.loja.titulo,
      cnpj: this.state.loja.cnpj,
      cep: this.state.loja.cep,
      logradouro: this.state.loja.logradouro,
      numero: this.state.loja.numero,
      bairro: this.state.loja.bairro,
      cidade: this.state.loja.cidade,
      uf: this.state.loja.uf,
      email: this.state.loja.email,
      telefone: this.state.loja.telefone,
      segmento:this.state.loja.segmento,
      prazoentrega:this.state.loja.prazoentrega,
      tipointegracao:this.state.loja.tipointegracao,
      manutencao:this.state.loja.manutencao==='sim'?true:false
    };
    console.log(data);

    ApiService.atualizarloja (data)
      .then (response => {
        this.setState ({
          submitted: true,
        });
        console.log (response.data);
        window.scrollTo (0, 0);
        this.setState ({message2: 'Loja atualizada com sucesso'});
      })
      .catch (e => {
        this.setState ({message: 'Erro ao atualizar loja'});
        console.log (e);
      });
  }

  render () {
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '640px'}}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Informações da Loja</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Informações da Loja
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  {this.state.message2 &&
                    <div className="alert alert-info alert-dismissible">
                      {this.state.message2}
                    </div>}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomeloja">Código da Loja:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.loja.id}
                        name="id"
                        disabled
                        style={{width: '70%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="cnpj">CNPJ:</label>
                      <input
                        type="text"
                        maxLength="14"
                        className="form-control"
                        id="cnpj"
                        placeholder="Insira o cnpj da loja"
                        required
                        disabled={true}
                        value={this.state.loja.cnpj}
                        onChange={this.onChangeCnpj}
                        name="cnpj"
                        style={{width: '70%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomeloja">Nome da Loja:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="nomeloja"
                        placeholder="Insira o nome da  loja"
                        required
                        value={this.state.loja.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{width: '70%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="titulo">Título:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="titulo"
                        placeholder="Insira o titulo da loja"
                        required
                        value={this.state.loja.titulo}
                        onChange={this.onChangeTitulo}
                        name="titulo"
                        style={{width: '70%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Email:</label>
                      <input
                        type="email"
                        maxLength="100"
                        className="form-control"
                        id="email"
                        placeholder="Insira o email da loja"
                        required
                        value={this.state.loja.email}
                        onChange={this.onChangeEmail}
                        name="email"
                        style={{width: '70%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="telefone">Telefone:</label>
                      <input
                        type="number"
                        maxLength="20"
                        className="form-control"
                        id="telefone"
                        placeholder="Insira o telefone da loja"
                        required
                        value={this.state.loja.telefone}
                        onChange={this.onChangeTelefone}
                        name="telefone"
                        style={{width: '70%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="prazo">Prazo Entrega:</label>
                      <input
                        type="text"
                        maxLength="20"
                        className="form-control"
                        id="prazo"
                        value={this.state.loja.prazoentrega}
                        onChange={this.onChangePrazoentrega}
                        name="prazo"
                        style={{width: '70%'}}
                      />
                    </div>

                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  {this.state.message2 &&
                    <div className="alert alert-info alert-dismissible">
                      {this.state.message2}
                    </div>}

                  <div className="card-body">

                    <div className="form-group">
                      <label htmlFor="cep">CEP:</label>
                      <input
                        type="number"
                        maxLength="10"
                        className="form-control"
                        id="cep"
                        placeholder="Insira o cep da loja"
                        required
                        value={this.state.loja.cep}
                        onChange={this.onChangeCep}
                        name="cep"
                        style={{width: '70%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="logradouro">Logradouro:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="logradouro"
                        placeholder="Insira o logradouro da loja"
                        required
                        value={this.state.loja.logradouro}
                        onChange={this.onChangeLogradouro}
                        name="logradouro"
                        style={{width: '70%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="numero">Número:</label>
                      <input
                        type="text"
                        maxLength="20"
                        className="form-control"
                        id="numero"
                        placeholder="Insira o numero da loja"
                        required
                        value={this.state.loja.numero}
                        onChange={this.onChangeNumero}
                        name="numero"
                        style={{width: '70%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="bairro">Bairro:</label>
                      <input
                        type="text"
                        maxLength="20"
                        className="form-control"
                        id="bairro"
                        placeholder="Insira o bairro da loja"
                        required
                        value={this.state.loja.bairro}
                        onChange={this.onChangeBairro}
                        name="bairro"
                        style={{width: '70%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="cidade">Cidade:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="cidade"
                        placeholder="Insira a cidade da loja"
                        required
                        value={this.state.loja.cidade}
                        onChange={this.onChangeCidade}
                        name="cidade"
                        style={{width: '70%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="uf">UF:</label>
                      <select
                        required
                        className="form-control select2"
                        value={this.state.loja.uf}
                        onChange={this.onChangeUf}
                        name="uf"
                        style={{width: '70%'}}
                      >
                        <option defaultValue value="">selecione</option>
                        {this.state.ufs &&
                          this.state.ufs.map (uf => (
                            <option value={uf}>
                              {uf}
                            </option>
                          ))}
                      </select>
                    </div>

                    
                    <div className="form-group">
                      <label>Segmento:</label>
                      <select
                      required
                        className="form-control select2"
                        value={this.state.loja.segmento}
                        onChange={this.onChangeSegmento}
                        name="segmento"
                        style={{ width: '70%' }}
                      >
                        <option defaultValue value="">selecione</option>
                        <option  value="3">3 - Farmacia</option>
                        <option value="4">4 - Farmacia Manipulacao</option>
                        <option value="6">6 - Mercado</option>
                        <option value="9">9 - Utilidades Geral</option>
                        <option value="11">11 - Loja Sofa</option>
                      </select>
                    </div>

                    
                    <div className="form-group">
                      <label>Tipo Integracao:</label>
                      <select
                      required
                        className="form-control select2"
                        value={this.state.loja.tipointegracao}
                        onChange={this.onChangetipointegracao}
                        name="tipointegracao"
                        style={{ width: '70%' }}
                      >
                        <option defaultValue value="">selecione</option>
                        <option  value="esitef">Esitef</option>
                        <option value="bin">Bin</option>
                        <option value="cielo">Cielo</option>
                        <option value="rede">Rede</option>
                        <option value="getnet">GetNet</option>
                      </select>
                    </div>

                         
                    <div className="form-group">
                      <label>Manutenção?:</label>
                      <select
                      required
                        className="form-control select2"
                        value={this.state.loja.manutencao}
                        onChange={this.onChangeManutencao}
                        name="manutencao"
                        style={{ width: '70%' }}
                      >
                        <option  value="nao">Não</option>
                        <option value="sim">Sim</option>
                        
                      </select>
                    </div>

                    

                  </div>

                </div>

              </div>

            </div>
            <div className="card-footer">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <button className="btn btn-primary" onClick={this.salvar}>
                  Salvar
                </button>
                <NavLink to={'/'} className="nav-link">
                  <button className="btn btn-danger" onClick={this.voltar}>
                    Voltar
                  </button>
                </NavLink>
              </div>
            </div>

          </section>

        </div>
        <Footer />
      </div>
    );
  }
}

export default EditLoja;
