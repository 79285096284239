import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ListaClientes from '../clientes/ListaClientes';

class Clientes extends Component {
  componentDidMount () {
    console.log('ff');
    window.scrollTo (0, 0);
  }

  render () {
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <ListaClientes />
        <Footer />
      </div>
    );
  }
}

export default Clientes;
