import React, { Component } from "react";
import Header from "../../componentes/Header";
import Sidebar from "../../componentes/Sidebar";
import Footer from "../../componentes/Footer";
import ApiService from "../../service/ApiService";
import { Redirect, NavLink } from "react-router-dom";

class Rede extends Component {
  constructor(props) {
    super(props);
    console.log("akiiiiaaa");
    this.state = {
      loja: {
        id: null,
        dataaltera: null,
        usuarioaltera: null,
        pvrede: "",
        tokenrede: "",
        maxparcelas: 1,
        maxparcelas2: 1,
        taxa: 0,
        valorminimo: 0,
        ambientenfe: "",
      },
      message: null,
      message2: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind(this);
    this.onChangemi = this.onChangemi.bind(this);
    this.onChangemk = this.onChangemk.bind(this);
    this.onChangeParcelas = this.onChangeParcelas.bind(this);
    this.onChangeParcelas2 = this.onChangeParcelas2.bind(this);
    this.onChangeTaxa = this.onChangeTaxa.bind(this);
    this.onChangeValor = this.onChangeValor.bind(this);
    this.onChangeAmbientenfe = this.onChangeAmbientenfe.bind(this);
  }
  componentDidMount() {
    ApiService.loja()
      .then((response) => {
        this.setState({
          loja: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao atualizar loja" });
        console.log(e);
      });
    window.scrollTo(0, 0);
  }

  onChangeAmbientenfe(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        ambientenfe: nomex,
      },
    }));
  }

  onChangeParcelas(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        maxparcelas: nomex,
      },
    }));
  }

  onChangeParcelas2(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        maxparcelas2: nomex,
      },
    }));
  }

  onChangeTaxa(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        taxa: nomex,
      },
    }));
  }

  onChangeValor(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        valorminimo: nomex,
      },
    }));
  }

  onChangeCep(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        cep: nomex,
      },
    }));
  }

  onChangeLogradouro(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        logradouro: nomex,
      },
    }));
  }

  onChangemi(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        pvrede: nomex,
      },
    }));
  }

  onChangemk(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        tokenrede: nomex,
      },
    }));
  }

  onChangeNumero(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        numero: nomex,
      },
    }));
  }

  onChangeBairro(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        bairro: nomex,
      },
    }));
  }

  onChangeCidade(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        cidade: nomex,
      },
    }));
  }

  onChangeUf(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        uf: nomex,
      },
    }));
  }

  onChangeEmail(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        email: nomex,
      },
    }));
  }

  onChangeTelefone(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        telefone: nomex,
      },
    }));
  }

  onChangeCnpj(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      loja: {
        ...prevState.loja,
        cnpj: nomex,
      },
    }));
  }

  salvar() {
    var ok = true;

    if (Number(this.state.loja.taxa) > 99.99) {
      ok = false;
      alert("taxa invalida");
    }

    if (
      Number(this.state.loja.maxparcelas) > Number(this.state.loja.maxparcelas2)
    ) {
      ok = false;
      alert("max.parcelas sem juros nao pode ser maior que max.parcelas");
    }

    if (ok) {
      var data = {
        id: this.state.loja.id,
        maxparcelas: this.state.loja.maxparcelas,
        maxparcelas2: this.state.loja.maxparcelas2,
        taxa: this.state.loja.taxa,
        valorminimo: this.state.loja.valorminimo,
        pvrede: this.state.loja.pvrede,
        tokenrede: this.state.loja.tokenrede,
        ambientenfe: this.state.loja.ambientenfe,
      };

      ApiService.atualizarlojarede(data)
        .then((response) => {
          this.setState({
            submitted: true,
          });
          console.log(response.data);
          window.scrollTo(0, 0);
          this.setState({ message2: "rede atualizada com sucesso" });
        })
        .catch((e) => {
          this.setState({ message: "Erro ao atualizar rede" });
          console.log(e);
        });
    }
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: "640px" }}>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Configuração Rede</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Configuração Rede
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  {this.state.message && (
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>
                  )}

                  {this.state.message2 && (
                    <div className="alert alert-info alert-dismissible">
                      {this.state.message2}
                    </div>
                  )}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="merchant_id">Integração Atual:</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        value={this.state.loja.tipointegracao}
                        disabled={true}
                        style={{ width: "12%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Ambiente:</label>
                      <select
                        disabled={true}
                        className="form-control select2"
                        style={{ width: "130px" }}
                        value={this.state.loja.ambientenfe}
                        onChange={this.onChangeAmbientenfe}
                        name="status"
                      >
                        <option defaultValue value="1">
                          Producao
                        </option>
                        <option value="2">Homologacao</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="merchant_id">PV Rede:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="merchant_id"
                        placeholder="PV da Rede"
                        required
                        value={this.state.loja.pvrede}
                        onChange={this.onChangemi}
                        name="merchant_id"
                        style={{ width: "460px" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="merchant_key">Token Rede:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="merchant_key"
                        placeholder="Token da Rede"
                        required
                        value={this.state.loja.tokenrede}
                        onChange={this.onChangemk}
                        name="merchant_key"
                        style={{ width: "460px" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="preco">Valor Mínimo Parcela:</label>
                      <input
                        type="number"
                        min="1.00"
                        max="10000.00"
                        step="0.01"
                        className="form-control"
                        id="dosagem"
                        value={this.state.loja.valorminimo}
                        onChange={this.onChangeValor}
                        name="preco"
                        style={{ width: "12%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="preco">Max Parcelas Sem Juros:</label>
                      <input
                        type="number"
                        min="1"
                        max="24"
                        step="1"
                        className="form-control"
                        id="dosagem"
                        value={this.state.loja.maxparcelas}
                        onChange={this.onChangeParcelas}
                        name="preco"
                        style={{ width: "12%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="preco">Max Parcelas:</label>
                      <input
                        type="number"
                        min="1"
                        max="24"
                        step="1"
                        className="form-control"
                        id="dosagem"
                        value={this.state.loja.maxparcelas2}
                        onChange={this.onChangeParcelas2}
                        name="preco"
                        style={{ width: "12%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="preco">Taxa(%):</label>
                      <input
                        type="number"
                        min="0.01"
                        max="99.99"
                        step="0.01"
                        className="form-control"
                        id="dosagem"
                        value={this.state.loja.taxa}
                        onChange={this.onChangeTaxa}
                        name="preco"
                        style={{ width: "12%" }}
                      />
                    </div>

                    <div>
                      <a href="#" target="_blank">
                        Painel Homologacao
                      </a>
                      <br />
                      <a href="#" target="_blank">
                        Painel Produção
                      </a>
                    </div>
                  </div>

                  <div className="card-footer">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button className="btn btn-primary" onClick={this.salvar}>
                        Salvar
                      </button>
                      <NavLink to={"/"} className="nav-link">
                        <button
                          className="btn btn-danger"
                          onClick={this.voltar}
                        >
                          Voltar
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Rede;
