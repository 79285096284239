import React, { Component } from "react";
import Header from "../../componentes/Header";
import Sidebar from "../../componentes/Sidebar";
import Footer from "../../componentes/Footer";
import ApiService from "../../service/ApiService";
import { Redirect, NavLink } from "react-router-dom";
import Moment from "react-moment";
import printJS from "print-js";

class VerPedidos extends Component {
  constructor(props) {
    super(props);
    console.log("akiiiiaaa");
    this.state = {
      pedido: {
        id: null,
        nome: "",
        status: "",
        dataaltera: null,
        usuarioaltera: null,
      },
      trava: false,
      eventos: [],
      transacoes: [],
      transacoesbin: [],
      transacoescielo: [],
      transacoesrede: [],
      transacoesgetnet: [],
      pontos: [],
      cashbacks: [],
      motoboys: [],
      motoboy: null,
      message: null,
      message2: null,
      submitted: false,
      segmento: 0,
    };
    this.salvar = this.salvar.bind(this);
    this.nfe = this.nfe.bind(this);
    this.entregar = this.entregar.bind(this);
    this.downloadnfe = this.downloadnfe.bind(this);
    this.downloadnfepdf = this.downloadnfepdf.bind(this);
    this.downloadnfepdfsimp = this.downloadnfepdfsimp.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeMotoboy = this.onChangeMotoboy.bind(this);
    this.cancelar = this.cancelar.bind(this);
  }

  onChangeMotoboy(e) {
    this.setState({
      motoboy: e.target.value,
    });
  }

  entregar() {
    this.setState({ message2: "" });
    this.setState({ trava: true });
    var ok = true;

    if (this.state.motoboy == null) {
      ok = false;
      alert("selecione o motoboy");
      this.setState({ trava: false });
    }

    if (ok) {
      this.state.pedido.motoboy_fk = this.state.motoboy;
      ApiService.enviarpedido(this.state.pedido)
        .then((response) => {
          if (response.status === 200) {
            this.setState({ pedido: response.data });
            ApiService.eventosp(this.state.pedido.id)
              .then((response) => {
                this.setState({
                  eventos: response.data,
                });
                console.log(response.data);
              })
              .catch((e) => {
                var er = e.response;
                var ed = "";
                if (er !== undefined) {
                  ed = e.response.data;
                }
                this.setState({
                  message2:
                    "Erro ao listar eventos:" +
                    (ed !== undefined ? ed.message : ""),
                });
                console.log(e);
              });
            document.getElementById("btnentregar").click();
            this.setState({ message2: "Pedido Enviado Com Sucesso!" });
          } else {
            alert("erro ao processar o pedido");
          }
          window.scrollTo(0, 0);
          this.setState({ trava: false });
        })
        .catch((e) => {
          var er = e.response;
          var ed = "";
          if (er !== undefined) {
            ed = e.response.data;
          }
          this.setState({
            message2:
              "erro ao enviar pedido:" + (ed !== undefined ? ed.message : ""),
          });
          window.scrollTo(0, 0);
          console.log(e);
          this.setState({ trava: false });
        });
    }
  }

  cancelar() {
    this.setState({ trava: true });
    this.setState({ message2: "" });
    var ok = true;
    this.setState({ isActive: true });
    this.setState({ textoloading: "Processando seu pedido..." });
    if (ok) {
      this.state.pedido.canceladopor = "pela loja";
      ApiService.cancelarpedido(this.state.pedido)
        .then((response) => {
          if (response.status === 200) {
            this.setState({ pedido: response.data });
            this.setState({ message2: "Pedido Cancelado Com Sucesso!" });
            window.scrollTo(0, 0);
            this.setState({ isActive: false });
            this.setState({ textoloading: "" });
          }

          // this.setState ({trava: false});
        })
        .catch((e) => {
          var er = e.response;
          var ed = "";
          if (er !== undefined) {
            ed = e.response.data;
          }
          this.setState({ trava: false });
          this.setState({ isActive: false });
          this.setState({ textoloading: "" });
          this.setState({
            message2:
              "Erro ao cancelar pedido:" + (ed !== undefined ? ed.message : ""),
          });
          window.scrollTo(0, 0);
       //   console.log(e);
        });
    }
  }

  componentDidMount() {
    ApiService.segmento()
      .then((response) => {
        if (response.status === 200) {
          this.setState({ segmento: response.data });
        }
      })
      .catch((e) => {
        ///  alert ('Erro ao recuperar idtawkto email');
        console.log(e);
      });
    console.log(this.props.match.params.id + "kkkkk");
    ApiService.pedido(this.props.match.params.id)
      .then((response) => {
        this.setState({
          pedido: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        var er = e.response;
        var ed = "";
        if (er !== undefined) {
          ed = e.response.data;
        }
        this.setState({
          message:
            "Erro ao atualizar pedido:" + (ed !== undefined ? ed.message : ""),
        });
        console.log(e);
      });

    ApiService.eventosp(this.props.match.params.id)
      .then((response) => {
        this.setState({
          eventos: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        var er = e.response;
        var ed = "";
        if (er !== undefined) {
          ed = e.response.data;
        }
        this.setState({
          message:
            "Erro ao listar eventos:" + (ed !== undefined ? ed.message : ""),
        });
        console.log(e);
      });

    ApiService.transancaosp(this.props.match.params.id)
      .then((response) => {
        this.setState({
          transacoes: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        var er = e.response;
        var ed = "";
        if (er !== undefined) {
          ed = e.response.data;
        }
        this.setState({
          message:
            "Erro ao listar transacoes:" + (ed !== undefined ? ed.message : ""),
        });
        console.log(e);
      });

    ApiService.transancaospbin(this.props.match.params.id)
      .then((response) => {
        this.setState({
          transacoesbin: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        var er = e.response;
        var ed = "";
        if (er !== undefined) {
          ed = e.response.data;
        }
        this.setState({
          message:
            "Erro ao listar transacoes:" + (ed !== undefined ? ed.message : ""),
        });
        console.log(e);
      });

      ApiService.transancaospgetnet(this.props.match.params.id)
      .then((response) => {
        this.setState({
          transacoesgetnet: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        var er = e.response;
        var ed = "";
        if (er !== undefined) {
          ed = e.response.data;
        }
        this.setState({
          message:
            "Erro ao listar transacoes:" + (ed !== undefined ? ed.message : ""),
        });
        console.log(e);
      });

      ApiService.transancaosprede(this.props.match.params.id)
      .then((response) => {
        this.setState({
          transacoesrede: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        var er = e.response;
        var ed = "";
        if (er !== undefined) {
          ed = e.response.data;
        }
        this.setState({
          message:
            "Erro ao listar transacoes:" + (ed !== undefined ? ed.message : ""),
        });
        console.log(e);
      });

      ApiService.transancaospcielo(this.props.match.params.id)
      .then((response) => {
        this.setState({
          transacoescielo: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        var er = e.response;
        var ed = "";
        if (er !== undefined) {
          ed = e.response.data;
        }
        this.setState({
          message:
            "Erro ao listar transacoes:" + (ed !== undefined ? ed.message : ""),
        });
        console.log(e);
      });

    ApiService.pontosp(this.props.match.params.id)
      .then((response) => {
        this.setState({
          pontos: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        var er = e.response;
        var ed = "";
        if (er !== undefined) {
          ed = e.response.data;
        }
        this.setState({
          message:
            "Erro ao listar pontos:" + (ed !== undefined ? ed.message : ""),
        });
        console.log(e);
      });

    ApiService.cashbacksp(this.props.match.params.id)
      .then((response) => {
        this.setState({
          cashbacks: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        var er = e.response;
        var ed = "";
        if (er !== undefined) {
          ed = e.response.data;
        }
        this.setState({
          message:
            "Erro ao listar cashbacks:" + (ed !== undefined ? ed.message : ""),
        });
        console.log(e);
      });

    ApiService.motoboysa()
      .then((response) => {
        this.setState({
          motoboys: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        var er = e.response;
        var ed = "";
        if (er !== undefined) {
          ed = e.response.data;
        }
        this.setState({
          message:
            "Erro ao listar motoboys:" + (ed !== undefined ? ed.message : ""),
        });
        console.log(e);
      });

    window.scrollTo(0, 0);
  }

  onChangeNome(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      pedido: {
        ...prevState.pedido,
        nome: nomex,
      },
    }));
  }

  onChangeStatus(e) {
    const statusx = e.target.value;

    this.setState((prevState) => ({
      pedido: {
        ...prevState.pedido,
        status: statusx,
      },
    }));
  }

  salvar() {}

  async nfe() {
    this.setState({ message2: "" });
    this.setState({ trava: true });
    var ok = true;
    var okc = true;

    if (ok) {
      await ApiService.iniciarnfe(this.state.pedido)
        .then((response) => {
          if (response.status === 200) {
            this.setState({ pedido: response.data });
            document.getElementById("btnnfe").click();
            this.setState({ message2: "Nfe do pedido criado Com Sucesso!" });
            ApiService.eventosp(this.state.pedido.id)
              .then((response) => {
                this.setState({
                  eventos: response.data,
                });
                console.log(response.data);
              })
              .catch((e) => {
                var er = e.response;
                var ed = "";
                if (er !== undefined) {
                  ed = e.response.data;
                }
                document.getElementById("btnnfe").click();
                this.setState({
                  message2:
                    "Erro ao listar eventos:" +
                    (ed !== undefined ? ed.message : ""),
                });
                console.log(e);
              });
          } else {
            document.getElementById("btnnfe").click();
            alert("erro ao processar o pedido");
            okc = false;
          }

          window.scrollTo(0, 0);
          this.setState({ trava: false });
        })
        .catch((e) => {
          document.getElementById("btnnfe").click();
          okc = false;

          var er = e.response;
          var ed = "";
          if (er !== undefined) {
            ed = e.response.data;
          }

          this.setState({
            message2:
              "erro ao processar pedido:" +
              (ed !== undefined ? ed.message : ""),
          });
          window.scrollTo(0, 0);
          console.log(e);
          this.setState({ trava: false });
        });
    }
  }

  downloadnfe() {
    ApiService.downloadnfe(this.state.pedido.id, "xml")
      .then((response) => {
        console.log(response.data);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "nfe-" + this.state.pedido.numeropedido + ".xml"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        var er = e.response;
        var ed = "";
        if (er !== undefined) {
          ed = e.response.data;
        }
        this.setState({
          message:
            "Erro ao fazer download do xml:" +
            (ed !== undefined ? ed.message : ""),
        });
        console.log(e);
      });
  }

  downloadnfepdf() {
    ApiService.downloadnfe(this.state.pedido.id, "pdf")
      .then((response) => {
        console.log(response.data);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        /* const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'nfe-' + this.state.pedido.numeropedido + '.pdf'
        ); //or any other extension
        document.body.appendChild(link);
        link.click();*/
        printJS(url);
      })
      .catch((e) => {
        var er = e.response;
        var ed = "";
        if (er !== undefined) {
          ed = e.response.data;
        }
        this.setState({
          message:
            "Erro ao fazer download do pdf:" +
            (ed !== undefined ? ed.message : ""),
        });
        console.log(e);
      });
  }

  downloadnfepdfsimp() {
    ApiService.downloadnfesimp(this.state.pedido.id, "pdf")
      .then((response) => {
        console.log(response.data);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        /* const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'nfe-' + this.state.pedido.numeropedido + '-simplificado.pdf'
        ); //or any other extension
        document.body.appendChild(link);
        link.click();*/
        printJS(url);
      })
      .catch((e) => {
        var er = e.response;
        var ed = "";
        if (er !== undefined) {
          ed = e.response.data;
        }
        this.setState({
          message:
            "Erro ao fazer download do pdf:" +
            (ed !== undefined ? ed.message : ""),
        });
        console.log(e);
      });
  }

  badge(status) {
    switch (status) {
      case "Enviado":
        return "badge badge-success";
      case "Pago":
        return "badge badge-success";
      case "Pendente":
        return "badge badge-info";
      case "NFe Gerada":
        return "badge badge-primary";
      case "Cancelado":
        return "badge badge-danger";
      case "Processando":
        return "badge badge-warning";
      case "Danfe Gerada":
        return "badge badge-primary";
      case "Em Transito":
        return "badge badge-success";
      default:
        return "";
    }
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/pedidos" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: "640px" }}>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Ver Pedido</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Ver Pedido</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="container">
              <div className="row">
                {this.state.message2 && (
                  <div className="col-12">
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message2}
                    </div>
                  </div>
                )}

                {this.state.pedido.id === undefined && (
                  <div className="col-12">
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>
                  </div>
                )}

                <div className="col-12">
                  <div className="invoice p-3 mb-3">
                    <div className="row">
                      <div className="col-12">
                        <h4>
                          <div className="btn-group" />

                          <small className="float-right">
                            Data:{" "}
                            <Moment format="DD/MM/YYYY HH:mm:ss">
                              {this.state.pedido.datapedido}
                            </Moment>{" "}
                          </small>
                        </h4>
                      </div>
                    </div>

                    <div className="row invoice-info">
                      <div className="col-sm-4 invoice-col">
                        <address>
                          <strong>Dados de Entrega:</strong>
                          <br />
                          {this.state.pedido.logradouro},{" "}
                          {this.state.pedido.numero}
                          <br />
                          Bairro: {this.state.pedido.bairro}
                          <br />
                          Cep: {this.state.pedido.cep}
                          <br /> Complemento:
                          {this.state.pedido.complemento}
                          <br />
                          {this.state.pedido.cidade}, {this.state.pedido.uf}
                          <br />Servico Correios:
                          {this.state.pedido.servico}
                          <br />Prazo Correios:
                          {this.state.pedido.prazo}
                        </address>
                      </div>

                      <div className="col-sm-4 invoice-col">
                        <address>
                          <strong>Dados do Cliente:</strong>
                          <br />
                          Nome: {this.state.pedido.nome}
                          <br />
                          CPF: {this.state.pedido.cpfcnpj}
                          <br />
                          Telefone: {this.state.pedido.telefone}
                          <br />
                          Email: {this.state.pedido.email}
                        </address>
                      </div>

                      <div className="col-sm-4 invoice-col">
                        <b>Nº Pedido:</b> {this.state.pedido.numeropedido}
                        <br />
                        <b>Status:</b>{" "}
                        <span
                          className={this.badge(this.state.pedido.status)}
                          style={{ width: "100px" }}
                        >
                          {this.state.pedido.status === "Processando"
                            ? "Pendente"
                            : this.state.pedido.status}
                        </span>
                        <br />
                        <b>Data Pagamento:</b>{" "}
                        {this.state.pedido.datapagamento && (
                          <Moment format="DD/MM/YYYY HH:mm:ss">
                            {this.state.pedido.datapagamento}
                          </Moment>
                        )}
                        <br />
                        <b>Tipo Pagamento:</b> {this.state.pedido.tipopagamento}{" "}
                        {this.state.pedido.parcela}
                        {this.state.pedido.tipopagamento == "cartaocredito" && (
                          <div>
                            Bandeira: {this.state.pedido.bandeira}
                            <br />
                            Cartao:{" "}
                            {this.state.pedido.finalcartao != undefined
                              ? this.state.pedido.finalcartao
                              : ""}
                            <br />
                          </div>
                        )}
                        <br />
                        {this.state.pedido.datanfe != undefined && (
                          <div>
                            <button
                              className="btn btn-primary"
                              onClick={this.downloadnfe}
                            >
                              XML NFE
                            </button>
                            &nbsp;&nbsp;
                            <button
                              className="btn btn-warning"
                              onClick={this.downloadnfepdf}
                            >
                              &nbsp;&nbsp;DANFE&nbsp;&nbsp;
                            </button>
                            &nbsp;&nbsp;
                            <button
                              className="btn btn-warning"
                              onClick={this.downloadnfepdfsimp}
                            >
                              &nbsp;&nbsp;DANFE Simp.&nbsp;&nbsp;
                            </button>
                          </div>
                        )}
                        {this.state.pedido.dataenvio && (
                          <b>Data Envio:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                        )}
                        {this.state.pedido.dataenvio && (
                          <Moment format="DD/MM/YYYY HH:mm:ss">
                            {this.state.pedido.dataenvio}
                          </Moment>
                        )}
                        <br />
                        {this.state.pedido.dataentrega && (
                          <b>Data Entrega:&nbsp;</b>
                        )}
                        {this.state.pedido.dataentrega && (
                          <Moment format="DD/MM/YYYY HH:mm:ss">
                            {this.state.pedido.dataentrega}
                          </Moment>
                        )}
                        <br />
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {(this.state.pedido.status === "Processando" ||
                          this.state.pedido.status === "Pago" ||
                          this.state.pedido.status === "Pix" ||
                          this.state.pedido.status === "Nao Entregue") && (
                          <button
                            className="btn btn-danger"
                            data-toggle="modal"
                            data-target="#exampleModal"
                            style={{ width: "150px" }}
                          >
                            Cancelar Pedido
                          </button>
                        )}
                        &nbsp;&nbsp;&nbsp;
                        {(this.state.pedido.status === "Pago" ||
                          this.state.pedido.status === "NFe Gerada" ||
                          this.state.pedido.status === "Emitindo NFe") && (
                          <button
                            className="btn btn-info"
                            data-toggle="modal"
                            data-target="#modalNFE"
                            style={{ width: "150px" }}
                          >
                            Gerar NFe
                          </button>
                        )}
                        &nbsp;&nbsp;&nbsp;
                        {(this.state.pedido.status === "Danfe Gerada" ||
                          this.state.pedido.status === "Nao Entregue") && (
                          <button
                            className="btn btn-info"
                            data-toggle="modal"
                            data-target="#modalEntrega"
                            style={{ width: "150px" }}
                          >
                            Entregar Pedido
                          </button>
                        )}
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-12 table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Codigo</th>
                              <th>Codigo ERP</th>
                              <th>Produto</th>
                              <th>Quantidade</th>
                              <th>Valor.Unit</th>
                              <th>SubTotal</th>
                              <th>Despesa</th>
                              <th>Frete</th>

                              <th>Desconto</th>
                              <th>Total</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.pedido.itens &&
                              this.state.pedido.itens.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.produto.id}</td>
                                  <td>{item.produto.produtofk}</td>
                                  <td>
                                    {" "}
                                    {item.nome}
                                    {item.cor != undefined
                                      ? "(" + item.cor + ")"
                                      : ""}
                                  </td>
                                  <td>{item.quantidade}</td>
                                  <td>R$ {Number(item.valor).toFixed(2)}</td>

                                  <td>
                                    R${" "}
                                    {Number(
                                      item.valor * item.quantidade
                                    ).toFixed(2)}
                                  </td>
                                  <td> R$ {Number(item.despesa).toFixed(2)}</td>
                                  <td> R$ {Number(item.frete).toFixed(2)}</td>
                                  <td>
                                    R${" "}
                                    {Number(
                                      item.desconto * item.quantidade
                                    ).toFixed(2)}
                                  </td>

                                  <td>R$ {Number(item.total).toFixed(2)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-4">
                        <div className="table-responsive">
                          <table className="table">
                            <tr>
                              <th style={{ width: "50%" }}>Subtotal:</th>
                              <td>
                                R${" "}
                                {Number(this.state.pedido.subtotal).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <th>Despesa:</th>
                              <td>
                                R${" "}
                                {Number(this.state.pedido.despesa).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <th>Frete:</th>
                              <td>
                                R$ {Number(this.state.pedido.frete).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ color: "red" }}>Desconto:</th>
                              <td style={{ color: "red" }}>
                                R${" "}
                                {Number(this.state.pedido.desconto).toFixed(2)}
                              </td>
                            </tr>
                            {this.state.segmento == "11" && (
                              <tr>
                                <th>Montagem:</th>
                                <td>
                                  R${" "}
                                  {Number(
                                    this.state.pedido.valormontagem
                                  ).toFixed(2)}
                                </td>
                              </tr>
                            )}
                            <tr>
                              <th>Total:</th>
                              <td>
                                R$ {Number(this.state.pedido.total).toFixed(2)}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.transacoesgetnet.length > 0 && (
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-transparent">
                        <h3 className="card-title">Últimas Transações GetNet</h3>

                        <div className="card-tools" />
                      </div>

                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <table className="table m-0">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Data</th>
                                <th>Status</th>
                                <th>Message</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.transacoesgetnet &&
                                this.state.transacoesgetnet.map((transacao) => (
                                  <tr key={transacao.id}>
                                    <td> {transacao.paymentid}</td>
                                    <td>
                                      <Moment format="DD/MM/YYYY HH:mm:ss">
                                        {transacao.dataaltera}
                                      </Moment>
                                    </td>
                                    <td>
                                      <span>{transacao.status}</span>
                                    </td>
                                    <td> {transacao.returncode}-{transacao.returnmessage}</td>
                                    <td>
                                      <NavLink
                                        to={`/ver-transacao-getnet/${transacao.id}`}
                                        className="nav-link"
                                      >
                                        #detalhe
                                      </NavLink>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                
                {this.state.transacoesrede.length > 0 && (
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-transparent">
                        <h3 className="card-title">Últimas Transações Rede</h3>

                        <div className="card-tools" />
                      </div>

                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <table className="table m-0">
                            <thead>
                              <tr>
                                <th>TID</th>
                                <th>Data</th>
                                <th>Status</th>
                                <th>Message</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.transacoesrede &&
                                this.state.transacoesrede.map((transacao) => (
                                  <tr key={transacao.id}>
                                    <td> {transacao.tid}</td>
                                    <td>
                                      <Moment format="DD/MM/YYYY HH:mm:ss">
                                        {transacao.dataaltera}
                                      </Moment>
                                    </td>
                                    <td>
                                      <span>{transacao.status}</span>
                                    </td>
                                    <td> {transacao.returncode}-{transacao.returnmessage}</td>
                                    <td>
                                      <NavLink
                                        to={`/ver-transacao-rede/${transacao.id}`}
                                        className="nav-link"
                                      >
                                        #detalhe
                                      </NavLink>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.transacoescielo.length > 0 && (
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-transparent">
                        <h3 className="card-title">Últimas Transações Cielo</h3>

                        <div className="card-tools" />
                      </div>

                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <table className="table m-0">
                            <thead>
                              <tr>
                                <th>Transaction Id</th>
                                <th>Data</th>
                                <th>Status</th>
                                <th>Message</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.transacoescielo &&
                                this.state.transacoescielo.map((transacao) => (
                                  <tr key={transacao.id}>
                                    <td> {transacao.paymentid}</td>
                                    <td>
                                      <Moment format="DD/MM/YYYY HH:mm:ss">
                                        {transacao.dataaltera}
                                      </Moment>
                                    </td>
                                    <td>
                                      <span>{transacao.status}</span>
                                    </td>
                                    <td> {transacao.returncode}-{transacao.returnmessage}</td>
                                    <td>
                                      <NavLink
                                        to={`/ver-transacao-cielo/${transacao.id}`}
                                        className="nav-link"
                                      >
                                        #detalhe
                                      </NavLink>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.transacoesbin.length > 0 && (
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-transparent">
                        <h3 className="card-title">Últimas Transações Bin</h3>

                        <div className="card-tools" />
                      </div>

                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <table className="table m-0">
                            <thead>
                              <tr>
                                <th>Transaction Id</th>
                                <th>Data</th>
                                <th>Status</th>
                                <th>Message</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.transacoesbin &&
                                this.state.transacoesbin.map((transacao) => (
                                  <tr key={transacao.id}>
                                    <td> {transacao.transactionid}</td>
                                    <td>
                                      <Moment format="DD/MM/YYYY HH:mm:ss">
                                        {transacao.dataaltera}
                                      </Moment>
                                    </td>
                                    <td>
                                      <span>{transacao.status}</span>
                                    </td>
                                    <td> {transacao.responsemessage}</td>
                                    <td>
                                      <NavLink
                                        to={`/ver-transacao-bin/${transacao.id}`}
                                        className="nav-link"
                                      >
                                        #detalhe
                                      </NavLink>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.transacoes.length > 0 && (
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-transparent">
                        <h3 className="card-title">
                          Últimas Transações Esitef
                        </h3>

                        <div className="card-tools" />
                      </div>

                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <table className="table m-0">
                            <thead>
                              <tr>
                                <th>USN</th>
                                <th>Data</th>
                                <th>Status</th>
                                <th>Code</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.transacoes &&
                                this.state.transacoes.map((transacao) => (
                                  <tr key={transacao.id}>
                                    <td> {transacao.merchant_usn}</td>
                                    <td>
                                      <Moment format="DD/MM/YYYY HH:mm:ss">
                                        {transacao.dataaltera}
                                      </Moment>
                                    </td>
                                    <td>
                                      <span>
                                        {transacao.status}- {transacao.rstatus}-{" "}
                                        {transacao.rstatusx}
                                      </span>
                                    </td>
                                    <td>
                                      {" "}
                                      {transacao.code}- {transacao.rcode}
                                    </td>
                                    <td>
                                      <NavLink
                                        to={`/ver-transacao/${transacao.id}`}
                                        className="nav-link"
                                      >
                                        #detalhe
                                      </NavLink>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.eventos.length > 0 && (
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-transparent">
                        <h3 className="card-title">Últimos Eventos</h3>

                        <div className="card-tools" />
                      </div>

                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <table className="table m-0">
                            <thead>
                              <tr>
                                <th>Data Hora</th>
                                <th>Descrição</th>
                                <th>Status</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.eventos &&
                                this.state.eventos.map((evento) => (
                                  <tr key={evento.id}>
                                    <td>
                                      <Moment format="DD/MM/YYYY HH:mm:ss">
                                        {evento.dataaltera}
                                      </Moment>
                                    </td>
                                    <td>
                                      <span>{evento.descricao}</span>
                                    </td>
                                    <td>
                                      {evento.status === "ok" && (
                                        <span
                                          className={"badge badge-success"}
                                          style={{ width: "80%" }}
                                        >
                                          {evento.status}
                                        </span>
                                      )}
                                      {evento.status === "erro" && (
                                        <div>
                                          <span
                                            className={"badge badge-danger"}
                                            style={{ width: "80%" }}
                                          >
                                            {evento.status}
                                          </span>
                                        </div>
                                      )}
                                      {evento.status === "info" && (
                                        <div>
                                          <span
                                            className={"badge badge-info"}
                                            style={{ width: "80%" }}
                                          >
                                            {evento.status}
                                          </span>
                                        </div>
                                      )}
                                    </td>
                                    <td>
                                      <NavLink
                                        to={`/ver-evento/${evento.id}`}
                                        className="nav-link"
                                      >
                                        #detalhe
                                      </NavLink>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="card-footer clearfix" />
                    </div>
                  </div>
                )}

                {this.state.pontos.length > 0 && (
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-transparent">
                        <h3 className="card-title">Pontos Fidelidade</h3>

                        <div className="card-tools" />
                      </div>

                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <table className="table m-0">
                            <thead>
                              <tr>
                                <th>Codigo</th>
                                <th>Produto</th>
                                <th>Total Produto</th>
                                <th>Regras</th>
                                <th>Total Pontos</th>
                                <th>Status</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.pontos &&
                                this.state.pontos.map((ponto) => (
                                  <tr key={ponto.id}>
                                    <td>{ponto.produto_fk}</td>
                                    <td>{ponto.produto.nome}</td>
                                    <td>
                                      {Number(ponto.totalproduto).toFixed(2)}
                                    </td>
                                    <td>
                                      {ponto.valor +
                                        " " +
                                        ponto.percentualreal +
                                        " = " +
                                        ponto.equivale +
                                        " pontos"}
                                    </td>
                                    <td>
                                      {Number(ponto.totalpontos).toFixed(2)}
                                    </td>
                                    <td>{ponto.status}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        &nbsp;
                        <div style={{ fontWeight: "bold", marginLeft: "15px" }}>
                          TOTAL DE PONTOS:{" "}
                          {Number(
                            this.state.pontos.reduce(
                              (prev, next) => prev + next.totalpontos,
                              0
                            )
                          ).toFixed(2)}
                        </div>
                      </div>

                      <div className="card-footer clearfix" />
                    </div>
                  </div>
                )}

                {this.state.cashbacks.length > 0 && (
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-transparent">
                        <h3 className="card-title">CashBack</h3>

                        <div className="card-tools" />
                      </div>

                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <table className="table m-0">
                            <thead>
                              <tr>
                                <th>Codigo</th>
                                <th>Produto</th>
                                <th>Total Produto</th>
                                <th>Regras</th>
                                <th>Total CashBack</th>
                                <th>Status</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.cashbacks &&
                                this.state.cashbacks.map((cashback) => (
                                  <tr key={cashback.id}>
                                    <td>{cashback.produto_fk}</td>
                                    <td>{cashback.produto.nome}</td>
                                    <td>
                                      {Number(cashback.totalproduto).toFixed(2)}
                                    </td>
                                    <td>
                                      {cashback.valor +
                                        " " +
                                        cashback.percentualreal +
                                        " = " +
                                        cashback.equivale +
                                        " em dinheiro "}
                                    </td>
                                    <td>
                                      {Number(cashback.totalpontos).toFixed(2)}
                                    </td>
                                    <td>{cashback.status}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        &nbsp;
                        <div style={{ fontWeight: "bold", marginLeft: "15px" }}>
                          TOTAL DE CASHBACK:{" "}
                          {Number(
                            this.state.cashbacks.reduce(
                              (prev, next) => prev + next.totalpontos,
                              0
                            )
                          ).toFixed(2)}
                        </div>
                      </div>

                      <div className="card-footer clearfix" />
                    </div>
                  </div>
                )}

                <div
                  className="modal fade"
                  id="modalEntrega"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Entrega do Pedido
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="form-group">
                          <label>MotoBoy:</label>
                          <select
                            required
                            className="form-control select2"
                            style={{ width: "460px" }}
                            value={this.state.motoboy}
                            onChange={this.onChangeMotoboy}
                            name="categoria"
                          >
                            <option defaultValue>Selecione</option>
                            {this.state.motoboys &&
                              this.state.motoboys.map((m) => (
                                <option value={m.id}>
                                  {m.id +
                                    " - " +
                                    m.nome +
                                    " Moto:" +
                                    m.modelo +
                                    "/" +
                                    m.placa}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          disabled={this.state.trava}
                          onClick={this.entregar}
                          className="btn btn-primary"
                        >
                          Confirmar{this.state.trava ? "...aguarde" : ""}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          id="btnentregar"
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="modalNFE"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Gerar NFe
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        Deseja Gerar NFe do Pedido?
                      </div>
                      <div className="modal-footer">
                        <button
                          disabled={this.state.trava}
                          className="btn btn-primary"
                          onClick={this.nfe}
                        >
                          Confirmar{this.state.trava ? "...aguarde" : ""}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          id="btnnfe"
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Cancelamento de Pedido
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        Deseja Cancelar Esse Pedido?
                      </div>
                      <div className="modal-footer">
                        <button
                          disabled={this.state.trava}
                          onClick={this.cancelar}
                          className="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Sim{this.state.trava ? "...aguarde" : ""}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Nao
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            &nbsp;
            <div className="card-footer">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NavLink to={"/pedidos/"} className="nav-link">
                  <button className="btn btn-danger" onClick={this.voltar}>
                    Voltar
                  </button>
                </NavLink>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default VerPedidos;
