import React, { Component } from "react";
import Header from "../../componentes/Header";
import Sidebar from "../../componentes/Sidebar";
import Footer from "../../componentes/Footer";
import ApiService from "../../service/ApiService";
import { Redirect, NavLink } from "react-router-dom";

class EditContatos extends Component {
  constructor(props) {
    super(props);
    console.log("akiiiiaaa");
    this.state = {
      categoria: {},
      message: null,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/contatos" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: "640px" }}>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Editar Contato</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Editar Contato</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-lg-6">
                <div className="card card-primary">
                  {this.state.message && (
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>
                  )}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="">Nome:</label>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id=""
                        placeholder="Insira o nome do contato"
                        required
                        // value={this.state.nome}
                        // onChange={this.onChangeNome}
                        name="nome"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="">Email:</label>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id=""
                        placeholder="Insira o email do contato"
                        required
                        // value={this.state.nome}
                        // onChange={this.onChangeNome}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="">Telefone:</label>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id=""
                        placeholder="Insira o telefone do contato"
                        required
                        // value={this.state.nome}
                        // onChange={this.onChangeNome}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="">Celular:</label>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id=""
                        placeholder="Insira o celular do contato"
                        required
                        // value={this.state.nome}
                        // onChange={this.onChangeNome}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="">Cpf:</label>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id=""
                        placeholder="Insira o CPF do contato"
                        required
                        // value={this.state.nome}
                        // onChange={this.onChangeNome}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-primary">
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="">Rua:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id=""
                        placeholder="Insira a rua"
                        required
                        // value={this.state.nome}
                        // onChange={this.onChangeNome}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="">Bairro:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id=""
                        placeholder="Insira o bairro"
                        required
                        // value={this.state.nome}
                        // onChange={this.onChangeNome}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="">Cidade:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id=""
                        placeholder="Insira o bairro"
                        required
                        // value={this.state.nome}
                        // onChange={this.onChangeNome}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="">UF:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id=""
                        placeholder="Insira o bairro"
                        required
                        // value={this.state.nome}
                        // onChange={this.onChangeNome}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="">Complemento:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id=""
                        placeholder="Insira o complemento"
                        required
                        // value={this.state.nome}
                        // onChange={this.onChangeNome}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  className="btn btn-primary"
                  //   onClick={this.salvar}
                >
                  Adicionar
                </button>

                <NavLink to={"/contatos/"} className="nav-link">
                  <button className="btn btn-danger" onClick={this.voltar}>
                    Voltar
                  </button>
                </NavLink>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default EditContatos;
