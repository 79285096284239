import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ListaEventos from '../eventos/ListaEventos';

class Eventos extends Component {
  componentDidMount () {
    console.log('ff');
    window.scrollTo (0, 0);
  }

  render () {
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <ListaEventos />
        <Footer />
      </div>
    );
  }
}

export default Eventos;
