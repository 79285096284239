import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import {Redirect, NavLink} from 'react-router-dom';

class EditRedes extends Component {
  constructor (props) {
    super (props);
    console.log ('akiiiiaaa');
    this.state = {
      loja: {
        id: null,
        facebook: '',
        twitter:'',
        instagram:'',
        linkedin:'',
      },
      message: null,
      message2: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind (this);
    this.onChangeFacebook = this.onChangeFacebook.bind (this);
    this.onChangeTwitter= this.onChangeTwitter.bind (this);
    this.onChangeInstragram = this.onChangeInstragram.bind (this);
    this.onChangeLinkedin= this.onChangeLinkedin.bind (this);
  }
  componentDidMount () {

    ApiService.loja ()
      .then (response => {
        this.setState ({
          loja: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao atualizar loja'});
        console.log (e);
      });
    window.scrollTo (0, 0);
  }

 

  onChangeFacebook(e) {
    const nomex = e.target.value;
    
    this.setState(prevState => ({
      loja: {
        ...prevState.loja,
        facebook: nomex
      }
    }));
  }

  onChangeTwitter(e) {
    const nomex = e.target.value;
    
    this.setState(prevState => ({
      loja: {
        ...prevState.loja,
        twitter: nomex
      }
    }));
  }

  onChangeInstragram(e) {
    const nomex = e.target.value;
    
    this.setState(prevState => ({
      loja: {
        ...prevState.loja,
        instagram: nomex
      }
    }));
  }

  onChangeLinkedin(e) {
    const nomex = e.target.value;
    
    this.setState(prevState => ({
      loja: {
        ...prevState.loja,
        linkedin: nomex
      }
    }));
  }

 

  salvar () {
    var data = {
      id: this.state.loja.id,
      facebook: this.state.loja.facebook,
      twitter: this.state.loja.twitter,
      instagram: this.state.loja.instagram,
      linkedin: this.state.loja.linkedin,

    };

    ApiService.atualizarloja3 (data)
      .then (response => {
        this.setState ({
          submitted: true,
        });
        console.log (response.data);
        window.scrollTo (0, 0);
        this.setState ({message2: 'Loja atualizada com sucesso'});
      })
      .catch (e => {
        this.setState ({message: 'Erro ao atualizar loja'});
        console.log (e);
      });
  }

  render () {
    
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '640px'}}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Redes Sociais</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                    Redes Sociais
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                    {this.state.message2 &&
                    <div className="alert alert-info alert-dismissible">
                      {this.state.message2}
                    </div>}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomeloja">Código da Loja:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.loja.id}
                        name="id"
                        disabled
                        style={{width:"10%"}}
                      />
                    </div>

                    <div className="form-group">                    
                      <label htmlFor="nomeloja">Facebook:</label>                   
                        <input
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="nomeloja"
                          placeholder="Insira o Facebook da Loja"
                          required
                          value={this.state.loja.facebook}
                          onChange={this.onChangeFacebook}
                          name="nome"
                          style={{width:"25%"}}
                        />   
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomeloja">Twitter:</label>
                      <input
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="nomeloja"
                        placeholder="Insira o Twitter da Loja"
                        required
                        value={this.state.loja.twitter}
                        onChange={this.onChangeTwitter}
                        name="nome"
                        style={{width:"25%"}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomeloja">Instagram:</label>
                      <input
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="nomeloja"
                        placeholder="Insira o Instagram da Loja"
                        required
                        value={this.state.loja.instagram}
                        onChange={this.onChangeInstragram}
                        name="nome"
                        style={{width:"25%"}}
                      />
                    </div>


                    <div className="form-group">
                      <label htmlFor="nomeloja">Linkedin:</label>
                      <input
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="nomeloja"
                        placeholder="Insira o LinkedIn"
                        required
                        value={this.state.loja.linkedin}
                        onChange={this.onChangeLinkedin}
                        name="nome"
                        style={{width:"25%"}}
                      />
                    </div>                   
                  </div>

                  <div className="card-footer">
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                  <button className="btn btn-primary" onClick={this.salvar}>
                      Salvar
                    </button>
                    <NavLink to={'/'} className="nav-link">
                      <button className="btn btn-danger" onClick={this.voltar}>
                        Voltar
                      </button>
                    </NavLink>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </section>

        </div>
        <Footer />
      </div>
    );
  }
}

export default EditRedes;
