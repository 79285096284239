import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import {Redirect, NavLink} from 'react-router-dom';
import ImageUploader from 'react-images-upload';

class EditDestaques extends Component {
  constructor (props) {
    super (props);
    console.log ('akiiiiaaa');
    this.state = {
      destaque: {
        id: null,
        nome: '',
        link: '',
        status: '',
        pictures: {},
        pictures2: [],
        dataaltera: null,
        usuarioaltera: null,
      },
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind (this);
    this.onChangeNome = this.onChangeNome.bind (this);
    this.onChangeLink = this.onChangeLink.bind (this);
    this.onChangeStatus = this.onChangeStatus.bind (this);
    this.onDrop = this.onDrop.bind (this);
  }

  async onDrop (pictureFiles, pictureDataURLs) {
    await this.setState ({
      pictures2: pictureFiles,
    });
    console.log (this.state.pictures2);
  }

  componentDidMount () {
    console.log (this.props.match.params.id + 'kkkkk');
    ApiService.destaque (this.props.match.params.id)
      .then (response => {
        this.setState ({
          destaque: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao atualizar destaque'});
        console.log (e);
      });

    ApiService.fotosdestaque (this.props.match.params.id)
      .then (response => {
        console.log(response.data);
        this.setState ({
          pictures: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao listar fotos do destaque'});
        console.log (e);
      });

    window.scrollTo (0, 0);
  }

  onChangeLink (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      destaque: {
        ...prevState.destaque,
        link: nomex,
      },
    }));
  }

  onChangeNome (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      destaque: {
        ...prevState.destaque,
        nome: nomex,
      },
    }));
  }

  onChangeStatus (e) {
    const statusx = e.target.value;

    this.setState (prevState => ({
      destaque: {
        ...prevState.destaque,
        status: statusx,
      },
    }));
  }

  salvar () {
    var ok = true;
    if (this.state.pictures===undefined) {
      this.state.pictures = {};
    }
    if (this.state.pictures2===undefined) {
      this.state.pictures2 = [];
    }
    console.log (this.state.pictures2);
    console.log (this.state.pictures);
    if (this.state.pictures2.length > 1) {
      ok = false;
      alert ('maximo permitido de 1 fotos');
    }

    if (ok) {
      var data = {
        id: this.state.destaque.id,
        nome: this.state.destaque.nome,
        link: this.state.destaque.link,
        status: this.state.destaque.status,
      };

      ApiService.atualizardestaque (data)
        .then (response => {
          console.log (response);
          console.log (this.state.pictures2);
          if (response.status===200) {
            if (this.state.pictures2.length > 0) {
              (async () => {
                await ApiService.uploaddestaque (
                  this.state.pictures2[0],
                  response.data.id,
                  response.data.id + '-' + 0 + '.jpg'
                );
              }) ();
            }

            this.setState ({
              submitted: true,
            });
            console.log (response.data);
          }
        })
        .catch (e => {
          this.setState ({message: 'Erro ao atualizar destaque'});
          console.log (e);
        });
    }
  }

  render () {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/destaques" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '640px'}}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Editar Destaque</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Editar Destaque
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomedestaque">Código do Destaque:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.destaque.id}
                        name="id"
                        disabled
                        style={{width: '10%'}}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="nomedestaque">Nome do Destaque:</label>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="nomedestaque"
                        placeholder="Insira o nome da nova destaque"
                        required
                        value={this.state.destaque.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{width: '400px'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="linkdestaque">Link do Destaque:</label>
                      <input
                        type="text"
                        maxLength="500"
                        className="form-control"
                        id="linkdestaque"
                        placeholder="Insira o link da nova destaque"
                        required
                        value={this.state.destaque.link}
                        onChange={this.onChangeLink}
                        name="link"
                        style={{width: '400px'}}
                      />
                    </div>

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        className="form-control select2"
                        style={{width: '12%'}}
                        value={this.state.destaque.status}
                        onChange={this.onChangeStatus}
                        name="status"
                      >
                        <option value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="foto">
                        Foto (1920 x 600):{' '}
                      </label>
                      <ImageUploader
                        withIcon={true}
                        id="foto"
                        label="Apenas Fotos .jpg"
                        buttonText="Selecione Até 1 Foto .jpg"
                        onChange={this.onDrop}
                        imgExtension={['.jpg']}
                        maxFileSize={204800}
                        fileSizeError="Tamanho Máximo Por Foto: 200kb"
                        fileTypeError="Apenas Arquivo .jpg"
                        withPreview={true}
                      />

                    </div>

                    {this.state.pictures &&
                      <div className="form-group">
                        <img
                          src={
                            'data:image/jpeg;base64,' + this.state.pictures.img
                          }
                          width="900px"
                          height="300px"
                          alt={this.state.pictures.foto}
                        />

                      </div>}

                  </div>

                  <div className="card-footer">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <button className="btn btn-primary" onClick={this.salvar}>
                        Salvar
                      </button>

                      <NavLink to={'/destaques/'} className="nav-link">
                        <button
                          className="btn btn-danger"
                          onClick={this.voltar}
                        >
                          Voltar
                        </button>
                      </NavLink>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </section>

        </div>
        <Footer />
      </div>
    );
  }
}

export default EditDestaques;
