import React, {Component} from 'react';
import Moment from 'react-moment';
import {NavLink} from 'react-router-dom';
import ApiService from '../../service/ApiService';

class ListaEmails extends Component {
  constructor (props) {
    super (props);
    this.state = {
      emails: [],
      message: null,
    };
    this.listar = this.listar.bind (this);
  }

  componentDidMount () {
    this.listar ();
  }

  listar () {
    ApiService.emails ()
      .then (response => {
        this.setState ({
          emails: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao listar emails'});
        console.log (e);
      });
  }

  render () {
    return (
      <div className="content-wrapper">

        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Emails</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Início</a></li>
                  <li className="breadcrumb-item active">Emails</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <td />
                    </div>
                  </div>

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Codigo</th>
                          <th>Data Hora</th>
                          <th>Email</th>
                          <th>Assunto</th>
                          <th>Status</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>

                        {this.state.emails &&
                          this.state.emails.map (email => (
                            <tr key={email.id}>
                              <td>{email.id}</td>
                              <td>
                               
                                  <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {email.dataaltera}
                                  </Moment>
                              
                              </td>
                              <td>{email.email}</td>
                              <td>{email.assunto}</td>
                              <td
                                style={{
                                  color: email.status === 'pendente'
                                    ? 'red'
                                    : 'black',
                                }}
                              >
                                {email.status}
                              </td>

                              <td>
                                <div className="btn-group">

                                  <NavLink
                                    to={`/ver-email/${email.id}`}
                                    className="nav-link"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                    >
                                      Ver
                                    </button>
                                  </NavLink>

                                </div>
                              </td>
                            </tr>
                          ))}

                      </tbody>

                    </table>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </section>

      </div>
    );
  }
}

export default ListaEmails;
