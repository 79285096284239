import React, {Component} from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import ApiService from '../service/ApiService';
import {withCookies} from 'react-cookie';

class Login extends Component {
  constructor (props) {
    super (props);

    this.state = {
      usuario: '',
      senha: '',
      submitted: false,
      message: '',
    };
    this.login = this.login.bind (this);
    this.onChangeUsuario = this.onChangeUsuario.bind (this);
    this.onChangeSenha = this.onChangeSenha.bind (this);
  }

  onChangeSenha (e) {
    this.setState ({
      senha: e.target.value,
    });
  }

  onChangeUsuario (e) {
    this.setState ({
      usuario: e.target.value,
    });
  }

  componentDidMount () {
    window.scrollTo (0, 0);
  }

  login () {
    var md5 = require ('md5');

    var data = {
      usuario: this.state.usuario,
      senha: md5 (this.state.senha),
    };

    ApiService.login (data)
      .then (response => {
        const {cookies} = this.props;
        cookies.set ('userd', response.data, {path: '/'});
        this.setState ({
          submitted: true,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Usuário e/ou Senha Incorretos!'});
        console.log (e);
      });
  }

  render () {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/" />;
    }

    return (
      <div className="hold-transition login-page" >
        <div className="login-box">

          <div className="card">

          {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

            <div className="card-body login-card-body">
              <p style={{fontWeight:"bold"}} className="login-box-msg">Entre Para Iniciar a Sessão</p>

              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  required
                  value={this.state.usuario}
                  onChange={this.onChangeUsuario}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Senha"
                  required
                  value={this.state.senha}
                  onChange={this.onChangeSenha}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember">
                    &nbsp;Lembrar-me
                    </label>
                  </div>
                </div>

                <div className="col-4">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    onClick={this.login}
                  >
                    Entrar
                  </button>
                </div>

              </div>

              <p className="mb-1">
                <a href="esqueceu-senha.html">Esqueci minha senha</a>
              </p>

            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(Login);
