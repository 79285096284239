import React, { Component } from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import { Redirect, NavLink } from 'react-router-dom';

class EditCategorias extends Component {
  constructor(props) {
    super(props);
    console.log('akiiiiaaa');
    this.state = {
      categoria: {
        id: null,
        nome: '',
        status: '',
        dataaltera: null,
        usuarioaltera: null,
      },
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
  }
  componentDidMount() {
    console.log(this.props.match.params.id + 'kkkkk');
    ApiService.categoria(this.props.match.params.id)
      .then(response => {
        this.setState({
          categoria: response.data,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao atualizar categoria' });
        console.log(e);
      });
    window.scrollTo(0, 0);
  }



  onChangeNome(e) {
    const nomex = e.target.value;

    this.setState(prevState => ({
      categoria: {
        ...prevState.categoria,
        nome: nomex
      }
    }));
  }

  onChangeStatus(e) {
    const statusx = e.target.value;

    this.setState(prevState => ({
      categoria: {
        ...prevState.categoria,
        status: statusx
      }
    }));
  }

  salvar() {
    var data = {
      id: this.state.categoria.id,
      nome: this.state.categoria.nome,
      status: this.state.categoria.status,
    };

    ApiService.atualizarcategoria(data)
      .then(response => {
        this.setState({
          submitted: true,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao atualizar categoria' });
        console.log(e);
      });
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/categorias" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: '640px' }}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Editar Categoria</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Editar Categoria
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                    
                  <div className="card-body">
                  <div className="form-group">
                      <label htmlFor="nomecategoria">Código da Categoria:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.categoria.id}
                        name="id"
                        disabled
                        style={{ width: '10%' }}
                      />
                    </div>
                    
                  <div className="form-group">
                      <label htmlFor="nomecategoria">Nome da Categoria:</label>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="nomecategoria"
                        placeholder="Insira o nome da nova categoria"
                        required
                        value={this.state.categoria.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{ width: '400px' }}
                      />
                    </div>                                                            

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        className="form-control select2"
                  
                        value={this.state.categoria.status}
                        onChange={this.onChangeStatus}
                        name="status"
                        style={{ width: '10%' }}
                      >
                        <option value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>
                  </div>

                  <div className="card-footer">
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <button className="btn btn-primary" onClick={this.salvar}>
                              Salvar
                            </button>
                         


                            <NavLink to={'/categorias/'} className="nav-link">
                              <button className="btn btn-danger" onClick={this.voltar}>
                                Voltar
                            </button>
                            </NavLink>
                          </div>
                        
                  </div>

                </div>

              </div>

            </div>

          </section>

        </div>
        <Footer />
      </div>
    );
  }
}

export default EditCategorias;
