import React, {Component, Image} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import {
  Polyline,
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsService,
  DirectionsRenderer,
} from '@react-google-maps/api';
import Geocode from 'react-geocode';
import Moment from 'react-moment';

const containerStyle = {
  width: '100%',
  height: '70vh',
};

const onLoad = polyline => {
  console.log ('polyline: ', polyline);
};

class Rastreio extends Component {
  constructor (props) {
    super (props);
    this.state = {
      pontos: [],
      pontosx: [],
      pedidos: [],
      loja: null,
      message: null,
      center: null,
      zoom: 11,
      ret: {},
      alterou:false,
      posicaogeral: [],
      travelMode: 'DRIVING',
    };
    this.atualizar = this.atualizar.bind (this);
    this.directionsCallback = this.directionsCallback.bind (this);
  }

  async componentDidMount () {
    ApiService.loja ()
      .then (response => {
        if (response.status == 200) {
          this.setState ({
            loja: response.data,
          });
          Geocode.setApiKey (response.data.googlemaps_apikey);

          // set response language. Defaults to english.
          Geocode.setLanguage ('pt-BR');

          // set response region. Its optional.
          // A Geocoding request with region=es (Spain) will return the Spanish city.
          Geocode.setRegion ('Brazil');
          console.log (
            response.data.logradouro +
              ', ' +
              response.data.numero +
              ' - ' +
              response.data.bairro +
              ', ' +
              response.data.cidade +
              ' - ' +
              response.data.uf
          );
          Geocode.fromAddress (
            response.data.logradouro +
              ', ' +
              response.data.numero +
              ' - ' +
              response.data.bairro +
              ', ' +
              response.data.cidade +
              ' - ' +
              response.data.uf
          ).then (
            response => {
              this.state.center = response.results[0].geometry.location;
              this.setState ({center: this.state.center});
            },
            error => {
              console.error (error);
            }
          );
        }
      })
      .catch (e => {
        this.setState ({message: 'Erro ao atualizar loja'});
        console.log (e);
      });
    this.atualizar ();
    setInterval (this.atualizar, 15000);
  }

  

  async atualizar () {
    console.log ('atualizando pedido ' + new Date ());
    ApiService.pedidosenviados ()
      .then (response => {
        this.setState ({
          pedidos: response.data,
        });
     //   console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao listar pedidos'});
        console.log (e);
      });

    ApiService.pedidosenviadosx ()
      .then (response => {
        this.setState ({
          pontosx: response.data,
        });
        this.verificarpontos ();
      })
      .catch (e => {
        this.setState ({message: 'Erro ao listar pedidos'});
        console.log (e);
      });
  }

  async verificarpontos () {
    

    for (var i = 0; i < this.state.pontosx.length; i++) {
      if (this.state.posicaogeral[i] == undefined) {
        this.state.posicaogeral[i] = '';
      }
      if (
        this.state.posicaogeral[i] !==
        this.state.pontosx[i].moto.latitude +
          '|' +
          this.state.pontosx[i].moto.longitude
      ) {
        this.state.posicaogeral[i] =
          this.state.pontosx[i].moto.latitude +
          '|' +
          this.state.pontosx[i].moto.longitude;
       this.setState({alterou:true})
      }
    }

    console.log (this.state.posicaogeral);
    console.log (this.state.alterou);

    if (this.state.alterou) {
      this.setState ({pontos: this.state.pontosx});
      for (var i = 0; i < this.state.pontos.length; i++) {
        //this.state.pontos[i].coordinates = [];
        var location = {};
        location = {
          latitude: this.state.pontos[i].moto.latitude,
          longitude: this.state.pontos[i].moto.longitude,
        };

        this.state.pontos[i].coordinates.push (location);
        this.state.pontos[i].uf = this.getRandomColor (i);

        for (var j = 0; j < this.state.pontos[i].pedidos.length; j++) {
          var lo = await this.retornalocal (
            this.state.pontos[i].pedidos[j].logradouro +
              ', ' +
              this.state.pontos[i].pedidos[j].numero +
              ' - ' +
              this.state.pontos[i].pedidos[j].bairro +
              ', ' +
              this.state.pontos[i].pedidos[j].cidade +
              ' - ' +
              this.state.pontos[i].pedidos[j].uf,
            i,
            j
          );
        }
      }

      this.setState ({
        posicaogeral: this.state.posicaogeral,
      });
      this.setState ({
        pontos: this.state.pontos,
      });
      console.log(this.state.pontos)
      this.setState({alterou:false})
    }
  }

  async retornalocal (endereco, i, j) {
  //  console.log (endereco);
    var l = await Geocode.fromAddress (endereco).then (
      response => {
        this.state.pontos[i].pedidos[j].latitude =
          response.results[0].geometry.location.lat;
        this.state.pontos[i].pedidos[j].longitude =
          response.results[0].geometry.location.lng;

        var location = {};
        location = {
          latitude: this.state.pontos[i].pedidos[j].latitude,
          longitude: this.state.pontos[i].pedidos[j].longitude,
        };
        this.state.pontos[i].coordinates.push (location);

        var rota = {
          location: {
            lat: this.state.pontos[i].pedidos[j].latitude,
            lng: this.state.pontos[i].pedidos[j].longitude,
          },
          stopover: true,
        };

        this.state.pontos[i].rota.push (rota);
      },
      error => {
        console.error (error);
      }
    );
  }

  async directionsCallback (response) {
    //console.log (response);
    if (response !== null) {
      if (response.status === 'OK') {
        // console.log (response);
        this.state.ret = {};
        this.state.ret = response;
        this.setState ({ret: response});
      } else {
        console.log ('response: ', response);
      }
    }
  }

  getRandomColor (i) {
    if (i == 0) {
      return 'red';
    } else if (i == 1) {
      return 'blue';
    } else if (i == 2) {
      return 'green';
    } else if (i == 3) {
      return 'orange';
    } else if (i == 4) {
      return 'yellow';
    } else {
      return 'black';
    }
  }

  render () {
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper">

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Rastreio</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Início</a></li>
                    <li className="breadcrumb-item active">Rastreio</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <td />
                      </div>

                    </div>

                    {this.state.message &&
                      <div className="alert alert-danger alert-dismissible">
                        {this.state.message}
                      </div>}

                    {this.state.pedidos.length == 0 &&
                      <div className="alert alert-danger alert-dismissible">
                        Nenhum pedido em rota de entrega
                      </div>}

                    <div style={{height: '70vh', width: '100%'}}>
                      {this.state.loja != null &&
                        this.state.pedidos.length > 0 &&
                        <LoadScript
                          googleMapsApiKey={this.state.loja.googlemaps_apikey}
                        >

                          <GoogleMap
                            ref={c => (this.mapView = c)}
                            mapContainerStyle={containerStyle}
                            center={this.state.center}
                            zoom={15}
                          >
                            <Marker
                              position={this.state.center}
                              title={this.state.loja.nome}
                            />

                            {this.state.pontos &&
                              this.state.pontos.map ((ponto, index) => (
                                <Marker
                                  position={{
                                    lat: ponto.moto.latitude,
                                    lng: ponto.moto.longitude,
                                  }}
                                  title={ponto.moto.nome}
                                  icon={'./dist/img/motorcycling.png'}
                                />
                              ))}

                            {this.state.pontos &&
                              this.state.pontos.map (
                                (ponto, index) =>
                                  ponto.pedidos &&
                                  ponto.pedidos.map ((pe, index) => (
                                    <Marker
                                      position={{
                                        lat: pe.latitude,
                                        lng: pe.longitude,
                                      }}
                                      strokeColor={'green'}
                                      title={pe.numeropedido + '-' + pe.nome}
                                      icon={'./dist/img/green-dot.png'}
                                    />
                                  ))
                              )}

                            {this.state.pontos &&
                              this.state.pontos.map (
                                (ponto, index) =>
                                  ponto.coordinates.length > 1 &&
                                  <div>
                                    <DirectionsService
                                      // required
                                      options={{
                                        destination: {
                                          lat: ponto.coordinates[
                                            ponto.coordinates.length - 1
                                          ].latitude,
                                          lng: ponto.coordinates[
                                            ponto.coordinates.length - 1
                                          ].longitude,
                                        },
                                        origin: {
                                          lat: ponto.coordinates[0].latitude,
                                          lng: ponto.coordinates[0].longitude,
                                        },
                                        waypoints: ponto.rota,
                                        optimizeWaypoints: true,
                                        travelMode: this.state.travelMode,
                                      }}
                                      // required
                                      callback={this.directionsCallback}
                                      // optional
                                      onLoad={directionsService => {
                                        console.log (
                                          'DirectionsService onLoad directionsService: ',
                                          directionsService
                                        );
                                      }}
                                      // optional
                                      onUnmount={directionsService => {
                                        console.log (
                                          'DirectionsService onUnmount directionsService: ',
                                          directionsService
                                        );
                                      }}
                                    />

                                    {this.state.ret !== null &&
                                      <DirectionsRenderer
                                        // required
                                        options={{
                                          polylineOptions: {
                                            strokeColor: ponto.uf,
                                          },
                                          directions: this.state.ret,
                                        }}
                                        // optional
                                        onLoad={directionsRenderer => {
                                          console.log (
                                            'DirectionsRenderer onLoad directionsRenderer: ',
                                            directionsRenderer
                                          );
                                        }}
                                        // optional
                                        onUnmount={directionsRenderer => {
                                          console.log (
                                            'DirectionsRenderer onUnmount directionsRenderer: ',
                                            directionsRenderer
                                          );
                                        }}
                                      />}
                                  </div>
                              )}

                          </GoogleMap>
                        </LoadScript>}
                    </div>

                    {this.state.pedidos.length > 0 &&
                      <div className="col-lg-12">

                        <div className="card">
                          <div className="card-header border-transparent">
                            <h3
                              className="card-title"
                              style={{fontWeight: '600'}}
                            >
                              Pedidos
                            </h3>

                            <div className="card-tools" />
                          </div>

                          <div className="card-body p-0">
                            <div className="table-responsive">
                              <table className="table m-0">
                                <thead>
                                  <tr>
                                    <th>Nº Pedido</th>
                                    <th>Data Envio</th>
                                    <th>Total</th>
                                    <th>Endereco</th>
                                    <th>MotoBoy</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.pedidos &&
                                    this.state.pedidos.map (pedido => (
                                      <tr key={pedido.id}>
                                        <td>
                                          {' '}{' '}

                                          {pedido.numeropedido}

                                        </td>
                                        <td>
                                          <Moment format="DD/MM/YYYY HH:mm:ss">
                                            {pedido.dataenvio}
                                          </Moment>
                                        </td>

                                        <td>
                                          <div
                                            className="sparkbar"
                                            data-color="#00a65a"
                                            data-height="20"
                                          >
                                            R$
                                            {Number (pedido.total).toFixed (2)}
                                          </div>
                                        </td>
                                        <td>
                                          {pedido.logradouro}
                                          ,
                                          {' '}
                                          {pedido.numero}
                                          {' '}
                                          -
                                          {' '}
                                          {pedido.bairro}
                                        </td>
                                        <td>
                                          {pedido.motoboy}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>

                          </div>

                        </div>
                      </div>}
                  </div>

                </div>

              </div>
            </div>
          </section>

        </div>
        <Footer />
      </div>
    );
  }
}

export default Rastreio;
