import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ApiService from '../../service/ApiService';

class ListaProdutos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      produtos: [],
      message: null,
      campo: 'nome',
      valor: '',
      loja: {},
      atualizando: false
    };
    this.listar = this.listar.bind(this);
    //
    this.pesquisar = this.pesquisar.bind(this);
    this.importar = this.importar.bind(this);
    this.onChangeCampo = this.onChangeCampo.bind(this);
    this.onChangeValor = this.onChangeValor.bind(this);
  }

  componentDidMount() {
    this.listar();
  }

  onChangeCampo(e) {
    this.setState({
      campo: e.target.value,
    });
  }

  onChangeValor(e) {
    this.setState({
      valor: e.target.value,
    });
  }

  pesquisar() {
    this.setState({ message: '' });
    if (this.state.valor.length > 0) {
      var data = {
        campo: this.state.campo,
        valor: this.state.valor,
      };
      console.log(data);
      ApiService.pprodutos(data)
        .then(response => {
          this.setState({
            produtos: response.data,
          });
          console.log(response.data);
        })
        .catch(e => {
          this.setState({ message: 'Erro ao pesquisar produtos' });
          console.log(e);
        });
    } else {
      this.setState({ message: 'digite valor a ser pesquisado' });
    }
  }
  listar() {
    ApiService.produtos()
      .then(response => {
        this.setState({
          produtos: response.data,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao listar produtos' });
        console.log(e);
      });
  }

  importar() {
    this.setState({ atualizando: true });
    ApiService.importar()
      .then(response => {
        this.setState({ message: response.data });
        console.log(response.data);
        this.setState({ atualizando: false });
      })
      .catch(e => {
        var trace = '';
        if (e.response) {
          if (e.response.data) {
            if (e.response.data.trace) {
              trace = e.response.data.trace;
              console.log(e.response.data.trace);
            }
          }
        }

        this.setState({ atualizando: false });
        this.setState({ message: 'Erro ao listar produtos:' });
        console.log(e);
      });

  }


  render() {
    return (
      <div className="content-wrapper">

        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Produtos</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Início</a></li>
                  <li className="breadcrumb-item active">Produtos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">

              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <td>
                        <NavLink to="/adicionar-produto" className="nav-link">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                          >
                            Inserir Novo Produto
                          </button>
                        </NavLink>
                      </td>
                      <td>

                        <button
                          disabled={this.state.atualizando}
                          type="button"
                          onClick={this.importar}
                          className="btn btn-block btn-danger"
                        >
                          Importar Produtos ERP{this.state.atualizando ? '...aguarde' : ''}
                        </button>

                      </td>
                    </div>

                    <div className="row">
                      <div className="col-lg-3">

                        <div className="form-group">
                          <label>Campo:</label>
                          <select
                            className="form-control select2"
                            value={this.state.campo}
                            onChange={this.onChangeCampo}
                            name="status"
                          >
                            <option defaultValue value="id">Codigo</option>
                            <option value="ean">EAN</option>
                            <option value="nome">Nome</option>
                            <option value="preco">Preco</option>
                            <option value="status">Status</option>
                            <option value="tipo">Tipo</option>
                          </select>
                        </div>

                      </div>
                      <div className="col-lg-6">

                        <div className="form-group">
                          <label>Valor:</label>
                          <input
                            type="text"
                            maxLength="50"
                            className="form-control"
                            id="nomecategoria"
                            placeholder="Valor a ser pesquisado"
                            required
                            value={this.state.valor}
                            onChange={this.onChangeValor}
                            name="nome"
                          />
                        </div>

                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label style={{ visibility: 'hidden' }}>Valor:</label>
                          <button
                            onClick={this.pesquisar}
                            className="btn btn-block btn-primary"
                          >
                            Pesquisar
                          </button>
                        </div>
                      </div>

                    </div>

                  </div>


                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Codigo</th>
                          <th>Nome</th>
                          <th>Subcategoria</th>
                          <th>Categoria</th>
                          <th>Tipo</th>
                          <th>Preco</th>
                          <th>Status</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>

                        {this.state.produtos &&
                          this.state.produtos.map(produto => (
                            <tr key={produto.id}>
                              <td>{produto.id}</td>
                              <td>{produto.nome}</td>
                              <td>{produto.subcategoria.nome}</td>
                              <td>{produto.subcategoria.categoria.nome}</td>
                              <td>{produto.tipo == 'Kit' ? 'Kit' : 'Normal'}</td>
                              <td>{Number(produto.preco).toFixed(2)}</td>
                              <td
                                style={{
                                  color: produto.status !== 'Ativo'
                                    ? 'red'
                                    : 'green',
                                }}
                              >
                                {produto.status}
                              </td>

                              <td>
                                <div className="btn-group">
                                  <NavLink
                                    to={`/editar-produto/${produto.id}`}
                                    className="nav-link"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-info"
                                    >
                                      Editar
                                    </button>
                                  </NavLink>
                                  <NavLink
                                    to={`/ver-produto/${produto.id}`}
                                    className="nav-link"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                    >
                                      Ver
                                    </button>
                                  </NavLink>

                                </div>
                              </td>
                            </tr>
                          ))}

                      </tbody>

                    </table>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </section>

      </div>
    );
  }
}

export default ListaProdutos;
