import React, { Component } from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import { Redirect, NavLink } from 'react-router-dom';
import EmailValidator from 'email-validator';

class EditUsuarios extends Component {
  constructor(props) {
    super(props);
    console.log('akiiiiaaa');
    this.state = {
      usuario: {
        id: null,
        nome: '',
        usuario: '',
        senha: '',
        funcao: 'Supervisor',
        status: '',
        dataaltera: null,
        usuarioaltera: null,
      },
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeUsuario = this.onChangeUsuario.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
  }
  componentDidMount() {
    console.log(this.props.match.params.id + 'kkkkk');
    ApiService.usuario(this.props.match.params.id)
      .then(response => {
        this.setState({
          usuario: response.data,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao atualizar usuario' });
        console.log(e);
      });
    window.scrollTo(0, 0);
  }



  onChangeNome(e) {
    const nomex = e.target.value;

    this.setState(prevState => ({
      usuario: {
        ...prevState.usuario,
        nome: nomex
      }
    }));
  }

  onChangeUsuario(e) {
    const nomex = e.target.value;

    this.setState(prevState => ({
      usuario: {
        ...prevState.usuario,
        usuario: nomex
      }
    }));
  }

  onChangeStatus(e) {
    const statusx = e.target.value;

    this.setState(prevState => ({
      usuario: {
        ...prevState.usuario,
        status: statusx
      }
    }));
  }

  salvar() {

    var ok = true;


    if (ok) {

      var data = {
        id: this.state.usuario.id,
        nome: this.state.usuario.nome,
        usuario: this.state.usuario.usuario,
        funcao: this.state.usuario.funcao,
        status: this.state.usuario.status,
        senha: this.state.usuario.senha,
      };

      ApiService.atualizarusuario(data)
        .then(response => {
          this.setState({
            submitted: true,
          });
          console.log(response.data);
        })
        .catch(e => {
          this.setState({ message: 'Erro ao atualizar usuario' });
          console.log(e);
        });
    }
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/usuarios" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: '640px' }}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Editar Usuario</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Editar Usuario
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomeusuario">Código do Usuário:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.usuario.id}
                        name="id"
                        disabled
                        style={{ width: '10%' }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="nomeusuario">Nome:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="nomeusuario"
                        placeholder="Insira o nome"
                        required
                        value={this.state.usuario.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{ width: '250px' }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="usuario">Email:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="usuario"
                        disabled={true}
                        placeholder="Insira o email"
                        required
                        value={this.state.usuario.usuario}
                        onChange={this.onChangeNome}
                        name="usuario"
                        style={{ width: '250px' }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        className="form-control select2"
                        style={{ width: '10%' }}
                        value={this.state.usuario.status}
                        onChange={this.onChangeStatus}
                        name="status"
                      >
                        <option value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>
                  </div>

                  <div className="card-footer">
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                  
                          <button className="btn btn-primary" onClick={this.salvar}>
                            Salvar
                            </button>
                        
                  
                          <NavLink to={'/usuarios/'} className="nav-link">
                            <button className="btn btn-danger" onClick={this.voltar}>
                              Voltar
                              </button>
                          </NavLink>                        
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default EditUsuarios;
