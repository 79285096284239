import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withCookies } from "react-cookie";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: "",
    };
  }

  componentDidMount() {
    var user = this.props.cookies.get("userd") || "";
    this.setState({ usuario: user.usuario });
    console.log(window.location);
  }

  render() {
    return (
      <aside className="main-sidebar sidebar-dark-red elevation-4">
        <NavLink to="/" className="brand-link">
          <img
            src=""
            className="brand-image img-circle elevation-3"
            style={{ opacity: 0.8 }}
          />
          {/* <span className="brand-text font-weight-dark">Farmácia Teste </span> */}
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              fontWeight: "700",
              fontSize: "22px",
            }}
          >
            Top Systems{" "}
          </span>
        </NavLink>

        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="info">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <a
                          href="/"
                          style={{
                            color: "#fff",
                            fontWeight: "700",
                            fontSize: "17px",
                          }}
                        >
                          {this.state.usuario}
                        </a>
                        &nbsp;{" "}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NavLink
                  to="/sair"
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#0c1524",
                  }}
                  className="btn btn-sm btn-info float-left"
                >
                  Sair
                </NavLink>
              </div>
            </div>
          </div>

          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column "
              data-widget="treeview"
              role="menu"
              data-expandsidebar="true"
              data-animationspeed="500"
            >
              <li
                className="nav-header"
                style={{ color: "#fff", fontWeight: "500" }}
              >
                MENUS
              </li>

              <li
                className="nav-item has-treeview"
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <NavLink to="/home" className="nav-link">
                  <i className="nav-icon fas fa fa-home" />
                  <p>
                    Início <i className="fas fa-angle-right right" />
                  </p>
                </NavLink>
              </li>

              <li
                className={
                  "nav-item has-treeview" +
                  (window.location.pathname === "/categorias" ||
                  window.location.pathname === "/subcategorias" ||
                  window.location.pathname === "/produtos" ||
                  window.location.pathname === "/destaques"
                    ? " menu-open"
                    : "")
                }
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <a href="/" className="nav-link">
                  <i className="nav-icon fas fa fa-book" />
                  <p>
                    Catálogo
                    <i className="fas fa-angle-right right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item ">
                    <NavLink to="/categorias" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Categorias</p>
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink to="/subcategorias" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>SubCategorias</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/produtos" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Produtos</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/destaques" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Destaques</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li
                className="nav-item has-treeview"
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <NavLink to="/clientes" className="nav-link">
                  <i className="nav-icon far fa fa-user-circle" />
                  <p>
                    Clientes
                    <i className="fas fa-angle-right right" />
                  </p>
                </NavLink>
              </li>

              <li
                className="nav-item has-treeview"
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <NavLink to="/pedidos" className="nav-link">
                  <i className="nav-icon far fa fa-shopping-cart" />
                  <p>
                    Pedidos
                    <i className="fas fa-angle-right right" />
                  </p>
                </NavLink>
              </li>

              <li
                className={
                  "nav-item has-treeview" +
                  (window.location.pathname === "/motoboys" ||
                  window.location.pathname === "/fretes" ||
                  window.location.pathname === "/rastreio" ||
                  window.location.pathname === "/teste-mapa"
                    ? " menu-open"
                    : "")
                }
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <a href="/" className="nav-link">
                  <i className="nav-icon far fa fa-motorcycle" />
                  <p>
                    Entregas
                    <i className="fas fa-angle-right right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/fretes" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Fretes</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/motoboys" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>MotoBoys</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/rastreio" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Rastreio Entregas</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li
                className={
                  "nav-item has-treeview" +
                  (window.location.pathname === "/descontos" ||
                  window.location.pathname === "/pontos" ||
                  window.location.pathname === "/cashbacks"
                    ? " menu-open"
                    : "")
                }
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <a href="" className="nav-link">
                  <i className="nav-icon far fa fa-credit-card" />
                  <p>
                    Descontos
                    <i className="fas fa-angle-right right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/descontos" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Descontos</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/pontos" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Pontos</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/cashbacks" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>CashBacks</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li
                className={
                  "nav-item has-treeview" +
                  (window.location.pathname === "/informacoes-loja" ||
                  window.location.pathname === "/cores-loja" ||
                  window.location.pathname === "/logos-loja" ||
                  window.location.pathname === "/configuracao-esitef" ||
                  window.location.pathname === "/configuracao-cielo" ||
                  window.location.pathname === "/configuracao-correios" ||
                  window.location.pathname === "/configuracao-bin" ||
                  window.location.pathname === "/configuracao-rede" ||
                  window.location.pathname === "/configuracao-getnet" ||
                  window.location.pathname === "/configuracao-email" ||
                  window.location.pathname === "/configuracao-nfe" ||
                  window.location.pathname === "/configuracao-tawkto" ||
                  window.location.pathname === "/configuracao-googlemaps" ||
                  window.location.pathname === "/configuracao-redes"
                    ? " menu-open"
                    : "")
                }
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <a href="/" className="nav-link">
                  <i className="nav-icon far fa fa-university" />
                  <p>
                    Loja
                    <i className="fas fa-angle-right right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/informacoes-loja" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Informações da Loja</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/configuracao-bin" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Config. Bin</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/configuracao-cielo" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Config. Cielo</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/configuracao-correios" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Config. Correios</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/configuracao-esitef" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Config. ESitef</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/configuracao-email" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Config. Email</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/configuracao-getnet" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Config. GetNet</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/configuracao-googlemaps" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Config. Google Maps</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/configuracao-nfe" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Config. NFe</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/configuracao-rede" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Config. Rede</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/configuracao-tawkto" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Config. Tawk.to</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <a
                      href="https://dashboard.tawk.to/"
                      target="_blank"
                      className="nav-link"
                    >
                      <i className="far fa fa-cir nav-icon" />
                      <p>Dashboard Tawk.to</p>
                    </a>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/cores-loja" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Cores da Loja</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/logos-loja" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Logos da Loja</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/configuracao-redes" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Redes Sociais</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li
                className={
                  "nav-item has-treeview" +
                  (window.location.pathname === "/vendas-periodo" ||
                  window.location.pathname === "/vendas-cliente" ||
                  window.location.pathname === "/vendas-pagamento"
                    ? " menu-open"
                    : "")
                }
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <a href="/" className="nav-link">
                  <i className="nav-icon far fa fa-file" />
                  <p>
                    Relatórios
                    <i className="fas fa-angle-right right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/vendas-periodo" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Vendas Por Período</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/vendas-cliente" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Vendas Por Cliente</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/vendas-pagamento" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Tipos de Pagamento</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li
                className={
                  "nav-item has-treeview" +
                  (window.location.pathname === "/newsletters"
                    ? " menu-open"
                    : "")
                }
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <a href="/" className="nav-link">
                  <i className="nav-icon far fa fa-laptop" />
                  <p>
                    Newsletter
                    <i className="fas fa-angle-right right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/newsletters" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Emails Cadastrados</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li
                className={
                  "nav-item has-treeview" +
                  (window.location.pathname === "/usuarios" ? " menu-open" : "")
                }
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <a href="/" className="nav-link">
                  <i className="nav-icon far fa fa-user" />
                  <p>
                    Usuários
                    <i className="fas fa-angle-right right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/usuarios" className="nav-link">
                      <i className="far fa fa-cir nav-icon" />
                      <p>Usuários</p>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li
                className="nav-item has-treeview"
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <NavLink to="/emails" className="nav-link">
                  <i className="nav-icon far fa fa-laptop" />
                  <p>
                    Emails
                    <i className="fas fa-angle-right right" />
                  </p>
                </NavLink>
              </li>

              <li
                className="nav-item has-treeview"
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <NavLink to="/contatos" className="nav-link">
                  <i className="nav-icon far fa fa-laptop" />
                  <p>
                    Contatos
                    <i className="fas fa-angle-right right" />
                  </p>
                </NavLink>
              </li>

              <li
                className="nav-item has-treeview"
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <NavLink to="/eventos" className="nav-link">
                  <i className="nav-icon far fa fa-info" />
                  <p>
                    Eventos
                    <i className="fas fa-angle-right right" />
                  </p>
                </NavLink>
              </li>

              <li
                className="nav-item has-treeview"
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <NavLink to="/transacoes" className="nav-link">
                  <i className="nav-icon far fa fa-history" />
                  <p>
                    Transações Esitef
                    <i className="fas fa-angle-right right" />
                  </p>
                </NavLink>
              </li>

              <li
                className="nav-item has-treeview"
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <NavLink to="/transacoes-bin" className="nav-link">
                  <i className="nav-icon far fa fa-history" />
                  <p>
                    Transações Bin
                    <i className="fas fa-angle-right right" />
                  </p>
                </NavLink>
              </li>
              <li
                className="nav-item has-treeview"
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <NavLink to="/transacoes-cielo" className="nav-link">
                  <i className="nav-icon far fa fa-history" />
                  <p>
                    Transações Cielo
                    <i className="fas fa-angle-right right" />
                  </p>
                </NavLink>
              </li>
              <li
                className="nav-item has-treeview"
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <NavLink to="/transacoes-getnet" className="nav-link">
                  <i className="nav-icon far fa fa-history" />
                  <p>
                    Transações GetNet
                    <i className="fas fa-angle-right right" />
                  </p>
                </NavLink>
              </li>
              <li
                className="nav-item has-treeview"
                style={{ color: "#fff", fontWeight: "600" }}
              >
                <NavLink to="/transacoes-rede" className="nav-link">
                  <i className="nav-icon far fa fa-history" />
                  <p>
                    Transações Rede
                    <i className="fas fa-angle-right right" />
                  </p>
                </NavLink>
              </li>
            </ul>
            <br />
            <br />
          </nav>
        </div>
      </aside>
    );
  }
}

export default withCookies(Sidebar);
