import React, { Component } from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import { Redirect, NavLink } from 'react-router-dom';

class VerDescontos extends Component {
  constructor(props) {
    super(props);
    console.log('akiiiiaaa');
    this.state = {
      desconto: {
        itens: [],
        id: null,
        nome: '',
        status: '',
        percentualreal: 'Percentual',
        valor: 0,
        dataexpiracao: new Date(),
        itens: [],
        dataaltera: null,
        usuarioaltera: null,
      },
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
  }
  componentDidMount() {
    console.log(this.props.match.params.id + 'kkkkk');
    ApiService.desconto(this.props.match.params.id)
      .then(response => {
        this.setState({
          desconto: response.data,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao atualizar desconto' });
        console.log(e);
      });

    window.scrollTo(0, 0);
  }

  onChangeNome(e) {
    const nomex = e.target.value;

    this.setState(prevState => ({
      desconto: {
        ...prevState.desconto,
        nome: nomex,
      },
    }));
  }

  onChangeStatus(e) {
    const statusx = e.target.value;

    this.setState(prevState => ({
      desconto: {
        ...prevState.desconto,
        status: statusx,
      },
    }));
  }

  salvar() {
    var data = {
      id: this.state.desconto.id,
      nome: this.state.desconto.nome,
      status: this.state.desconto.status,
    };

    ApiService.atualizardesconto(data)
      .then(response => {
        this.setState({
          submitted: true,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao atualizar desconto' });
        console.log(e);
      });
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/descontos" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: '640px' }}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Ver Desconto</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Ver Desconto
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                  <div style={{display: 'flex',  justifyContent:'flex-start', alignItems:'center'}}>
                    <div className="form-group">
                      <label htmlFor="nomedesconto">Código do Desconto:</label>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={this.state.desconto.id}
                        name="id"
                        disabled
                        style={{width: '180px'}}
                      />
                    </div>

                    &nbsp;&nbsp;  

                    <div className="form-group">
                      <label htmlFor="nomedesconto">Nome da Desconto:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="nomedesconto"
                        placeholder="Insira o nome da nova desconto"
                        required
                        value={this.state.desconto.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{ width: "460px" }}
                      />
                    </div>

                    &nbsp;&nbsp;

                    <div className="form-group">
                      <label htmlFor="dataexpiracao">Data Expiração:</label>
                      <input
                        type="date"
                        className="form-control"
                        id="dataexpiracao"
                        placeholder="Insira a data expiracao"
                        disabled={true}
                        value={this.state.desconto.dataexpiracao}
                        onChange={this.onChangeDataexpiracao}
                        name="dataexpiracao"
                        style={{width: '180px'}}
                      />
                    </div>

                    &nbsp;&nbsp;

                    <div className="form-group">
                      <label>Tipo de Desconto:</label>
                      <select
                        disabled={true}
                        className="form-control select2"
                        style={{width: '180px'}}
                        value={this.state.desconto.percentualreal}
                        onChange={this.onChangePercentual}
                        name="percentual"
                      >
                        <option value="Percentual">
                          Percentual
                        </option>
                        <option value="Real">Reais</option>
                      </select>
                    </div>
                  </div>

                  <div style={{display: 'flex',  justifyContent:'flex-start', alignItems:'center'}}>
                    <div className="form-group">
                      <label htmlFor="valor">
                        Valor:
                      </label>
                      <input
                        type="number"
                        min="0.00"
                        max={
                          this.state.desconto.percentualreal==='Percentual'
                            ? '99.99'
                            : '10000.00'
                        }
                        step="0.01"
                        className="form-control"
                        id="Valor"
                        disabled={true}
                        placeholder="Insira o Valor "
                        value={this.state.desconto.valor}
                        onChange={this.onChangeValor}
                        name="Valor"
                        style={{width: '180px'}}
                      />
                    </div>

                    &nbsp;&nbsp;   

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        disabled={true}
                        className="form-control select2"
                        style={{width: '180px'}}
                        value={this.state.desconto.status}
                        onChange={this.onChangeStatus}
                        name="status"
                      >
                        <option value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-body">

                  <table
                    id="example1"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>Tipo</th>
                        <th>Codigo</th>
                        <th>Nome</th>

                      </tr>
                    </thead>
                    <tbody>
                      {this.state.desconto.itens &&
                        this.state.desconto.itens.map(item => (
                          <tr
                            key={
                              item.categoria!==null
                                ? item.categoria.id
                                : item.produto!==null?item.produto.id:item.subcategoria.id
                            }
                          >
                            <td>{item.tipo}</td>
                            <td>
                              {item.categoria!==null
                                ? item.categoria.id
                                : item.produto!==null?item.produto.id:item.subcategoria.id}
                            </td>
                            <td>
                              {item.categoria!==null
                                ? item.categoria.nome
                                : item.produto!==null?item.produto.nome:item.subcategoria.nome}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                <div className="card-footer">
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <NavLink
                      to={`/editar-desconto/${this.state.desconto.id}`}
                      className="nav-link"
                    >
                      <button type="button" className="btn btn-info">
                        Editar
                </button>
                    </NavLink>

                    <NavLink to={'/descontos/'} className="nav-link">
                      <button className="btn btn-danger" onClick={this.voltar}>
                        Voltar
                  </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default VerDescontos;
