import React, {Component} from 'react';
import Moment from 'react-moment';
import {NavLink} from 'react-router-dom';
import ApiService from '../../service/ApiService';

class ListaContatos extends Component {
  constructor (props) {
    super (props);
    this.state = {
      emails: [],
      message: null,
    };
    this.listar = this.listar.bind (this);
  }

  componentDidMount () {
    this.listar ();
  }

  listar () {
    ApiService.contatos ()
      .then (response => {
        this.setState ({
          emails: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao listar contatos'});
        console.log (e);
      });
  }

  render () {
    return (
      <div className="content-wrapper">

        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Contatos</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Início</a></li>
                  <li className="breadcrumb-item active">Contatos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <td>
                        <NavLink to="/adicionar-contato" className="nav-link">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                          >
                            Inserir Novo Contato
                          </button>
                        </NavLink>
                      </td>
                    </div>

                    <div className="row">
                      <div className="col-lg-3">

                        <div className="form-group">
                          <label>Campo:</label>
                          <select
                            className="form-control select2"
                            // value={this.state.campo}
                            // onChange={this.onChangeCampo}
                            name="status"
                          >
                            <option defaultValue value="id">Codigo</option>
                            <option value="nome">Nome</option>
                          </select>
                        </div>

                      </div>
                      <div className="col-lg-6">

                        <div className="form-group">
                          <label>Valor:</label>
                          <input
                            type="text"
                            maxLength="50"
                            className="form-control"
                            id="nomecategoria"
                            placeholder="Valor a ser pesquisado"
                            required
                            // value={this.state.valor}
                            // onChange={this.onChangeValor}
                            name="nome"
                          />
                        </div>

                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label style={{visibility: 'hidden'}}>Valor:</label>
                          <button
                            // onClick={this.pesquisar}
                            className="btn btn-block btn-primary"
                          >
                            Pesquisar
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Codigo</th>
                          <th>Nome</th>
                          <th>Data e Hora</th>
                          <th>Email</th>                          
                        
                          <th />
                        </tr>
                      </thead>
                      <tbody>

                        {this.state.emails &&
                          this.state.emails.map (email => (
                            <tr key={email.id}>
                              <td>{email.id}</td>
                              <td>{email.nome}</td>
                              <td>
                               
                                  <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {email.dataaltera}
                                  </Moment>
                              
                              </td>
                              <td>{email.email}</td>                              
                          

                              <td>
                                <div className="btn-group">
                               
                                  <NavLink
                                    to={`/ver-contato/${email.id}`}
                                    className="nav-link"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                    >
                                      Ver
                                    </button>
                                  </NavLink>

                                </div>
                              </td>
                            </tr>
                          ))}

                      </tbody>

                    </table>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </section>

      </div>
    );
  }
}

export default ListaContatos;
