import React, { Component } from "react";
import Header from "../../componentes/Header";
import Sidebar from "../../componentes/Sidebar";
import Footer from "../../componentes/Footer";
import ApiService from "../../service/ApiService";
import { Redirect, NavLink } from "react-router-dom";
import ImageUploader from "react-images-upload";

const checkboxes = [
  {
    name: "branco",
    key: "branco",
    label: "Branco",
    cor: "#EEF2F8",
  },
  {
    name: "perola",
    key: "perola",
    label: "Pérola",
    cor: "#E5D2B3",
  },
  {
    name: "taupe",
    key: "taupe",
    label: "Taupe",
    cor: "#D2B179",
  },
  {
    name: "caramelo",
    key: "caramelo",
    label: "Caramelo",
    cor: "#FFB74D",
  },

  {
    name: "whisky",
    key: "whisky",
    label: "Whisky",
    cor: "#8b4513",
  },
  {
    name: "cafe-fosco",
    key: "cafe-fosco",
    label: "Cafe fosco",
    cor: "#4d2719",
  },
  {
    name: "cafe-brilho",
    key: "cafe-brilho",
    label: "Cafe brilho",
    cor: "#86442d",
  },
  {
    name: "chumbo",
    key: "chumbo",
    label: "Chumbo",
    cor: "#607d8b",
  },
  {
    name: "preto-brilho",
    key: "preto-brilho",
    label: "Preto brilho",
    cor: "#4d4d4d",
  },
  {
    name: "preto-fosco",
    key: "preto-fosco",
    label: "Preto fosco",
    cor: "#333333",
  },
];

class EditProdutos extends Component {
  constructor(props) {
    super(props);
    console.log("akiiiiaaa");
    this.state = {
      produtos: [],
      segmento: "0",
      cores: new Map(),
      produtoi: "",
      nome: "",
      produto: {
        id: "",
        nome: "",
        status: "",
        subcategoria: null,
        laboratorio: "",
        dosagem: "",
        introducao: "",
        Caracteristica: "",
        preco: 0.0,
        desconto: 0.0,
        promocao: 0.0,
        quantidade: "",
        dataaltera: null,
        usuarioaltera: null,
        pictures: [],
        pictures2: [],
        imagef: null,
        ean: "",
        produtofk: null,
        tipo: "",
        produtosi: [],
        prazoentrega: "",
      },
      produtosx: [],
      semcb: true,
      subcategorias: [],
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind(this);
    this.onChangePrazoentrega = this.onChangePrazoentrega.bind(this);
    this.onChangeEan = this.onChangeEan.bind(this);
    this.onChangecores = this.onChangecores.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeCategoria = this.onChangeCategoria.bind(this);
    this.onChangeLaboratorio = this.onChangeLaboratorio.bind(this);
    this.onChangeDosagem = this.onChangeDosagem.bind(this);
    this.onChangeIntroducao = this.onChangeIntroducao.bind(this);
    this.onChangeCaracteristica = this.onChangeCaracteristica.bind(this);
    this.onChangePreco = this.onChangePreco.bind(this);
    this.onChangeDesconto = this.onChangeDesconto.bind(this);
    this.onChangePromocao = this.onChangePromocao.bind(this);
    this.onChangeQuantidade = this.onChangeQuantidade.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.padrao = this.padrao.bind(this);
    this.excluir = this.excluir.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onChangeProdutoi = this.onChangeProdutoi.bind(this);
    this.onChangePrecoi = this.onChangePrecoi.bind(this);
    this.adicionar = this.adicionar.bind(this);
    this.excluirx = this.excluirx.bind(this);
    this.onChangevalormontagem = this.onChangevalormontagem.bind(this);
  }

  excluirx(produto) {
    this.state.produto.produtosi = this.state.produto.produtosi.filter(
      (it) => it.produto_fk !== produto.produto_fk
    );
    console.log(this.state.produto.produtosi);
    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        produtosi: this.state.produto.produtosi,
      },
    }));

    this.state.produtosx.push(produto);
    this.setState({
      produtosx: this.state.produtosx,
    });

    var total = this.state.produto.produtosi.reduce(
      (prev, next) => prev + Number(next.preco),
      0
    );

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        preco: Number(total).toFixed(2),
      },
    }));
  }

  adicionar() {
    var ok = true;

    if (this.state.precoi <= 0) {
      ok = false;
      alert("preco invalido");
    }
    if (this.state.produtoi === "") {
      ok = false;
      alert("produto invalido");
    }

    if (ok) {
      var h = this.state.produtoi.split("##");
      var p = {};
      p.produto_fk = h[0];
      p.produto_ref = this.state.produto.id;
      p.nome = h[1];
      p.preco = this.state.precoi;
      if (ok) {
        this.state.produto.produtosi.push(p);
        var total = this.state.produto.produtosi.reduce(
          (prev, next) => prev + Number(next.preco),
          0
        );

        this.setState((prevState) => ({
          produto: {
            ...prevState.produto,
            preco: Number(total).toFixed(2),
          },
        }));
        this.setState({ precoi: 0 });
        this.setState({ produtoi: "" });
      }
    }
  }

  onChangecores(e) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      cores: prevState.cores.set(item, isChecked),
    }));
  }

  onChangePrecoi(e) {
    this.setState({
      precoi: e.target.value,
    });
  }

  onChangeProdutoi(e) {
    this.setState({
      produtoi: e.target.value,
    });
  }

  async onDrop(pictureFiles, pictureDataURLs) {
    await this.setState({
      pictures2: pictureFiles,
    });
    console.log(this.state.pictures2);
  }

  componentDidMount() {
    ApiService.produto(this.props.match.params.id)
      .then((response) => {
        this.setState({
          produto: response.data,
        });
        if (this.state.produto.cores != undefined) {
          var fg = this.state.produto.cores.split(",");
          for (let i = 0; i < fg.length; i++) {
            if (fg[i].length > 0) {
              this.state.cores.set(fg[i], true);
            }
          }
        }
        console.log(this.state.cores);
        this.setState({
          cores: this.state.cores,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao atualizar produto" });
        console.log(e);
      });

    ApiService.produtos()
      .then((response) => {
        this.setState({
          produtos: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao listar produtos" });
        console.log(e);
      });

    ApiService.fotosproduto(this.props.match.params.id)
      .then((response) => {
        this.setState({
          pictures: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao listar fotos do produto" });
        console.log(e);
      });

    ApiService.subcategorias()
      .then((response) => {
        this.setState({
          subcategorias: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao listar subcategorias" });
        console.log(e);
      });

    ApiService.segmento()
      .then((response) => {
        this.setState({
          segmento: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao consultar segmento" });
        console.log(e);
      });

    window.scrollTo(0, 0);
  }

  onChangeQuantidade(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        quantidade: nomex,
      },
    }));
  }

  onChangeDesconto(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        desconto: nomex,
      },
    }));
  }

  onChangePromocao(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        promocao: nomex,
      },
    }));
  }

  onChangePreco(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        preco: nomex,
      },
    }));
  }

  onChangeLaboratorio(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        laboratorio: nomex,
      },
    }));
  }

  onChangeDosagem(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        dosagem: nomex,
      },
    }));
  }

  onChangeIntroducao(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        introducao: nomex,
      },
    }));
  }

  onChangevalormontagem(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        valormontagem: nomex,
      },
    }));
  }

  onChangeCaracteristica(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        caracteristica: nomex,
      },
    }));
  }

  onChangeNome(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        nome: nomex,
      },
    }));
  }

  handleKeyDown() {
    console.log(this.state.produto.ean);
    if (this.state.produto.ean !== undefined) {
      if (this.state.produto.ean !== null) {
        if (this.state.produto.ean.length >= 13) {
          ApiService.ean(this.state.produto.ean)
            .then((response) => {
              if (response.status === 200) {
                this.setState((prevState) => ({
                  produto: {
                    ...prevState.produto,
                    produtofk: response.data.idProd,
                  },
                }));
                console.log(response.data);
              } else {
                this.setState((prevState) => ({
                  produto: {
                    ...prevState.produto,
                    produtofk: null,
                  },
                }));
              }
            })
            .catch((e) => {
              this.setState((prevState) => ({
                produto: {
                  ...prevState.produto,
                  produtofk: null,
                },
              }));
              ///   this.setState ({message: 'Erro ao consulta ean'+e});
              console.log(e);
            });
        } else {
          this.setState({
            produtofk: null,
          });
        }
      }
    }
  }

  onChangeEan(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        ean: nomex,
      },
    }));
  }

  onChangePrazoentrega(e) {
    const nomex = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        prazoentrega: nomex,
      },
    }));
  }

  onChangeCategoria(e) {
    const categoriax = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        subcategoria: { id: categoriax },
      },
    }));
  }

  onChangeStatus(e) {
    const statusx = e.target.value;

    this.setState((prevState) => ({
      produto: {
        ...prevState.produto,
        status: statusx,
      },
    }));
  }

  async excluir() {
    console.log(this.state.imagef);
    ApiService.excluirfoto(this.state.imagef)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          ApiService.fotosproduto(this.state.imagef.produto.id)
            .then((response) => {
              this.setState({
                pictures: response.data,
              });
              console.log(response.data);
            })
            .catch((e) => {
              this.setState({ message: "Erro ao listar fotos do produto" });
              console.log(e);
            });
        }
      })
      .catch((e) => {
        this.setState({ message: "Erro ao excluir foto" });
        console.log(e);
      });
  }

  async padrao() {
    this.state.imagef.padrao = !this.state.imagef.padrao;
    ApiService.atualizarfoto(this.state.imagef)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          ApiService.fotosproduto(this.state.imagef.produto.id)
            .then((response) => {
              this.setState({
                pictures: response.data,
              });
              console.log(response.data);
            })
            .catch((e) => {
              this.setState({ message: "Erro ao listar fotos do produto" });
              console.log(e);
            });
        }
      })
      .catch((e) => {
        this.setState({ message: "Erro ao atualizar foto" });
        console.log(e);
      });
  }

  async salvar() {
    var ok = true;
    if (this.state.pictures === undefined) {
      this.state.pictures = [];
    }
    if (this.state.pictures2 === undefined) {
      this.state.pictures2 = [];
    }
    console.log(this.state.pictures2);
    console.log(this.state.pictures);
    if (this.state.pictures2.length + this.state.pictures.length > 6) {
      ok = false;
      alert("maximo permitido de 6 fotos");
    }

    if (this.state.pictures2.length + this.state.pictures.length === 0) {
      ok = false;
      alert("selecione pelo menos uma foto");
    }

    if (this.state.produto.preco === 0) {
      ok = false;
      alert("preco invalido");
    }

    if (this.state.produto.preco < this.state.produto.desconto) {
      ok = false;
      alert("desconto nao pode ser maior que o preco");
    }

    if (this.state.produto.tipo !== "Kit") {
      if (this.state.produto.produtofk === null) {
        ok = false;
        alert("codigo de barras nao encontrado");
      }
    }

    if (this.state.produto.tipo === "Kit") {
      if (this.state.produto.produtosi.length < 2) {
        ok = false;
        alert("adicione pelo menos 2 itens ao kit");
      }
    }

    if (ok) {
      var data = {
        id: this.state.produto.id,
        nome: this.state.produto.nome,
        status: this.state.produto.status,
        subcategoria: { id: this.state.produto.subcategoria.id },
        quantidade: this.state.produto.quantidade,
        preco: this.state.produto.preco,
        desconto: this.state.produto.desconto,
        promocao: this.state.produto.promocao,
        laboratorio: this.state.produto.laboratorio,
        dosagem: this.state.produto.dosagem,
        introducao: this.state.produto.introducao,
        caracteristica: this.state.produto.caracteristica,
        ean: this.state.produto.ean,
        produtofk: this.state.produto.produtofk,
        tipo: this.state.produto.tipo,
        produtosi: this.state.produto.produtosi,
        produtosx: this.state.produtosx,
        prazoentrega: this.state.produto.prazoentrega,
        valormontagem: this.state.produto.valormontagem,
      };
      var cores = "";
      console.log(this.state.cores);
      for (const [key, value] of this.state.cores.entries()) {
        if (value == true) {
          cores += key + ",";
        }
      }
      data.cores = cores;
      console.log(data);

      ApiService.atualizarproduto(data)
        .then((response) => {
          if (response.status === 200) {
            var padrao = true;
            for (let i = 0; i < this.state.pictures2.length; i++) {
              (async () => {
                await ApiService.uploadproduto(
                  this.state.pictures2[i],
                  response.data.id,
                  response.data.id +
                    "-p" +
                    new Date().getTime() +
                    "-" +
                    i +
                    ".jpg",
                  padrao
                );
              })();
              padrao = false;
            }
            this.setState({
              submitted: true,
            });
            console.log(response.data);
          }
        })
        .catch((e) => {
          this.setState({ message: "Erro ao atualizar produto" });
          console.log(e);
        });
    }
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/produtos" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: "640px" }}>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Editar Produto</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Editar Produto</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  {this.state.message && (
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>
                  )}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomecategoria">Código do Produto:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.produto.id}
                        name="id"
                        disabled
                        style={{ width: "25%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Tipo</label>
                      <select
                        disabled={true}
                        className="form-control select2"
                        style={{ width: "25%" }}
                        value={this.state.produto.tipo}
                        onChange={this.onChangeTipo}
                        name="tipo"
                      >
                        <option defaultValue value="Normal">
                          Normal
                        </option>
                        <option value="Kit">Kit</option>
                      </select>
                    </div>

                    {this.state.produto.tipo !== "Kit" && (
                      <div className="form-group">
                        <label htmlFor="ean">
                          Código de Barras:{" "}
                          {this.state.produto.produtofk !== null
                            ? " - Produto ERP:" + this.state.produto.produtofk
                            : ""}
                        </label>
                        <input
                          type="number"
                          maxLength="14"
                          className="form-control"
                          id="ean"
                          placeholder="Insira o Código De Barras Do Produto"
                          required
                          disabled={this.state.produto.produtofk !== null}
                          value={this.state.produto.ean}
                          onChange={this.onChangeEan}
                          onBlur={this.handleKeyDown}
                          name="ean"
                          style={{ width: "460px" }}
                        />
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="nomecategoria">Nome do Produto:</label>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="nomecategoria"
                        placeholder="Insira o nome da nova produto"
                        required
                        value={this.state.produto.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{ width: "460px" }}
                      />
                    </div>

                    <div className="form-group">
                      <label>SubCategoria:</label>
                      <select
                        required
                        className="form-control select2"
                        style={{ width: "460px" }}
                        value={
                          this.state.produto.subcategoria !== null
                            ? this.state.produto.subcategoria.id
                            : ""
                        }
                        onChange={this.onChangeCategoria}
                        name="categoria"
                      >
                        {this.state.subcategorias &&
                          this.state.subcategorias.map((subcategoria) => (
                            <option
                              key={subcategoria.id}
                              value={subcategoria.id}
                            >
                              {subcategoria.id +
                                "- " +
                                subcategoria.nome +
                                " - Categoria: " +
                                subcategoria.categoria.nome}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card card-primary">
                  {this.state.message && (
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>
                  )}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="laboratorio">Fabricante:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="laboratorio"
                        placeholder="Insira o  laboratorio"
                        value={this.state.produto.laboratorio}
                        onChange={this.onChangeLaboratorio}
                        name="laboratorio"
                        style={{ width: "460px" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="dosagem">Dosagem:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="dosagem"
                        placeholder="Insira a dosagem"
                        value={this.state.produto.dosagem}
                        onChange={this.onChangeDosagem}
                        name="dosagem"
                        style={{ width: "460px" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="quantidade">Quantidade:</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="quantidade"
                        placeholder="Insira a quantidade "
                        value={this.state.produto.quantidade}
                        onChange={this.onChangeQuantidade}
                        name="quantidade"
                        style={{ width: "460px" }}
                      />
                    </div>

                    {this.state.produto.tipo === "Kit" && (
                      <div className="form-group">
                        <label>Produtos do Kit</label>
                        <select
                          required
                          className="form-control select2"
                          value={this.state.produtoi}
                          onChange={this.onChangeProdutoi}
                          name="produtoi"
                        >
                          <option defaultValue value="">
                            selecione
                          </option>
                          {this.state.produtos &&
                            this.state.produtos.map((produto) => (
                              <option value={produto.id + "##" + produto.nome}>
                                {produto.id +
                                  "- " +
                                  produto.nome +
                                  " - R$" +
                                  Number(produto.preco).toFixed(2)}
                              </option>
                            ))}
                        </select>
                        <input
                          type="number"
                          min="0.00"
                          max="10000.00"
                          step="0.01"
                          className="form-control"
                          id="dosagem"
                          placeholder="Preco Item"
                          value={this.state.precoi}
                          onChange={this.onChangePrecoi}
                          name="preco"
                          style={{ width: "20%" }}
                        />
                        <button
                          className="btn btn-primary"
                          onClick={() => this.adicionar()}
                        >
                          Adicionar
                        </button>

                        <table
                          id="example1"
                          className="table table-bordered table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Codigo</th>
                              <th>Nome</th>
                              <th>Preço</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.produto.produtosi &&
                              this.state.produto.produtosi.map((produto) => (
                                <tr key={produto.produto_fk}>
                                  <td>{produto.produto_fk}</td>
                                  <td>{produto.nome}</td>
                                  <td>{Number(produto.preco).toFixed(2)}</td>
                                  <td>
                                    <button
                                      className="btn btn-danger"
                                      onClick={() => this.excluirx(produto)}
                                    >
                                      excluir
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="preco">Preço:</label>
                      <input
                        type="number"
                        min="0.00"
                        max="10000.00"
                        disabled={this.state.produto.tipo === "Kit"}
                        step="0.01"
                        className="form-control"
                        id="dosagem"
                        placeholder="Insira o preco "
                        value={this.state.produto.preco}
                        onChange={this.onChangePreco}
                        name="preco"
                        style={{ width: "20%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="desconto">Valor Desconto(R$):</label>
                      <input
                        type="number"
                        min="0.00"
                        max="10000.00"
                        disabled={this.state.produto.tipo === "Kit"}
                        step="0.01"
                        className="form-control"
                        id="desconto"
                        placeholder="Insira o desconto "
                        value={this.state.produto.desconto}
                        onChange={this.onChangeDesconto}
                        name="desconto"
                        style={{ width: "20%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="card card-primary">
                  {this.state.message && (
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>
                  )}

                  <div className="card-body">
                    {this.state.segmento == "11" && (
                      <div className="form-group">
                        <div>
                          {/*
                          <label htmlFor="">
                            Opções de Lugares e Tamanhos:
                          </label>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <div className="form-group">
                              <label htmlFor="valor">Lugares:</label>
                              <input
                                type="number"
                                required
                                placeholder="Insira o Valor "
                                name="Valor"
                                style={{ width: "130px" }}
                                className="form-control"
                              />
                            </div>
                            &nbsp;
                            <div className="form-group">
                              <label htmlFor="valor">Comprimento:</label>
                              <input
                                type="number"
                                required
                                placeholder="Insira o Valor "
                                name="Valor"
                                style={{ width: "130px" }}
                                className="form-control"
                              />
                            </div>
                            &nbsp;
                            <div className="form-group">
                              <label htmlFor="valor">Altura:</label>
                              <input
                                type="number"
                                required
                                placeholder="Insira o Valor "
                                name="Valor"
                                style={{ width: "130px" }}
                                className="form-control"
                              />
                            </div>
                            &nbsp;
                            <div className="form-group">
                              <label htmlFor="valor">Profundidade:</label>
                              <input
                                type="number"
                                required
                                placeholder="Insira o Valor "
                                name="Valor"
                                style={{ width: "130px" }}
                                className="form-control"
                              />
                            </div>
                            &nbsp;
                            <div className="form-group">
                              <label htmlFor="valor">Preço:</label>
                              <input
                                type="number"
                                required
                                placeholder="Insira o Valor "
                                name="Valor"
                                style={{ width: "130px" }}
                                className="form-control"
                              />
                            </div>
                            &nbsp;
                            <div className="form-group">
                              <button
                                style={{ marginTop: "35%" }}
                                className="btn btn-primary form-control"
                              >
                                Adicionar Opção
                              </button>
                              &nbsp;
                            </div>
                          </div>

                          <div>
                            <input type="checkbox" /> Poltrona 1,05C x 0,85A x
                            0,85P -R$900,00 <br />
                            <input type="checkbox" /> 2 Lugares 1,60C x 0,85A x
                            0,85P -R$300,00
                            <br />
                            <input type="checkbox" /> 3 Lugares 2,10C x 0,85A x
                            0,85P
                            <br />
                            <input type="checkbox" /> 4 Lugares 2,50C x 0,85A x
                            0,85P +R$959,00
                            <br />
                            <input type="checkbox" /> 5 Lugares 3,25C x 0,85A x
                            0,85P +R$1.600,00
                            <br />
                            <button className="btn btn-danger">Excluir</button>
                          </div>
                          <hr />
                          <label htmlFor="">Opções de Comprar Também:</label>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <div className="form-group">
                              <label htmlFor="valor">Lugares:</label>
                              <input
                                type="number"
                                required
                                placeholder="Insira o Valor "
                                name="Valor"
                                style={{ width: "130px" }}
                                className="form-control"
                              />
                            </div>
                            &nbsp;
                            <div className="form-group">
                              <label htmlFor="valor">Comprimento:</label>
                              <input
                                type="number"
                                required
                                placeholder="Insira o Valor "
                                name="Valor"
                                style={{ width: "130px" }}
                                className="form-control"
                              />
                            </div>
                            &nbsp;
                            <div className="form-group">
                              <label htmlFor="valor">Altura:</label>
                              <input
                                type="number"
                                required
                                placeholder="Insira o Valor "
                                name="Valor"
                                style={{ width: "130px" }}
                                className="form-control"
                              />
                            </div>
                            &nbsp;
                            <div className="form-group">
                              <label htmlFor="valor">Profundidade:</label>
                              <input
                                type="number"
                                required
                                placeholder="Insira o Valor "
                                name="Valor"
                                style={{ width: "130px" }}
                                className="form-control"
                              />
                            </div>
                            &nbsp;
                            <div className="form-group">
                              <label htmlFor="valor">Preço:</label>
                              <input
                                type="number"
                                required
                                placeholder="Insira o Valor "
                                name="Valor"
                                style={{ width: "130px" }}
                                className="form-control"
                              />
                            </div>
                            &nbsp;
                            <div className="form-group">
                              <button
                                style={{ marginTop: "35%" }}
                                className="btn btn-primary form-control"
                              >
                                Adicionar Opção
                              </button>
                              &nbsp;
                            </div>
                          </div>

                          <div>
                            <input type="checkbox" name="pc" /> Poltrona 1,05C x
                            0,85A x 0,85P +R$1.690,00
                            <br />
                            <input type="checkbox" name="2c" /> 2 Lugares 1,60C
                            x 0,85A x 0,85P +R$2.290,00
                            <br />
                            <input type="checkbox" name="3c" /> 3 Lugares 2,10C
                            x 0,85A x 0,85P +R$2.590,00
                            <br />
                            <input type="checkbox" name="4c" /> 4 Lugares 2,50C
                            x 0,85A x 0,85P +R$3.549,00
                            <br />
                            <input type="checkbox" name="5c" /> 5 Lugares 3,25C
                            x 0,85A x 0,85P +R$4.190,00
                            <br />
                            <button className="btn btn-danger">Excluir</button>
                          </div>*/}
                          <hr />
                          <label htmlFor="">Montagem de Fábrica:</label>
                          <input
                            type="number"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            disabled={this.state.tipo === "Kit"}
                            className="form-control"
                            id="valormontagem"
                            placeholder="Insira o valormontagem "
                            value={this.state.produto.valormontagem}
                            onChange={this.onChangevalormontagem}
                            name="valormontagem"
                            style={{ width: "20%" }}
                          />
                        </div>
                        <br />
                        <label htmlFor="dosagem">Cores do Produto:</label>
                        <div class="row">
                          {checkboxes.map((item) => (
                            <div class="col-md-1">
                              <div class="custom-control custom-checkbox image-checkbox">
                                <label key={item.key}>
                                  <input
                                    type="checkbox"
                                    name={item.name}
                                    checked={this.state.cores.get(item.name)}
                                    onChange={this.onChangecores}
                                  />
                                  <div
                                    style={{
                                      height: "70px",
                                      width: "70px",
                                      backgroundColor: item.cor,
                                    }}
                                  />

                                  {item.label}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="prazo">Prazo Entrega:</label>
                      <input
                        type="text"
                        maxLength="20"
                        className="form-control"
                        id="prazo"
                        value={this.state.produto.prazoentrega}
                        onChange={this.onChangePrazoentrega}
                        name="prazo"
                        style={{ width: "70%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        className="form-control select2"
                        style={{ width: "12%" }}
                        value={this.state.produto.status}
                        onChange={this.onChangeStatus}
                        name="status"
                      >
                        <option defaultValue value="Ativo">
                          Ativo
                        </option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="introducao">Introdução:</label>
                      <textarea
                        type="text"
                        maxLength="500"
                        rows={2}
                        cols={5}
                        className="form-control"
                        id="introducao"
                        placeholder="Insira a introducao"
                        value={this.state.produto.introducao}
                        onChange={this.onChangeIntroducao}
                        name="introducao"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="dosagem">Caracteristica:</label>
                      <textarea
                        type="text"
                        maxLength="500"
                        rows={2}
                        cols={5}
                        className="form-control"
                        id="caracteristica"
                        placeholder="Insira a caracteristica"
                        value={this.state.produto.caracteristica}
                        onChange={this.onChangeCaracteristica}
                        name="caracteristica"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="foto">Foto (450 x 450):</label>
                      <ImageUploader
                        withIcon={true}
                        id="foto"
                        label="apenas fotos jpg"
                        buttonText="selecione ate 6 fotos jpg"
                        onChange={this.onDrop}
                        imgExtension={[".jpg"]}
                        maxFileSize={102400}
                        fileSizeError="tamanho maximo por foto 100kb"
                        fileTypeError="apenas arquivos jpg"
                        withPreview={true}
                      />
                    </div>

                    {this.state.pictures &&
                      this.state.pictures.map((image) => (
                        <div style={{ padding: "10px", float: "left" }}>
                          <img
                            src={"data:image/jpeg;base64," + image.data}
                            width="209px"
                            height="209px"
                            alt={image.nome}
                          />
                          <br />

                          <div className="card-footer">
                            <table>
                              <tr>
                                <td>
                                  <div>
                                    <button
                                      className="btn btn-success"
                                      onClick={() => {
                                        this.setState({ imagef: image });
                                        this.state.imagef = image;

                                        this.padrao();
                                      }}
                                    >
                                      {image.padrao ? "Padrao" : "Setar Padrao"}
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <button
                                      className="btn btn-danger"
                                      onClick={() => {
                                        this.setState({ imagef: image });
                                        this.state.imagef = image;

                                        this.excluir();
                                      }}
                                    >
                                      Excluir
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="card-footer">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button className="btn btn-primary" onClick={this.salvar}>
                        Salvar
                      </button>

                      <NavLink to={"/produtos/"} className="nav-link">
                        <button
                          className="btn btn-danger"
                          onClick={this.voltar}
                        >
                          Voltar
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default EditProdutos;
