import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';

class Footer extends Component {

  componentDidMount () {
    this.createscript ('plugins/jquery/jquery.min.js');
    this.createscript ('plugins/bootstrap/js/bootstrap.bundle.min.js');
    this.createscript ('dist/js/adminlte.js');
    this.createscript ('plugins/chart.js/Chart.min.js');
    this.createscript ('dist/js/demo.js');
    this.createscript ('dist/js/pages/dashboard3.js');
  }

  createscript (link) {
    var script = document.createElement ('script');
    script.src = link;
    script.async = true;
    document.body.appendChild (script);
  }
  render () {
    return (
      <footer className="main-footer">
				<strong>Top Systems &copy; 2020 - 2021.</strong>				
				<div className="float-right d-none d-sm-inline-block">
					<b>Version</b> 3.0.5
				</div>
			</footer>
    );
  }
}

export default Footer;
