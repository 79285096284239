import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import {Redirect, NavLink} from 'react-router-dom';
import Moment from 'react-moment';

class VerClientes extends Component {
  constructor (props) {
    super (props);
    console.log ('akiiiiaaa');
    this.state = {
      pedidos: [],
      cliente: {
        id: null,
        nome: '',
        status: '',
        dataaltera: null,
        usuarioaltera: null,
      },
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind (this);
    this.onChangeNome = this.onChangeNome.bind (this);
    this.onChangeStatus = this.onChangeStatus.bind (this);
  }
  componentDidMount () {
    console.log (this.props.match.params.id + 'kkkkk');
    ApiService.cliente (this.props.match.params.id)
      .then (response => {
        this.setState ({
          cliente: response.data,
        });

        ApiService.ultimoscli (response.data.email)
          .then (response => {
            this.setState ({
              pedidos: response.data,
            });
            console.log (response.data);
          })
          .catch (e => {
            this.setState ({message: 'Erro ao listar pedidos'});
            console.log (e);
          });

        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao atualizar cliente'});
        console.log (e);
      });
    window.scrollTo (0, 0);
  }

  onChangeNome (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      cliente: {
        ...prevState.cliente,
        nome: nomex,
      },
    }));
  }

  onChangeStatus (e) {
    const statusx = e.target.value;

    this.setState (prevState => ({
      cliente: {
        ...prevState.cliente,
        status: statusx,
      },
    }));
  }

  salvar () {}

  render () {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/clientes" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '640px'}}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Ver Cliente</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Ver Cliente
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">

                  <div className="card-header">
                    <h3 className="card-title">Dados Pessoais do Cliente</h3>
                  </div>

                  <div className="card-body">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <strong>
                        <i className="fas fa-book mr-1" /> Nome Completo:
                      </strong>
                      &nbsp;
                      <a className="text-muted">
                        {this.state.cliente.nome}
                      </a>
                    </div>

                    <hr />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <strong>
                        <i className="fas fa fa-birthday-cake" />
                        {' '}
                        Data de Nascimento:
                      </strong>
                      &nbsp;
                      <a className="text-muted">
                        {this.state.cliente.datanascimento}
                      </a>
                    </div>

                    <hr />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <strong><i className="fas fa fa-id-card" /> CPF:</strong>
                      &nbsp;
                      <a className="text-muted">
                        <span className="tag tag-danger">
                          {' '} {this.state.cliente.cpfcnpj}
                        </span>
                      </a>
                    </div>
                    <hr />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <strong>
                        <i className="fas fa-pencil-alt mr-1" /> Email:
                      </strong>
                      &nbsp;
                      <a className="text-muted">
                        <span className="tag tag-danger">
                          {' '} {this.state.cliente.email}
                        </span>
                      </a>
                    </div>
                    <hr />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <strong>
                        <i className="far fa-file-alt mr-1" /> Observações:
                      </strong>
                      &nbsp;
                      <a className="text-muted">
                        {this.state.cliente.observacao}
                      </a>
                    </div>
                    <hr />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <strong>
                        <i className="far fa-file-alt mr-1" /> Status:
                      </strong>
                      &nbsp;
                      <a className="text-muted">
                        <span className="tag tag-danger">
                          {' '} {this.state.cliente.status}{' '}
                        </span>
                      </a>
                    </div>
                  </div>

                </div>

              </div>
              <div className="col-md-6">

                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Dados de Contato do Cliente</h3>
                  </div>

                  <div className="card-body">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <strong>
                        <i className="fas fa-map-marker-alt mr-1" /> CEP:
                      </strong>
                      &nbsp;
                      <a className="text-muted">  {this.state.cliente.cep}</a>
                    </div>
                    <hr />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <strong>
                        <i className="fas fa-map-marker-alt mr-1" /> Endereço:
                      </strong>
                      &nbsp;
                      <a className="text-muted">
                        {' '}{' '}{' '}
                        {this.state.cliente.logradouro}
                        ,
                        {' '}
                        {this.state.cliente.numero}
                        ,
                        {' '}
                        {this.state.cliente.bairro}
                      </a>
                    </div>
                    <hr />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <strong>
                        <i className="fas fa-map-marker-alt mr-1" /> Complemento:
                      </strong>
                      &nbsp;
                      <a className="text-muted">
                        {this.state.cliente.complemento}
                      </a>
                    </div>
                    <hr />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <strong>
                        <i className="fas fa-map-marker-alt mr-1" /> Cidade & UF:
                      </strong>
                      &nbsp;
                      <a className="text-muted">
                        {' '}{' '}
                        {this.state.cliente.cidade}
                        {' '}
                        -
                        {' '}
                        {this.state.cliente.uf}
                      </a>
                    </div>
                    <hr />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <strong><i className="fas fa fa-phone" /> Telefone:</strong>
                      &nbsp;
                      <a className="text-muted">
                        <span className="tag tag-danger">
                          {' '} {this.state.cliente.telefone}{' '}
                        </span>
                      </a>
                    </div>

                    <hr />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <strong><i className="fas fa" /> </strong>
                      &nbsp;
                      <a className="text-muted">
                        <span className="tag tag-danger" />
                      </a>
                    </div>
                  </div>

                </div>

              </div>
              <div className="col-lg-12">

                <div className="card">
                  <div className="card-header border-transparent">
                    <h3 className="card-title">Pedidos</h3>

                    <div className="card-tools" />
                  </div>

                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table className="table m-0">
                        <thead>
                          <tr>
                            <th>Nº Pedido</th>
                            <th>Data</th>
                            <th>SubTotal</th>
                            <th>Total</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.pedidos &&
                            this.state.pedidos.map (pedido => (
                              <tr key={pedido.id}>
                                <td>
                                  {' '}{' '}

                                  {pedido.numeropedido}

                                </td>
                                <td>
                                  <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {pedido.datapedido}
                                  </Moment>
                                </td>
                                <td>
                                  <div
                                    className="sparkbar"
                                    data-color="#00a65a"
                                    data-height="20"
                                  >
                                    R${Number (pedido.subtotal).toFixed (2)}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="sparkbar"
                                    data-color="#00a65a"
                                    data-height="20"
                                  >
                                    R${Number (pedido.total).toFixed (2)}
                                  </div>
                                </td>
                                <td>
                                  <span className="badge badge-success">
                                  {pedido.status==='Processando'?'Pendente':pedido.status}
                                  </span>
                                </td>
                                <td>
                                <div className="btn-group">

                                  <NavLink
                                    to={`/ver-pedido/${pedido.id}`}
                                    className="nav-link"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                    >
                                      Ver
                                    </button>
                                  </NavLink>

                                </div>
                              </td>
                              </tr>
                            ))}

                        </tbody>
                      </table>
                    </div>

                  </div>

                </div>
              </div>
            </div>
            <div className="card-footer">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <NavLink to={'/clientes/'} className="nav-link">
                  <button className="btn btn-danger" onClick={this.voltar}>
                    Voltar
                  </button>
                </NavLink>
              </div>
            </div>

          </section>

        </div>
        <Footer />
      </div>
    );
  }
}

export default VerClientes;
