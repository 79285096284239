import React, { Component } from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import { Redirect, NavLink } from 'react-router-dom';
import ImageUploader from 'react-images-upload';

class AddCategorias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: '',
      link: '',
      status: 'Ativo',
      pictures: [],
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeLink = this.onChangeLink.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  async onDrop(pictureFiles, pictureDataURLs) {
    await this.setState({
      pictures: pictureFiles,
    });
    console.log(this.state.pictures);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onChangeNome(e) {
    this.setState({
      nome: e.target.value,
    });
  }

  onChangeLink(e) {
    this.setState({
      link: e.target.value,
    });
  }

  onChangeStatus(e) {
    this.setState({
      status: e.target.value,
    });
  }

  salvar() {
    if (this.state.pictures===undefined) {
      this.state.pictures = [];
    }
    var ok = true;
    if (this.state.pictures.length > 1) {
      ok = false;
      alert('maximo permitido de 1 foto');
    }

    if (ok) {
      var data = {
        nome: this.state.nome,
        link: this.state.link,
        status: this.state.status,
      };

      ApiService.salvardestaque(data)
        .then(response => {
          if (response.status===200 && this.state.pictures.length > 0) {
            (async () => {
              await ApiService.uploaddestaque(
                this.state.pictures[0],
                response.data.id,
                response.data.id + '-' + 0 + '.jpg'
              );
            })();

            this.setState({
              submitted: true,
            });
            console.log(response.data);
          }
        })
        .catch(e => {
          this.setState({ message: 'Erro ao cadastrar Destaque' });
          console.log(e);
        });
    }
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/destaques" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: '640px' }}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Adicionar Destaque</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Adicionar Destaque
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomedestaque">Nome do Destaque:</label>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="nomedestaque"
                        placeholder="Insira o nome do novo Destaque"
                        required
                        value={this.state.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{ width: '400px' }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="linkdestaque">Link do Destaque:</label>
                      <input
                        type="text"
                        maxLength="500"
                        className="form-control"
                        id="linkdestaque"
                        placeholder="Insira o link do novo Destaque"
                        required
                        value={this.state.link}
                        onChange={this.onChangeLink}
                        name="link"
                        style={{ width: '400px' }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        className="form-control select2"
                        style={{ width: '12%' }}
                        value={this.state.status}
                        onChange={this.onChangeStatus}
                        name="status"
                      >
                        <option defaultValue value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="foto">
                        Foto (1920 x 600):{' '}
                      </label>
                      <ImageUploader
                        withIcon={true}
                        id="foto"
                        label="Apenas Fotos .jpg"
                        buttonText="Selecione Até 1 Foto .jpg"
                        onChange={this.onDrop}
                        imgExtension={['.jpg']}
                        maxFileSize={204800}
                        fileSizeError="Tamanho Máximo Por Foto: 200kb"
                        fileTypeError="Apenas Arquivo .jpg"
                        withPreview={true}
                      />

                    </div>
                  </div>

                  <div className="card-footer">
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <button className="btn btn-primary" onClick={this.salvar}>
                              Adicionar
                    </button>
                          
                            <NavLink to={'/destaques/'} className="nav-link">
                              <button className="btn btn-danger" onClick={this.voltar}>
                                Voltar
                      </button>
                            </NavLink>
                    </div>
                        
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
        <Footer />
      </div>
    );
  }
}

export default AddCategorias;
