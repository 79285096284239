import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import {Redirect, NavLink} from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import Moment from 'react-moment';

class VerTransacaos extends Component {
  constructor (props) {
    super (props);
    console.log ('akiiiiaaa');
    this.state = {
      transacao: {
        id: null,
        merchant_usn: '',
        order_id: '',
        rstatus: '',
        status: '',
        rcode: '',
        code: '',
        nit: '',
        amount: '',
        authorizer_id: '',
        acquirer_name: '',
        authorizer_date: '',
        authorization_number: '',
        payment_date: '',
        payment_type: '',
        host_usn: '',
        customer_receipt: '',
        installments: '',
        dataaltera: null,
        usuarioaltera: null,
        esitef_usn: '',
        sitef_usn: '',
      },
      message: null,
      submitted: false,
    };

    this.consultar = this.consultar.bind (this);
  }
  consultar () {
    ApiService.consultatransancao (this.state.transacao)
      .then (response => {
        this.setState ({
          transacao: response.data,
        });
        this.setState ({message: response.data.message});
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao consultar transacao'});
        console.log (e);
      });
      window.scrollTo (0, 0);
  }

  componentDidMount () {
    console.log (this.props.match.params.id + 'kkkkk');

    ApiService.transancao (this.props.match.params.id)
      .then (response => {
        this.setState ({
          transacao: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao atualizar transacao'});
        console.log (e);
      });
    window.scrollTo (0, 0);
  }

  render () {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/transacaos" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '640px'}}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Ver Transação</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Ver Transação
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            {this.state.message &&
              <div className="alert alert-danger alert-dismissible">
                {this.state.message}
              </div>}
            <div className="row">

              <div className="col-md-5">
                <div className="card card-primary">

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nometransacao">Código Transação:</label>
                      <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={this.state.transacao.id}
                        name="id"
                        disabled
                        //style={{ width: "12%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomeevento">Data Hora:</label>
                      <div style={{padding: '7px', alignItems: 'flex-start'}}>
                        <Moment format="DD/MM/YYYY HH:mm:ss">
                          {this.state.transacao.dataaltera}
                        </Moment>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="nometransacao">Merchant USN:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="nometransacao"
                        required
                        value={this.state.transacao.merchant_usn}
                        name="nome"
                        //style={{ width: "50%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="assunto">Pedido:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="assunto"
                        required
                        value={this.state.transacao.order_id}
                        name="assunto"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="assunto">Status:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="assunto"
                        required
                        value={
                          this.state.transacao.status +
                            '-' +
                            this.state.transacao.rstatus +
                            '-' +
                            this.state.transacao.rstatusx
                        }
                        name="assunto"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <div className="card card-primary">

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="assunto">Code:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="assunto"
                        required
                        value={
                          this.state.transacao.code +
                            '-' +
                            this.state.transacao.rcode
                        }
                        name="assunto"
                      />
                    </div>

                    {this.state.transacao.nit &&
                      <div className="form-group">
                        <label htmlFor="assunto">Nit:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.nit}
                          name="assunto"
                        />
                      </div>}

                    <div className="form-group">
                      <label htmlFor="assunto">Valor:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="assunto"
                        required
                        value={this.state.transacao.amount}
                        name="assunto"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="assunto">Parcelas:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="assunto"
                        required
                        value={this.state.transacao.installments}
                        name="assunto"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="assunto">Cod.Autorizadora:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="assunto"
                        required
                        value={this.state.transacao.authorizer_id}
                        name="assunto"
                      />
                    </div>
                    {this.state.transacao.bandeira &&
                    <div className="form-group">
                      <label htmlFor="assunto">Bandeira/Cartao:</label>
                      <input
                        disabled={true}
                        type="text"
                        maxLength="200"
                        className="form-control"
                        id="assunto"
                        required
                        value={this.state.transacao.bandeira+' - '
                        +(this.state.transacao.finalcartao!=undefined?this.state.transacao.finalcartao:'')}
                        name="assunto"
                      />
                    </div>}
                    {this.state.transacao.qr_code &&
                    <div className="form-group">
                        <label htmlFor="assunto">QRCODE:</label>
                        <textarea
                          disabled={true}
                          type="text"
                          className="form-control"
                          id="assunto"
                          rows="5"
                          cols="50"
                          required
                          value={this.state.transacao.qr_code}
                          name="assunto"
                        />
                      </div>}
                  </div>
                </div>
              </div>

              {this.state.transacao.status==='CON' &&
                <div className="col-md-12">
                  <div className="card card-primary">

                    <div className="card-body">

                      <div className="form-group">
                        <label htmlFor="assunto">Tipo Pagamento:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.payment_type}
                          name="assunto"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="assunto">Nome do adquirente:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.acquirer_name}
                          name="assunto"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="assunto">Data de efetivação:</label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.authorizer_date}
                          name="assunto"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="assunto">
                          Número de Autorização:
                        </label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.authorization_number}
                          name="assunto"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="assunto">
                          Host USN:
                        </label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.host_usn}
                          name="assunto"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="assunto">
                          Sitef USN:
                        </label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.sitef_usn}
                          name="assunto"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="assunto">
                          ESitef USN:
                        </label>
                        <input
                          disabled={true}
                          type="text"
                          maxLength="200"
                          className="form-control"
                          id="assunto"
                          required
                          value={this.state.transacao.esitef_usn}
                          name="assunto"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="assunto">Cupom:</label>
                        <textarea
                          disabled={true}
                          type="text"
                          className="form-control"
                          id="assunto"
                          rows="20"
                          cols="50"
                          required
                          value={this.state.transacao.customer_receipt}
                          name="assunto"
                        />
                      </div>
                    </div>
                  </div>
                </div>}
            </div>

            <div className="card-footer">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >

                {(this.state.transacao.status === 'CON' || this.state.transacao.status === 'PEN') &&
                  <button className="btn btn-info" onClick={this.consultar}>
                    Consultar
                  </button>}
                <NavLink to={'/transacoes/'} className="nav-link">
                  <button className="btn btn-danger" onClick={this.voltar}>
                    Voltar
                  </button>
                </NavLink>
              </div>
            </div>
          </section>

        </div>
        <Footer />

      </div>
    );
  }
}

export default VerTransacaos;
