import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import {Redirect, NavLink} from 'react-router-dom';
import ImageUploader from 'react-images-upload';

class EditLogos extends Component {
  constructor (props) {
    super (props);
    console.log ('akiiiiaaa');
    this.state = {
      loja: {
        id: null,
        topo: '',
        rodape: '',
      },
      pictures2: [],
      pictures3: [],
      message: null,
      message2: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind (this);
    this.onDrop = this.onDrop.bind (this);
    this.onDrop3 = this.onDrop3.bind (this);
  }
  componentDidMount () {
    ApiService.lojalogos ()
      .then (response => {
        this.setState ({
          loja: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao atualizar loja'});
        console.log (e);
      });
    window.scrollTo (0, 0);
  }

  async onDrop (pictureFiles, pictureDataURLs) {
    await this.setState ({
      pictures2: pictureFiles,
    });
    console.log (this.state.pictures2);
  }

  async onDrop3 (pictureFiles, pictureDataURLs) {
    await this.setState ({
      pictures3: pictureFiles,
    });
    console.log (this.state.pictures3);
  }

  salvar () {
    var ok = true;

    if (this.state.pictures2===undefined) {
      this.state.pictures2 = [];
    }
    console.log (this.state.pictures2);
    if (this.state.pictures2.length > 1) {
      ok = false;
      alert ('maximo permitido de 1 topo');
    }

    if (this.state.pictures3===undefined) {
      this.state.pictures3 = [];
    }
    console.log (this.state.pictures3);
    if (this.state.pictures3.length > 1) {
      ok = false;
      alert ('maximo permitido de 1 rodape');
    }

    if (this.state.pictures3.length ==0 && this.state.pictures2.length ==0) {
      ok = false;
      alert ('selecione topo e rodape para atualizar');
    }

    if (ok) {
      ApiService.uploadloja (
        this.state.pictures2[0],
        'topo.png',
        this.state.pictures3[0],
        'rodape.png'
      )
        .then (response => {
          if (response.status===200) {
            this.setState ({
              submitted: true,
            });
            this.setState ({
              pictures2: [],
              pictures3:[]
            });
            console.log (response.data);
            this.setState ({message2: 'Loja atualizada com sucesso'});
            ApiService.lojalogos ()
              .then (response => {
                this.setState ({
                  loja: response.data,
                });
                console.log (response.data);
              })
              .catch (e => {
                this.setState ({message: 'Erro ao atualizar loja'});
                console.log (e);
              });
          }
        })
        .catch (e => {
          this.setState ({message: 'Erro ao atualizar loja'});
          console.log (e);
        });
    }
  }

  render () {
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '640px'}}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Logos</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Logos
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  {this.state.message2 &&
                    <div className="alert alert-info alert-dismissible">
                      {this.state.message2}
                    </div>}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomeloja">Código da Loja:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.loja.id}
                        name="id"
                        disabled
                        style={{width: '10%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="foto">
                        Logo do Topo (195 x 46):{' '}
                      </label>
                      <ImageUploader
                        withIcon={true}
                        id="foto"
                        label="Apenas Fotos .png"
                        buttonText="Selecione Até 1 Foto .png"
                        onChange={this.onDrop}
                        imgExtension={['.png']}
                        maxFileSize={204800}
                        fileSizeError="Tamanho Máximo Por Foto: 200kb"
                        fileTypeError="Apenas Arquivo .png"
                        withPreview={true}
                      />

                    </div>

                    {this.state.loja.topo &&
                      <div className="form-group">
                        <img
                          src={'data:image/jpeg;base64,' + this.state.loja.topo}                     
                          alt={'topo.png'}
                        />
                      </div>}

                    <div className="form-group">
                      <label htmlFor="foto">
                        Logo do Rodapé:{' '}
                      </label>
                      <ImageUploader
                        withIcon={true}
                        id="foto"
                        label="Apenas Fotos .png"
                        buttonText="Selecione Até 1 Foto .png"
                        onChange={this.onDrop3}
                        imgExtension={['.png']}
                        maxFileSize={204800}
                        fileSizeError="Tamanho Máximo Por Foto: 200kb"
                        fileTypeError="Apenas Arquivo .png"
                        withPreview={true}
                      />

                    </div>

                    {this.state.loja.rodape &&
                      <div className="form-group">
                        <img
                          src={'data:image/jpeg;base64,' + this.state.loja.rodape}
                     
                          alt={'rodape.png'}
                        />

                      </div>}

                  </div>

                  <div className="card-footer">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <button className="btn btn-primary" onClick={this.salvar}>
                        Salvar
                      </button>
                      <NavLink to={'/'} className="nav-link">
                        <button
                          className="btn btn-danger"
                          onClick={this.voltar}
                        >
                          Voltar
                        </button>
                      </NavLink>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </section>

        </div>
        <Footer />
      </div>
    );
  }
}

export default EditLogos;
