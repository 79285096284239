import React, {Component} from 'react';
import Moment from 'react-moment';
import {NavLink} from 'react-router-dom';
import ApiService from '../../service/ApiService';

class ListaCashBacks extends Component {
  constructor (props) {
    super (props);
    this.state = {
      cashbacks: [],
      message: null,
    };
    this.listar = this.listar.bind (this);
  }

  componentDidMount () {
    this.listar ();
  }

  listar () {
    ApiService.cashbacks ()
      .then (response => {
        this.setState ({
          cashbacks: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao listar cashbacks'});
        console.log (e);
      });
  }

  render () {
    return (
      <div className="content-wrapper">

        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>CashBacks</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Início</a></li>
                  <li className="breadcrumb-item active">CashBacks</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <td>
                        <NavLink to="/adicionar-cashback" className="nav-link">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                          >
                            Inserir Novo CashBack
                          </button>
                        </NavLink>
                      </td>
                    </div>
                  </div>

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Codigo</th>
                          <th>Nome</th>
                          <th>Status</th>
                          <th>Tipo</th>
                          <th>Valor</th>
                          <th>Equivale em dinheiro</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>

                        {this.state.cashbacks &&
                          this.state.cashbacks.map (cashback => (
                            <tr key={cashback.id}>
                              <td>{cashback.id}</td>
                              <td>{cashback.nome}</td>
                              <td
                                style={{
                                  color: cashback.status !== 'Ativo'
                                    ? 'red'
                                    : 'green',
                                }}
                              >
                                {cashback.status}
                              </td>
                              <td>{cashback.percentualreal}</td>
                              <td>{cashback.valor.toFixed (2)}</td>
                              <td>{cashback.equivale}</td>
                              <td>
                                <div className="btn-group">
                                  <NavLink
                                    to={`/editar-cashback/${cashback.id}`}
                                    className="nav-link"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-info"
                                    >
                                      Editar
                                    </button>
                                  </NavLink>
                                  <NavLink
                                    to={`/ver-cashback/${cashback.id}`}
                                    className="nav-link"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                    >
                                      Ver
                                    </button>
                                  </NavLink>

                                </div>
                              </td>
                            </tr>
                          ))}

                      </tbody>

                    </table>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </section>

      </div>
    );
  }
}

export default ListaCashBacks;
