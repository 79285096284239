import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import {Redirect, NavLink} from 'react-router-dom';

class EditCores extends Component {
  constructor (props) {
    super (props);
    console.log ('akiiiiaaa');
    this.state = {
      loja: {
        id: null,
        corprincipal: '',
        corsecundaria: '',
        cormenu: '',
        cormenuativo: '',
      },
      message: null,
      message2: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind (this);
    this.onChangeCorprincipal = this.onChangeCorprincipal.bind (this);
    this.onChangeCorsecundaria = this.onChangeCorsecundaria.bind (this);
    this.onChangeCormenu = this.onChangeCormenu.bind (this);
    this.onChangeCormenuativo = this.onChangeCormenuativo.bind (this);
  }
  componentDidMount () {
    ApiService.loja ()
      .then (response => {
        this.setState ({
          loja: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao atualizar loja'});
        console.log (e);
      });
    window.scrollTo (0, 0);
  }

  onChangeCorprincipal (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        corprincipal: nomex,
      },
    }));
  }

  onChangeCorsecundaria (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        corsecundaria: nomex,
      },
    }));
  }

  onChangeCormenu (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        cormenu: nomex,
      },
    }));
  }

  onChangeCormenuativo (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        cormenuativo: nomex,
      },
    }));
  }

  salvar () {
    var data = {
      id: this.state.loja.id,
      corsecundaria: this.state.loja.corsecundaria,
      corprincipal: this.state.loja.corprincipal,
      cormenuativo: this.state.loja.cormenuativo,
      cormenu: this.state.loja.cormenu,
    };

    ApiService.atualizarloja5 (data)
      .then (response => {
        this.setState ({
          submitted: true,
        });
        console.log (response.data);
        window.scrollTo (0, 0);
        this.setState ({message2: 'Loja atualizada com sucesso'});
      })
      .catch (e => {
        this.setState ({message: 'Erro ao atualizar loja'});
        console.log (e);
      });
  }

  render () {
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '640px'}}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Cores</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                     Cores
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  {this.state.message2 &&
                    <div className="alert alert-info alert-dismissible">
                      {this.state.message2}
                    </div>}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomeloja">Código da Loja:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.loja.id}
                        name="id"
                        disabled
                        style={{width: '10%'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomeloja">Cor Principal (Header e Footer):</label>
                      <input
                        type="color"
                        maxLength="200"
                        className="form-control"
                        id="nomeloja"
                        placeholder="Insira a cor principal da Loja"
                        required
                        value={this.state.loja.corprincipal}
                        onChange={this.onChangeCorprincipal}
                        name="nome"
                        style={{width: '10%'}}
                      />
                     
                    </div>


                    <div className="form-group">
                      <label htmlFor="nomeloja">Cor Secundaria (Botões e Widgets):</label>
                      <input
                        type="color"
                        maxLength="200"
                        className="form-control"
                        id="nomeloja"
                        placeholder="Insira a cor secundaria da Loja"
                        required
                        value={this.state.loja.corsecundaria}
                        onChange={this.onChangeCorsecundaria}
                        name="nome"
                        style={{width: '10%'}}
                      />
                     
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomeloja">Cor Das Categorias:</label>
                      <input
                        type="color"
                        maxLength="200"
                        className="form-control"
                        id="nomeloja"
                        placeholder="Insira a cor secundaria da Loja"
                        required
                        // value={this.state.loja.corsecundaria}
                        // onChange={this.onChangeCorsecundaria}
                        name="nome"
                        style={{width: '10%'}}
                      />
                     
                    </div>

                    <div className="form-group">
                      <label htmlFor="nomeloja">Cor Dos Botões de Comprar:</label>
                      <input
                        type="color"
                        maxLength="200"
                        className="form-control"
                        id="nomeloja"
                        placeholder="Insira a cor secundaria da Loja"
                        required
                        // value={this.state.loja.corsecundaria}
                        // onChange={this.onChangeCorsecundaria}
                        name="nome"
                        style={{width: '10%'}}
                      />
                     
                    </div>

                  

                  </div>

                  <div className="card-footer">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <button className="btn btn-primary" onClick={this.salvar}>
                        Salvar
                      </button>
                      <NavLink to={'/'} className="nav-link">
                        <button
                          className="btn btn-danger"
                          onClick={this.voltar}
                        >
                          Voltar
                        </button>
                      </NavLink>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </section>

        </div>
        <Footer />
      </div>
    );
  }
}

export default EditCores;
