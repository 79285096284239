import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import {Redirect, NavLink} from 'react-router-dom';
import ImageUploader from 'react-images-upload';

class EditNFE extends Component {
  constructor (props) {
    super (props);
    console.log ('akiiiiaaa');
    this.state = {
      loja: {
        id: null,
        dataaltera: null,
        usuarioaltera: null,
        serienfe: '',
        certificadonfe: '',
        senhanfe: '',
        ambientenfe: '',
        cnpj:'',
        uf:'',
      },
      travar:false,
      pfx: null,
      message: null,
      message2: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind (this);
    this.teste = this.teste.bind (this);
    this.onChangeCertificadonfe = this.onChangeCertificadonfe.bind (this);
    this.onChangeSerienfe = this.onChangeSerienfe.bind (this);
    this.onChangeSenhanfe = this.onChangeSenhanfe.bind (this);
    this.onChangeAmbientenfe = this.onChangeAmbientenfe.bind (this);
    this.onChangeHandler = this.onChangeHandler.bind (this);
  }

teste(){
  this.setState ({travar: true});
  this.setState ({message2: ''});
    this.setState ({message: ''});
  console.log(this.state.loja)
  ApiService.testenfe (this.state.loja)
  .then (response => {
    this.setState ({
      message2: response.data.info,
    });
    console.log (response.data);
    this.setState ({travar: false});
  })
  .catch (e => {
    console.log(e);
    var er = e.response;
    var ed = "";
    if (er !== undefined) {
      ed = e.response.data;
    }
    this.setState ({travar: false});
    this.setState ({message: 'Erro ao testar o certificado:'+(ed!==undefined?ed.message:'')});
    console.log (e);
  });

}

  onChangeHandler (){
    console.log (document.getElementById('file').files[0]);
    this.setState ({
      pfx: document.getElementById('file').files[0],
    });
    console.log (this.state.pfx);
  };

  componentDidMount () {
    ApiService.loja ()
      .then (response => {
        this.setState ({
          loja: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao atualizar loja'});
        console.log (e);
      });
    window.scrollTo (0, 0);
  }
  onChangeSenhanfe (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        senhanfe: nomex,
      },
    }));
  }

  onChangeAmbientenfe (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        ambientenfe: nomex,
      },
    }));
  }

  onChangeSerienfe (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        serienfe: nomex,
      },
    }));
  }

  onChangeCertificadonfe (e) {
    const nomex = e.target.value;

    this.setState (prevState => ({
      loja: {
        ...prevState.loja,
        certificadonfe: nomex,
      },
    }));
  }

  salvar () {
    this.setState ({message2: ''});
    this.setState ({message: ''});
    var data = {
      id: this.state.loja.id,
      certificadonfe: this.state.loja.certificadonfe,
      serienfe: this.state.loja.serienfe,
      senhanfe: this.state.loja.senhanfe,
      ambientenfe: this.state.loja.ambientenfe,
    };
    console.log (data);

    ApiService.atualizarlojanfe (data)
      .then (response => {
        if (response.status===200) {
          if (document.getElementById('file').files[0]!= null) {
            ApiService.uploadlojapfx (this.state.pfx)
              .then (response => {
                this.setState ({
                  loja: response.data,
                });
                console.log (response.data);
              })
              .catch (e => {
                this.setState ({message: 'Erro ao atualizar loja'});
                console.log (e);
              });
          }

          this.setState ({
            submitted: true,
          });
          console.log (response.data);
          window.scrollTo (0, 0);
          this.setState ({message2: 'nfe atualizada com sucesso'});
        }
      })
      .catch (e => {
        var er = e.response;
        var ed = "";
        if (er !== undefined) {
          ed = e.response.data;
        }
        this.setState ({message: 'Erro ao atualizar esitef:'+(ed!==undefined?ed.message:'')});
        console.log (e);
      });
  }

  render () {
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '640px'}}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Configuração NFe</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Configuração NFe
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  {this.state.message2 &&
                    <div className="alert alert-info alert-dismissible">
                      {this.state.message2}
                    </div>}

                  <div className="card-body">

                  <div className="form-group">
                      <label htmlFor="merchant_id">CNPJ:</label>
                      <input
                        type="number"
                        maxLength="18"
                        className="form-control"
                        id="merchant_id"
                        disabled={true}
                        placeholder="CNPJ"
                        required
                        value={this.state.loja.cnpj}
                        onChange={this.onChangeSerienfe}
                        name="merchant_id"
                        style={{width: '200px'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="merchant_id">UF:</label>
                      <input
                        type="text"
                        maxLength="18"
                        className="form-control"
                        id="merchant_id"
                        disabled={true}
                        placeholder="UF"
                        required
                        value={this.state.loja.uf}
                        onChange={this.onChangeSerienfe}
                        name="merchant_id"
                        style={{width: '200px'}}
                      />
                    </div>

                    <div className="form-group">
                      <label>Ambiente:</label>
                      <select
                        className="form-control select2"
                        style={{width: '200px'}}
                        value={this.state.loja.ambientenfe}
                        onChange={this.onChangeAmbientenfe}
                        name="status"
                      >
                        <option defaultValue value="1">Producao</option>
                        <option value="2">Homologacao</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="merchant_id">Serie NFe:</label>
                      <input
                        type="number"
                        maxLength="9"
                        className="form-control"
                        id="merchant_id"
                        placeholder="Serie da NFe"
                        required
                        value={this.state.loja.serienfe}
                        onChange={this.onChangeSerienfe}
                        name="merchant_id"
                        style={{width: '200px'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="merchant_key">Senha Certificado</label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        id="merchant_key"
                        placeholder="Senha Certificado"
                        required
                        value={this.state.loja.senhanfe}
                        onChange={this.onChangeSenhanfe}
                        name="merchant_key"
                        style={{width: '250px'}}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="merchant_key">Certificado NFe</label>
                      <input
                        type="text"
                        maxLength="100"
                        disabled={true}
                        className="form-control"
                        id="merchant_key"
                        placeholder="Certficado NFe"
                        required
                        value={this.state.loja.certificadonfe}
                        onChange={this.onChangeCertificadonfe}
                        name="merchant_key"
                        style={{width: '250px'}}
                      />
                      <br/>
                      <button className="btn btn-info" onClick={this.teste} disabled={this.state.travar}>
                        Teste NFe{this.state.travar?'...aguarde':''}
                      </button>
                    </div>
                    <br/>
                    <div className="form-group">
                      <label htmlFor="foto">
                        Arquivo pfx:&nbsp;
                      </label>
                      <input
                        type="file"
                        name="file"
                        id="file"
                        onChange={this.onChangeHandler}
                      />

                    </div>

                  </div>

                  <div className="card-footer">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <button className="btn btn-primary" onClick={this.salvar}>
                        Salvar
                      </button>
                      <NavLink to={'/'} className="nav-link">
                        <button
                          className="btn btn-danger"
                          onClick={this.voltar}
                        >
                          Voltar
                        </button>
                      </NavLink>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </section>

        </div>
        <Footer />
      </div>
    );
  }
}

export default EditNFE;
