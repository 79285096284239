import React, { Component } from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ApiService from '../../service/ApiService';
import { Redirect, NavLink } from 'react-router-dom';

class EditSubCategorias extends Component {
  constructor(props) {
    super(props);
    console.log('akiiiiaaa');
    this.state = {
      subcategoria: {
        id: null,
        nome: '',
        status: '',
        categoria: null,
        dataaltera: null,
        usuarioaltera: null,
      },
      categorias: [],
      message: null,
      submitted: false,
    };
    this.salvar = this.salvar.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeCategoria = this.onChangeCategoria.bind(this);
  }
  componentDidMount() {
    ApiService.subcategoria(this.props.match.params.id)
      .then(response => {
        this.setState({
          subcategoria: response.data,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao atualizar subcategoria' });
        console.log(e);
      });

    ApiService.categorias()
      .then(response => {
        this.setState({
          categorias: response.data,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao listar categorias' });
        console.log(e);
      });

    window.scrollTo(0, 0);
  }

  onChangeNome(e) {
    const nomex = e.target.value;

    this.setState(prevState => ({
      subcategoria: {
        ...prevState.subcategoria,
        nome: nomex,
      },
    }));
  }

  onChangeCategoria(e) {
    const categoriax = e.target.value;

    this.setState(prevState => ({
      subcategoria: {
        ...prevState.subcategoria,
        categoria: { id: categoriax },
      },
    }));
  }

  onChangeStatus(e) {
    const statusx = e.target.value;

    this.setState(prevState => ({
      subcategoria: {
        ...prevState.subcategoria,
        status: statusx,
      },
    }));
  }

  salvar() {
    var data = {
      id: this.state.subcategoria.id,
      nome: this.state.subcategoria.nome,
      status: this.state.subcategoria.status,
      categoria: { id: this.state.subcategoria.categoria.id }
    };

    ApiService.atualizarsubcategoria(data)
      .then(response => {
        this.setState({
          submitted: true,
        });
        console.log(response.data);
      })
      .catch(e => {
        this.setState({ message: 'Erro ao atualizar subcategoria' });
        console.log(e);
      });
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/subcategorias" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: '640px' }}>

          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Editar SubCategoria</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">
                      Editar SubCategoria
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="nomecategoria">
                        Código da SubCategoria:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.subcategoria.id}
                        name="id"
                        disabled
                        style={{ width: '12%' }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="nomecategoria">
                        Nome da SubCategoria:
                      </label>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="nomecategoria"
                        placeholder="Insira o nome da nova categoria"
                        required
                        value={this.state.subcategoria.nome}
                        onChange={this.onChangeNome}
                        name="nome"
                        style={{ width: '460px' }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Categoria:</label>
                      <select
                        required
                        className="form-control select2"
                        style={{ width: '460px' }}
                        value={this.state.subcategoria.categoria!==null ? this.state.subcategoria.categoria.id : ''}
                        onChange={this.onChangeCategoria}
                        name="categoria"
                      >

                        {this.state.categorias &&
                          this.state.categorias.map(categoria => (
                            <option value={categoria.id}>
                              {categoria.id + '- ' + categoria.nome}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label>Status:</label>
                      <select
                        className="form-control select2"
                        style={{ width: '12%' }}
                        value={this.state.subcategoria.status}
                        onChange={this.onChangeStatus}
                        name="status"
                      >
                        <option defaultValue value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </select>
                    </div>
                    
                  </div>

                  <div className="card-footer">
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <button className="btn btn-primary" onClick={this.salvar}>
                              Salvar
                    </button>                         

                            <NavLink to={'/subcategorias/'} className="nav-link">
                              <button className="btn btn-danger" onClick={this.voltar}>
                                Voltar
                      </button>
                            </NavLink>
                          </div>
                        
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default EditSubCategorias;
