import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import ApiService from '../../service/ApiService';

class ListaCategorias extends Component {
  constructor (props) {
    super (props);
    this.state = {
      categorias: [],
      message: null,
      campo: 'nome',
      valor: '',
    };
    this.listar = this.listar.bind (this);
    //
    this.pesquisar = this.pesquisar.bind (this);
    this.onChangeCampo = this.onChangeCampo.bind (this);
    this.onChangeValor = this.onChangeValor.bind (this);
  }

  componentDidMount () {
    this.listar ();
  }

  onChangeCampo (e) {
    this.setState ({
      campo: e.target.value,
    });
  }

  onChangeValor (e) {
    this.setState ({
      valor: e.target.value,
    });
  }

  pesquisar () {
    this.setState ({message: ''});
    if (this.state.valor.length > 0) {
      var data = {
        campo: this.state.campo,
        valor: this.state.valor,
      };
      console.log(data);
      ApiService.pcategorias (data)
        .then (response => {
          this.setState ({
            categorias: response.data,
          });
          console.log (response.data);
        })
        .catch (e => {
          this.setState ({message: 'Erro ao pesquisar categorias'});
          console.log (e);
        });
    }else{
      this.setState ({message: 'digite valor a ser pesquisado'});
    }
  }

  listar () {
    ApiService.categorias ()
      .then (response => {
        this.setState ({
          categorias: response.data,
        });
        console.log (response.data);
      })
      .catch (e => {
        this.setState ({message: 'Erro ao listar categorias'});
        console.log (e);
      });
  }

  render () {
    return (
      <div className="content-wrapper">

        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Categorias</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Início</a></li>
                  <li className="breadcrumb-item active">Categorias</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <td>
                        <NavLink to="/adicionar-categoria" className="nav-link">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                          >
                            Inserir Nova Categoria
                          </button>
                        </NavLink>
                      </td>
                    </div>

                    <div className="row">
                      <div className="col-lg-3">

                        <div className="form-group">
                          <label>Campo:</label>
                          <select
                            className="form-control select2"
                            value={this.state.campo}
                            onChange={this.onChangeCampo}
                            name="status"
                          >
                            <option defaultValue value="id">Codigo</option>
                            <option value="nome">Nome</option>
                          </select>
                        </div>

                      </div>
                      <div className="col-lg-6">

                        <div className="form-group">
                          <label>Valor:</label>
                          <input
                            type="text"
                            maxLength="50"
                            className="form-control"
                            id="nomecategoria"
                            placeholder="Valor a ser pesquisado"
                            required
                            value={this.state.valor}
                            onChange={this.onChangeValor}
                            name="nome"
                          />
                        </div>

                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label style={{visibility: 'hidden'}}>Valor:</label>
                          <button
                            onClick={this.pesquisar}
                            className="btn btn-block btn-primary"
                          >
                            Pesquisar
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>

                  {this.state.message &&
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>}

                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Codigo</th>
                          <th>Nome</th>
                          <th>Status</th>
                          <th>Qtd Produtos</th>
                          <th>Qtd Sub-Categorias</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>

                        {this.state.categorias &&
                          this.state.categorias.map (categoria => (
                            <tr key={categoria.id}>
                              <td>{categoria.id}</td>
                              <td>{categoria.nome}</td>
                              <td
                                style={{
                                  color: categoria.status !== 'Ativo'
                                    ? 'red'
                                    : 'green',
                                }}
                              >
                                {categoria.status}
                              </td>
                              <td>{categoria.qtdsubcategorias}</td>
                              <td>{categoria.qtdprodutos}</td>
                              <td>
                                <div className="btn-group">
                                  <NavLink
                                    to={`/editar-categoria/${categoria.id}`}
                                    className="nav-link"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-info"
                                    >
                                      Editar
                                    </button>
                                  </NavLink>
                                  <NavLink
                                    to={`/ver-categoria/${categoria.id}`}
                                    className="nav-link"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                    >
                                      Ver
                                    </button>
                                  </NavLink>

                                </div>
                              </td>
                            </tr>
                          ))}

                      </tbody>

                    </table>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </section>

      </div>
    );
  }
}

export default ListaCategorias;
