import React, {Component} from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import {withCookies} from 'react-cookie';

class Header extends Component {
  constructor (props) {
    super (props);
    this.state = {
      submitted2: false,
    };
  }

  componentDidMount () {
    window.scrollTo (0, 0);

    var user = this.props.cookies.get ('userd') || '';
    if (user === '') {
      this.setState ({submitted2: true});
    }
  }

  render () {

    const submitted2 = this.state.submitted2;
    if (submitted2 === true) {
      return <Redirect to="/login" />;
    }

    return (
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">

        <ul className="navbar-nav">
         {/*  <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button">
              <i className="fas fa-bars" />
            </a> 
          </li> */}
          <li className="nav-item d-none d-sm-inline-block">
            <NavLink to="/" className="nav-link">Início</NavLink>
          </li>
        </ul>

        <form className="form-inline ml-3">
          <div className="input-group input-group-sm">
            <input
              className="form-control form-control-navbar"
              type="search"
              placeholder="Pesquisar"
              aria-label="Search"
            />
            <div className="input-group-append">
              <button className="btn btn-navbar" type="submit">
                <i className="fas fa-search" />
              </button>
            </div>
          </div>
        </form>
        <br />

        <ul className="navbar-nav ml-auto">

          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-bell" />
              <span className="badge badge-warning navbar-badge">15</span>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-item dropdown-header">15 Notificações</span>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-envelope mr-2" />4 novas mensagens
                <span className="float-right text-muted text-sm">3 min</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-file mr-2" />3 novos avisos
                <span className="float-right text-muted text-sm">2 dias</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item dropdown-footer">
                Ver Todas as Notificações
              </a>
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="control-sidebar"
              data-slide="true"
              href="#"
              role="button"
            >
              <i className="fas fa-th-large" />
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default withCookies(Header);
