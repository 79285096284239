import React, {Component} from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import Footer from '../../componentes/Footer';
import ListaFretes from './ListaFretes';



class Fretes extends Component {
  componentDidMount () {
    console.log('ff');
    window.scrollTo (0, 0);
  }

  render () {
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <ListaFretes />
        <Footer />
      </div>
    );
  }
}

export default Fretes;
