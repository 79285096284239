import React, { Component } from "react";
import Header from "../../componentes/Header";
import Sidebar from "../../componentes/Sidebar";
import Footer from "../../componentes/Footer";
import ApiService from "../../service/ApiService";
import { Redirect, NavLink } from "react-router-dom";
import ImageUploader from "react-images-upload";

const checkboxes = [
  {
    name: "branco",
    key: "branco",
    label: "Branco",
    cor: "#EEF2F8",
  },
  {
    name: "perola",
    key: "perola",
    label: "Pérola",
    cor: "#E5D2B3",
  },
  {
    name: "taupe",
    key: "taupe",
    label: "Taupe",
    cor: "#D2B179",
  },
  {
    name: "caramelo",
    key: "caramelo",
    label: "Caramelo",
    cor: "#FFB74D",
  },

  {
    name: "whisky",
    key: "whisky",
    label: "Whisky",
    cor: "#8b4513",
  },
  {
    name: "cafe-fosco",
    key: "cafe-fosco",
    label: "Cafe fosco",
    cor: "#4d2719",
  },
  {
    name: "cafe-brilho",
    key: "cafe-brilho",
    label: "Cafe brilho",
    cor: "#86442d",
  },
  {
    name: "chumbo",
    key: "chumbo",
    label: "Chumbo",
    cor: "#607d8b",
  },
  {
    name: "preto-brilho",
    key: "preto-brilho",
    label: "Preto brilho",
    cor: "#4d4d4d",
  },
  {
    name: "preto-fosco",
    key: "preto-fosco",
    label: "Preto fosco",
    cor: "#333333",
  },
];

class AddProdutos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      produtos: [],
      segmento: "0",
      cores: new Map(),
      produtosi: [],
      produtoi: "",
      nome: "",
      status: "Ativo",
      subcategoria: null,
      laboratorio: "",
      dosagem: "",
      introducao: "",
      Caracteristica: "",
      preco: 0.0,
      precoi: 0.0,
      desconto: 0.0,
      promocao: 0.0,
      quantidade: "",
      message: null,
      submitted: false,
      subcategorias: [],
      categoria: {},
      pictures: [],
      ean: "",
      produtofk: null,
      visible: false,
      tipo: "Normal",
      semcb: true,
      prazoentrega: "",
    };
    this.salvar = this.salvar.bind(this);
    this.onChangecores = this.onChangecores.bind(this);
    this.onChangePrazoentrega = this.onChangePrazoentrega.bind(this);
    this.onChangeEan = this.onChangeEan.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeTipo = this.onChangeTipo.bind(this);
    this.onChangeCategoria = this.onChangeCategoria.bind(this);
    this.onChangeProdutoi = this.onChangeProdutoi.bind(this);
    this.onChangeLaboratorio = this.onChangeLaboratorio.bind(this);
    this.onChangeDosagem = this.onChangeDosagem.bind(this);
    this.onChangeIntroducao = this.onChangeIntroducao.bind(this);
    this.onChangeCaracteristica = this.onChangeCaracteristica.bind(this);
    this.onChangePreco = this.onChangePreco.bind(this);
    this.onChangePrecoi = this.onChangePrecoi.bind(this);
    this.onChangeDesconto = this.onChangeDesconto.bind(this);
    this.onChangePromocao = this.onChangePromocao.bind(this);
    this.onChangeQuantidade = this.onChangeQuantidade.bind(this);
    this.onChangevalormontagem= this.onChangevalormontagem.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.adicionar = this.adicionar.bind(this);
    this.excluir = this.excluir.bind(this);
  }

  excluir(produto) {
    this.state.produtosi = this.state.produtosi.filter(
      (it) => it.produto_fk !== produto.produto_fk
    );
    console.log(this.state.produtosi);
    this.setState({ produtosi: this.state.produtosi });

    var total = this.state.produtosi.reduce(
      (prev, next) => prev + Number(next.preco),
      0
    );

    this.setState({ preco: Number(total).toFixed(2) });
  }

  adicionar() {
    var ok = true;

    if (this.state.precoi <= 0) {
      ok = false;
      alert("Preço Inválido!");
    }
    if (this.state.produtoi === "") {
      ok = false;
      alert("Produto Inválido!");
    }

    if (ok) {
      var h = this.state.produtoi.split("##");
      var p = {};
      p.produto_fk = h[0];
      p.nome = h[1];
      p.preco = this.state.precoi;
      if (ok) {
        this.state.produtosi.push(p);
        var total = this.state.produtosi.reduce(
          (prev, next) => prev + Number(next.preco),
          0
        );

        this.setState({ preco: Number(total).toFixed(2) });
        this.setState({ precoi: 0 });
        this.setState({ produtoi: "" });
      }
    }
  }

  async onDrop(pictureFiles, pictureDataURLs) {
    await this.setState({
      pictures: pictureFiles,
    });
    console.log(this.state.pictures);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    ApiService.subcategorias()
      .then((response) => {
        this.setState({
          subcategorias: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao Listar SubCategorias!" });
        console.log(e);
      });

    ApiService.segmento()
      .then((response) => {
        this.setState({
          segmento: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao consultar segmento" });
        console.log(e);
      });
  }

  onChangeDesconto(e) {
    this.setState({
      desconto: e.target.value,
    });
  }

  onChangeQuantidade(e) {
    this.setState({
      quantidade: e.target.value,
    });
  }

  onChangePromocao(e) {
    this.setState({
      promocao: e.target.value,
    });
  }

  onChangeLaboratorio(e) {
    this.setState({
      laboratorio: e.target.value,
    });
  }

  onChangePreco(e) {
    this.setState({
      preco: e.target.value,
    });
  }

  onChangePrecoi(e) {
    this.setState({
      precoi: e.target.value,
    });
  }

  onChangeDosagem(e) {
    this.setState({
      dosagem: e.target.value,
    });
  }

  onChangeIntroducao(e) {
    this.setState({
      introducao: e.target.value,
    });
  }

  onChangeCaracteristica(e) {
    this.setState({
      caracteristica: e.target.value,
    });
  }

  onChangeNome(e) {
    this.setState({
      nome: e.target.value,
    });
  }

  onChangeTipo(e) {
    this.setState({
      tipo: e.target.value,
    });
    if (e.target.value === "Normal") {
      this.setState({ semcb: true });
      this.setState({ visible: false });
      this.setState({ ean: "" });
      this.setState({ produtofk: null });
    } else {
      this.setState({ semcb: false });
      this.setState({ visible: true });
      ApiService.produtos()
        .then((response) => {
          this.setState({
            produtos: response.data,
          });
          console.log(response.data);
        })
        .catch((e) => {
          this.setState({ message: "Erro ao Listar Produtos!" });
          console.log(e);
        });
    }
  }

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  handleKeyDown() {
    console.log(this.state.ean);
    if (this.state.ean !== undefined) {
      if (this.state.ean !== null) {
        if (this.state.ean.length >= 13) {
          ApiService.ean(this.state.ean)
            .then((response) => {
              if (response.status === 200) {
                this.setState({
                  produtofk: response.data.idProd,
                  nome: this.capitalize(
                    response.data.descricaoProd.toLowerCase()
                  ),
                  laboratorio: this.capitalize(
                    response.data.fabricante.nomeFab.toLowerCase()
                  ),
                  visible: true,
                });
                console.log(response.data);
              } else {
                this.setState({
                  produtofk: null,
                  visible: false,
                });
              }
            })
            .catch((e) => {
              this.setState({
                produtofk: null,
                visible: false,
              });
              this.setState({ message: "Erro ao Consulta EAN!" });
              console.log(e.toJSON()); // Return error object
            });
        } else {
          this.setState({
            produtofk: null,
            visible: false,
          });
        }
      }
    }
  }

  onChangecores(e) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      cores: prevState.cores.set(item, isChecked),
    }));
  }

  onChangevalormontagem(e) {
    this.setState({
      valormontagem: e.target.value,
    });
  }

  onChangePrazoentrega(e) {
    this.setState({
      prazoentrega: e.target.value,
    });
  }

  onChangeEan(e) {
    this.setState({
      ean: e.target.value,
    });
  }

  onChangeStatus(e) {
    this.setState({
      status: e.target.value,
    });
  }

  onChangeCategoria(e) {
    this.setState({
      subcategoria: e.target.value,
    });
  }

  onChangeProdutoi(e) {
    this.setState({
      produtoi: e.target.value,
    });
  }

  salvar() {
    if (this.state.pictures === undefined) {
      this.state.pictures = [];
    }
    var ok = true;
    if (this.state.pictures.length > 6) {
      ok = false;
      alert("Máximo de 6 Fotos!");
    }

    if (this.state.preco === 0) {
      ok = false;
      alert("Preço Inválido!");
    }

    if (this.state.preco < this.state.desconto) {
      ok = false;
      alert("Desconto Não Pode Ser Maior Que o Preço!");
    }

    if (this.state.pictures.length === 0) {
      ok = false;
      alert("Selecione Pelo Menos Uma Foto!");
    }

    if (this.state.tipo !== "Kit") {
      if (this.state.produtofk === null) {
        ok = false;
        alert("Código de Barras Não Encontrado!");
      }
    }

    if (this.state.tipo === "Kit") {
      if (this.state.produtosi.length < 2) {
        ok = false;
        alert("Adicione Pelo Menos Dois Itens Ao Kit!");
      }
    }

    if (ok) {
      var data = {
        nome: this.state.nome,
        status: this.state.status,
        subcategoria: { id: this.state.subcategoria },
        quantidade: this.state.quantidade,
        preco: this.state.preco,
        desconto: this.state.desconto,
        promocao: this.state.promocao,
        laboratorio: this.state.laboratorio,
        dosagem: this.state.dosagem,
        introducao: this.state.introducao,
        caracteristica: this.state.caracteristica,
        ean: this.state.ean,
        produtofk: this.state.produtofk,
        tipo: this.state.tipo,
        produtosi: this.state.produtosi,
        prazoentrega: this.state.prazoentrega,
        valormontagem:this.state.valormontagem
      };
      var cores = "";
      console.log(this.state.cores);
      for (const [key, value] of this.state.cores.entries()) {
        if (value == true) {
          cores += key + ",";
        }
      }
      data.cores = cores;
      console.log(data);

      ApiService.salvarproduto(data)
        .then((response) => {
          if (response.status === 200) {
            var padrao = true;
            for (let i = 0; i < this.state.pictures.length; i++) {
              (async () => {
                await ApiService.uploadproduto(
                  this.state.pictures[i],
                  response.data.id,
                  response.data.id + "-" + i + ".jpg",
                  padrao
                );
              })();
              padrao = false;
            }
            this.setState({
              submitted: true,
            });
            console.log(response.data);
          }
        })
        .catch((e) => {
          this.setState({ message: "Erro Ao Cadastrar Produto!" });
          console.log(e);
        });
    }
  }

  render() {
    const submitted = this.state.submitted;
    if (submitted === true) {
      return <Redirect to="/produtos" />;
    }
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{ minHeight: "640px" }}>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Adicionar Produto</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Adicionar Produto
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  {this.state.message && (
                    <div className="alert alert-danger alert-dismissible">
                      {this.state.message}
                    </div>
                  )}

                  <div className="card-body">
                    <div className="form-group">
                      <label>Tipo:</label>
                      <select
                        className="form-control select2"
                        style={{ width: "12%" }}
                        value={this.state.tipo}
                        onChange={this.onChangeTipo}
                        name="tipo"
                      >
                        <option defaultValue value="Normal">
                          Normal
                        </option>
                        <option value="Kit">Kit</option>
                      </select>
                    </div>

                    {this.state.semcb === true && (
                      <div className="form-group">
                        <label htmlFor="ean">
                          Código de Barras:{" "}
                          {this.state.produtofk !== null
                            ? " - Produto ERP:" + this.state.produtofk
                            : ""}
                        </label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="number"
                            maxLength="14"
                            className="form-control"
                            id="ean"
                            placeholder="Insira o Código De Barras Do Produto"
                            required
                            value={this.state.ean}
                            onChange={this.onChangeEan}
                            name="ean"
                            style={{ width: "400px" }}
                          />
                          &nbsp;&nbsp;
                          <button
                            className="btn btn-primary"
                            onClick={this.handleKeyDown}
                          >
                            Verificar
                          </button>
                        </div>
                      </div>
                    )}

                    {this.state.visible === true && (
                      <div>
                        <div className="form-group">
                          <label htmlFor="nomecategoria">
                            Nome do Produto:
                          </label>
                          <input
                            type="text"
                            maxLength="50"
                            className="form-control"
                            id="nomecategoria"
                            placeholder="Insira o nome do produto"
                            required
                            value={this.state.nome}
                            onChange={this.onChangeNome}
                            name="nome"
                            style={{ width: "400px" }}
                          />
                        </div>

                        <div className="form-group">
                          <label>SubCategoria:</label>
                          <select
                            required
                            className="form-control select2"
                            style={{ width: "400px" }}
                            value={this.state.subcategoria}
                            onChange={this.onChangeCategoria}
                            name="subcategoria"
                          >
                            <option defaultValue value="">
                              selecione
                            </option>
                            {this.state.subcategorias &&
                              this.state.subcategorias.map((subcategoria) => (
                                <option value={subcategoria.id}>
                                  {subcategoria.id +
                                    "- " +
                                    subcategoria.nome +
                                    " - Categoria: " +
                                    subcategoria.categoria.nome}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="form-group">
                          <label htmlFor="laboratorio">Fabricante:</label>
                          <input
                            type="text"
                            maxLength="100"
                            className="form-control"
                            id="laboratorio"
                            placeholder="Insira o  laboratorio"
                            value={this.state.laboratorio}
                            onChange={this.onChangeLaboratorio}
                            name="laboratorio"
                            style={{ width: "400px" }}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="dosagem">Dosagem:</label>
                          <input
                            type="text"
                            maxLength="100"
                            className="form-control"
                            id="dosagem"
                            placeholder="Insira a dosagem"
                            value={this.state.dosagem}
                            onChange={this.onChangeDosagem}
                            name="dosagem"
                            style={{ width: "12%" }}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="quantidade">Quantidade:</label>
                          <input
                            type="text"
                            maxLength="100"
                            className="form-control"
                            id="quantidade"
                            placeholder="Insira a quantidade "
                            value={this.state.quantidade}
                            onChange={this.onChangeQuantidade}
                            name="quantidade"
                            style={{ width: "12%" }}
                          />
                        </div>

                        {this.state.tipo === "Kit" && (
                          <div className="form-group">
                            <label>Produtos do Kit:</label>
                            <select
                              required
                              className="form-control select2"
                              value={this.state.produtoi}
                              onChange={this.onChangeProdutoi}
                              name="produtoi"
                              style={{ width: "400px" }}
                            >
                              <option defaultValue value="">
                                selecione
                              </option>
                              {this.state.produtos &&
                                this.state.produtos.map((produto) => (
                                  <option
                                    value={produto.id + "##" + produto.nome}
                                  >
                                    {produto.id +
                                      "- " +
                                      produto.nome +
                                      " - R$" +
                                      Number(produto.preco).toFixed(2)}
                                  </option>
                                ))}
                            </select>
                            &nbsp;
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="number"
                                min="0.00"
                                max="10000.00"
                                step="0.01"
                                className="form-control"
                                id="dosagem"
                                placeholder="Preco Item"
                                value={this.state.precoi}
                                onChange={this.onChangePrecoi}
                                name="preco"
                                style={{ width: "20%" }}
                              />
                              &nbsp;
                              <button
                                className="btn btn-primary"
                                onClick={() => this.adicionar()}
                              >
                                Adicionar
                              </button>
                            </div>
                            &nbsp;
                            <table
                              id="example1"
                              className="table table-bordered table-striped"
                            >
                              <thead>
                                <tr>
                                  <th>Codigo</th>
                                  <th>Nome</th>
                                  <th>Preço</th>
                                  <th />
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.produtosi &&
                                  this.state.produtosi.map((produto) => (
                                    <tr key={produto.produto_fk}>
                                      <td>{produto.produto_fk}</td>
                                      <td>{produto.nome}</td>
                                      <td>
                                        {Number(produto.preco).toFixed(2)}
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-danger"
                                          onClick={() => this.excluir(produto)}
                                        >
                                          Excluir
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}

                        <div className="form-group">
                          <label htmlFor="preco">Preço</label>
                          <input
                            type="number"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            className="form-control"
                            id="dosagem"
                            disabled={this.state.tipo === "Kit"}
                            placeholder="Insira o preco "
                            value={this.state.preco}
                            onChange={this.onChangePreco}
                            name="preco"
                            style={{ width: "20%" }}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="desconto">Valor Desconto(R$):</label>
                          <input
                            type="number"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            disabled={this.state.tipo === "Kit"}
                            className="form-control"
                            id="desconto"
                            placeholder="Insira o desconto "
                            value={this.state.desconto}
                            onChange={this.onChangeDesconto}
                            name="desconto"
                            style={{ width: "20%" }}
                          />
                        </div>
                        <hr />
                        {this.state.segmento == "11" && (
                          <div className="form-group">
                            <div>
                              {/*
                              <label htmlFor="">
                                Opções de Lugares e Tamanhos:
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <div className="form-group">
                                  <label htmlFor="valor">Lugares:</label>
                                  <input
                                    type="number"
                                    required
                                    placeholder="Insira o Valor "
                                    name="Valor"
                                    style={{ width: "130px" }}
                                    className="form-control"
                                  />
                                </div>
                                &nbsp;
                                <div className="form-group">
                                  <label htmlFor="valor">Comprimento:</label>
                                  <input
                                    type="number"
                                    required
                                    placeholder="Insira o Valor "
                                    name="Valor"
                                    style={{ width: "130px" }}
                                    className="form-control"
                                  />
                                </div>
                                &nbsp;
                                <div className="form-group">
                                  <label htmlFor="valor">Altura:</label>
                                  <input
                                    type="number"
                                    required
                                    placeholder="Insira o Valor "
                                    name="Valor"
                                    style={{ width: "130px" }}
                                    className="form-control"
                                  />
                                </div>
                                &nbsp;
                                <div className="form-group">
                                  <label htmlFor="valor">Profundidade:</label>
                                  <input
                                    type="number"
                                    required
                                    placeholder="Insira o Valor "
                                    name="Valor"
                                    style={{ width: "130px" }}
                                    className="form-control"
                                  />
                                </div>
                                &nbsp;
                                <div className="form-group">
                                  <label htmlFor="valor">Preço:</label>
                                  <input
                                    type="number"
                                    required
                                    placeholder="Insira o Valor "
                                    name="Valor"
                                    style={{ width: "130px" }}
                                    className="form-control"
                                  />
                                </div>
                                &nbsp;
                                <div className="form-group">
                                  <button
                                    style={{ marginTop: "35%" }}
                                    className="btn btn-primary form-control"
                                  >
                                    Adicionar Opção
                                  </button>
                                  &nbsp;
                                </div>
                              </div>
                              <div>
                                <label /> Poltrona 1,05C x 0,85A x 0,85P
                                -R$900,00
                                <br />
                                <label /> 2 Lugares 1,60C x 0,85A x 0,85P
                                -R$300,00
                                <br />
                                <label /> 3 Lugares 2,10C x 0,85A x 0,85P
                                <br />
                                <label /> 4 Lugares 2,50C x 0,85A x 0,85P
                                +R$959,00
                                <br />
                                <label /> 5 Lugares 3,25C x 0,85A x 0,85P
                                +R$1.600,00
                              </div>

                              <hr />

                              <label htmlFor="">
                                Opções de Comprar Também:
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <div className="form-group">
                                  <label htmlFor="valor">Lugares:</label>
                                  <input
                                    type="number"
                                    required
                                    placeholder="Insira o Valor "
                                    name="Valor"
                                    style={{ width: "130px" }}
                                    className="form-control"
                                  />
                                </div>
                                &nbsp;
                                <div className="form-group">
                                  <label htmlFor="valor">Comprimento:</label>
                                  <input
                                    type="number"
                                    required
                                    placeholder="Insira o Valor "
                                    name="Valor"
                                    style={{ width: "130px" }}
                                    className="form-control"
                                  />
                                </div>
                                &nbsp;
                                <div className="form-group">
                                  <label htmlFor="valor">Altura:</label>
                                  <input
                                    type="number"
                                    required
                                    placeholder="Insira o Valor "
                                    name="Valor"
                                    style={{ width: "130px" }}
                                    className="form-control"
                                  />
                                </div>
                                &nbsp;
                                <div className="form-group">
                                  <label htmlFor="valor">Profundidade:</label>
                                  <input
                                    type="number"
                                    required
                                    placeholder="Insira o Valor "
                                    name="Valor"
                                    style={{ width: "130px" }}
                                    className="form-control"
                                  />
                                </div>
                                &nbsp;
                                <div className="form-group">
                                  <label htmlFor="valor">Preço:</label>
                                  <input
                                    type="number"
                                    required
                                    placeholder="Insira o Valor "
                                    name="Valor"
                                    style={{ width: "130px" }}
                                    className="form-control"
                                  />
                                </div>
                                &nbsp;
                                <div className="form-group">
                                  <button
                                    style={{ marginTop: "35%" }}
                                    className="btn btn-primary form-control"
                                  >
                                    Adicionar Opção
                                  </button>
                                  &nbsp;
                                </div>
                              </div>
                              <div>
                                <label /> Poltrona 1,05C x 0,85A x 0,85P
                                +R$1.690,00
                                <br />
                                <label /> 2 Lugares 1,60C x 0,85A x 0,85P
                                +R$2.290,00
                                <br />
                                <label /> 3 Lugares 2,10C x 0,85A x 0,85P
                                +R$2.590,00
                                <br />
                                <label /> 4 Lugares 2,50C x 0,85A x 0,85P
                                +R$3.549,00
                                <br />
                                <label /> 5 Lugares 3,25C x 0,85A x 0,85P
                                +R$4.190,00
                              </div>
                              */}
                              <hr />

                              <label htmlFor="valormontagem">Montagem de Fábrica:</label>
                              <input
                            type="number"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            disabled={this.state.tipo === "Kit"}
                            className="form-control"
                            id="valormontagem"
                            placeholder="Insira o valormontagem "
                            value={this.state.valormontagem}
                            onChange={this.onChangevalormontagem}
                            name="valormontagem"
                            style={{ width: "20%" }}
                          />
                            </div>
                            <br />
                            <label htmlFor="dosagem">Cores do Produto:</label>
                            <div class="row">
                              {checkboxes.map((item) => (
                                <div class="col-md-1">
                                  <div class="custom-control custom-checkbox image-checkbox">
                                    <label key={item.key}>
                                      <input
                                        type="checkbox"
                                        name={item.name}
                                        checked={this.state.cores.get(
                                          item.name
                                        )}
                                        onChange={this.onChangecores}
                                      />
                                      <div
                                        style={{
                                          height: "70px",
                                          width: "70px",
                                          backgroundColor: item.cor,
                                        }}
                                      />

                                      {item.label}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        <div className="form-group">
                          <label htmlFor="prazo">Prazo Entrega:</label>
                          <input
                            type="text"
                            maxLength="20"
                            className="form-control"
                            id="prazo"
                            value={this.state.prazoentrega}
                            onChange={this.onChangePrazoentrega}
                            name="prazo"
                            style={{ width: "70%" }}
                          />
                        </div>

                        <div className="form-group">
                          <label>Status:</label>
                          <select
                            className="form-control select2"
                            style={{ width: "12%" }}
                            value={this.state.status}
                            onChange={this.onChangeStatus}
                            name="status"
                          >
                            <option defaultValue value="Ativo">
                              Ativo
                            </option>
                            <option value="Inativo">Inativo</option>
                          </select>
                        </div>

                        <div className="form-group">
                          <label htmlFor="introducao">Introdução:</label>
                          <textarea
                            type="text"
                            maxLength="500"
                            rows={2}
                            cols={5}
                            className="form-control"
                            id="introducao"
                            placeholder="Insira a introducao"
                            value={this.state.introducao}
                            onChange={this.onChangeIntroducao}
                            name="introducao"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="dosagem">Característica:</label>
                          <textarea
                            type="text"
                            maxLength="500"
                            rows={2}
                            cols={5}
                            className="form-control"
                            id="caracteristica"
                            placeholder="Insira a caracteristica"
                            value={this.state.caracteristica}
                            onChange={this.onChangeCaracteristica}
                            name="caracteristica"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="foto">Foto (450 x 450):</label>
                          <ImageUploader
                            withIcon={true}
                            id="foto"
                            label="Apenas Fotos .jpg"
                            buttonText="Selecione Até 6 Fotos .jpg"
                            onChange={this.onDrop}
                            imgExtension={[".jpg"]}
                            maxFileSize={102400}
                            fileSizeError="Tamanho Máximo Por Foto 100kb"
                            fileTypeError="Apenas Arquivos .jpg"
                            withPreview={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="card-footer">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button className="btn btn-primary" onClick={this.salvar}>
                        Adicionar
                      </button>

                      <NavLink to={"/produtos/"} className="nav-link">
                        <button
                          className="btn btn-danger"
                          onClick={this.voltar}
                        >
                          Voltar
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default AddProdutos;
