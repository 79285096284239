import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ApiService from "../service/ApiService";
import Moment from "react-moment";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pedidos: [],
      eventos: [],
      transacoes: [],
      transacoesbin: [],
      transacoescielo: [],
      transacoesrede: [],
      transacoesgetnet: [],
      message: null,
    };
    this.listar = this.listar.bind(this);
  }

  componentDidMount() {
    this.listar();
  }

  listar() {
    ApiService.ultimos()
      .then((response) => {
        this.setState({
          pedidos: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao listar pedidos" });
        console.log(e);
      });

    ApiService.eventos()
      .then((response) => {
        this.setState({
          eventos: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao listar eventos" });
        console.log(e);
      });

    ApiService.transacoesultimos()
      .then((response) => {
        this.setState({
          transacoes: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao listar transacoes" });
        console.log(e);
      });

    ApiService.transacoesultimosbin()
      .then((response) => {
        this.setState({
          transacoesbin: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao listar transacoes" });
        console.log(e);
      });

    ApiService.transacoesultimoscielo()
      .then((response) => {
        this.setState({
          transacoescielo: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao listar transacoes" });
        console.log(e);
      });

      ApiService.transacoesultimosrede()
      .then((response) => {
        this.setState({
          transacoesrede: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao listar transacoes" });
        console.log(e);
      });

      ApiService.transacoesultimosgetnet()
      .then((response) => {
        this.setState({
          transacoesgetnet: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        this.setState({ message: "Erro ao listar transacoes" });
        console.log(e);
      });
  }

  badge(status) {
    switch (status) {
      case "Enviado":
        return "badge badge-success";
      case "Pago":
        return "badge badge-success";
      case "Pendente":
        return "badge badge-info";
      case "NFe Gerada":
        return "badge badge-primary";
      case "Cancelado":
        return "badge badge-danger";
      case "Processando":
        return "badge badge-warning";
      case "Danfe Gerada":
        return "badge badge-primary";
      case "Em Transito":
        return "badge badge-success";
      default:
        return "";
    }
  }

  render() {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid" />
        </div>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header border-transparent">
                    <h3 className="card-title" style={{ fontWeight: "600" }}>
                      ÚLTIMOS PEDIDOS
                    </h3>

                    <div className="card-tools" />
                  </div>

                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table className="table m-0">
                        <thead>
                          <tr>
                            <th>Nº Pedido</th>
                            <th>Data</th>
                            <th>SubTotal</th>
                            <th>Total</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.pedidos &&
                            this.state.pedidos.map((pedido) => (
                              <tr key={pedido.id}>
                                <td> {pedido.numeropedido}</td>
                                <td>
                                  <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {pedido.datapedido}
                                  </Moment>
                                </td>
                                <td>
                                  <div
                                    className="sparkbar"
                                    data-color="#00a65a"
                                    data-height="20"
                                  >
                                    R${Number(pedido.subtotal).toFixed(2)}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="sparkbar"
                                    data-color="#00a65a"
                                    data-height="20"
                                  >
                                    R${Number(pedido.total).toFixed(2)}
                                  </div>
                                </td>
                                <td>
                                  <span
                                    className={this.badge(pedido.status)}
                                    style={{ width: "100px" }}
                                  >
                                    {pedido.status == "Processando"
                                      ? "Pendente"
                                      : pedido.status}
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="card-footer clearfix">
                    <NavLink
                      to={"/pedidos/"}
                      className="btn btn-sm btn-info float-left"
                    >
                      Ver Todos
                    </NavLink>
                  </div>
                </div>
              </div>

              {this.state.transacoesgetnet.length>0 && (
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header border-transparent">
                      <h3 className="card-title" style={{ fontWeight: "600" }}>
                        ÚLTIMAS TRANSAÇÕES GetNet
                      </h3>

                      <div className="card-tools" />
                    </div>

                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table m-0">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Nº Pedido</th>
                              <th>Data</th>
                              <th>Status</th>
                              <th>Message</th>
                              <th>Valor</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.transacoesgetnet &&
                              this.state.transacoesgetnet.map((transacao) => (
                                <tr key={transacao.id}>
                                  <td> {transacao.paymentid}</td>
                                  <td> {transacao.pedido}</td>
                                  <td>
                                    <Moment format="DD/MM/YYYY HH:mm:ss">
                                      {transacao.dataaltera}
                                    </Moment>
                                  </td>
                                  <td>
                                    <span>{transacao.status}</span>
                                  </td>
                                  <td>
                                    {transacao.returncode}-{" "}
                                    {transacao.returnmessage}
                                  </td>
                                  <td>R$ {transacao.valor.toFixed(2)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="card-footer clearfix">
                      <NavLink
                        to={"/transacoes-getnet/"}
                        className="btn btn-sm btn-info float-left"
                      >
                        Ver Todos
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}

              {this.state.transacoesrede.length>0 && (
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header border-transparent">
                      <h3 className="card-title" style={{ fontWeight: "600" }}>
                        ÚLTIMAS TRANSAÇÕES Rede
                      </h3>

                      <div className="card-tools" />
                    </div>

                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table m-0">
                          <thead>
                            <tr>
                              <th>TID</th>
                              <th>Nº Pedido</th>
                              <th>Data</th>
                              <th>Status</th>
                              <th>Message</th>
                              <th>Valor</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.transacoesrede &&
                              this.state.transacoesrede.map((transacao) => (
                                <tr key={transacao.id}>
                                  <td> {transacao.tid}</td>
                                  <td> {transacao.pedido}</td>
                                  <td>
                                    <Moment format="DD/MM/YYYY HH:mm:ss">
                                      {transacao.dataaltera}
                                    </Moment>
                                  </td>
                                  <td>
                                    <span>{transacao.status}</span>
                                  </td>
                                  <td>
                                    {transacao.returncode}-{" "}
                                    {transacao.returnmessage}
                                  </td>
                                  <td>R$ {transacao.valor.toFixed(2)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="card-footer clearfix">
                      <NavLink
                        to={"/transacoes-rede/"}
                        className="btn btn-sm btn-info float-left"
                      >
                        Ver Todos
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}



              {this.state.transacoescielo.length>0 && (
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header border-transparent">
                      <h3 className="card-title" style={{ fontWeight: "600" }}>
                        ÚLTIMAS TRANSAÇÕES Cielo
                      </h3>

                      <div className="card-tools" />
                    </div>

                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table m-0">
                          <thead>
                            <tr>
                              <th>Transation Id</th>
                              <th>Nº Pedido</th>
                              <th>Data</th>
                              <th>Status</th>
                              <th>Message</th>
                              <th>Valor</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.transacoescielo &&
                              this.state.transacoescielo.map((transacao) => (
                                <tr key={transacao.id}>
                                  <td> {transacao.paymentid}</td>
                                  <td> {transacao.pedido}</td>
                                  <td>
                                    <Moment format="DD/MM/YYYY HH:mm:ss">
                                      {transacao.dataaltera}
                                    </Moment>
                                  </td>
                                  <td>
                                    <span>{transacao.status}</span>
                                  </td>
                                  <td>
                                    {transacao.returncode}-{" "}
                                    {transacao.returnmessage}
                                  </td>
                                  <td>R$ {transacao.valor.toFixed(2)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="card-footer clearfix">
                      <NavLink
                        to={"/transacoes-cielo/"}
                        className="btn btn-sm btn-info float-left"
                      >
                        Ver Todos
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}

              {this.state.transacoesbin.length>0 && (
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header border-transparent">
                      <h3 className="card-title" style={{ fontWeight: "600" }}>
                        ÚLTIMAS TRANSAÇÕES Bin
                      </h3>

                      <div className="card-tools" />
                    </div>

                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table m-0">
                          <thead>
                            <tr>
                              <th>Transation Id</th>
                              <th>Nº Pedido</th>
                              <th>Data</th>
                              <th>Status</th>
                              <th>Message</th>
                              <th>Valor</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.transacoesbin &&
                              this.state.transacoesbin.map((transacao) => (
                                <tr key={transacao.id}>
                                  <td> {transacao.transactionid}</td>
                                  <td> {transacao.pedido}</td>
                                  <td>
                                    <Moment format="DD/MM/YYYY HH:mm:ss">
                                      {transacao.dataaltera}
                                    </Moment>
                                  </td>
                                  <td>
                                    <span>{transacao.status}</span>
                                  </td>
                                  <td> {transacao.responsemessage}</td>
                                  <td>R$ {transacao.valor.toFixed(2)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="card-footer clearfix">
                      <NavLink
                        to={"/transacoes-bin/"}
                        className="btn btn-sm btn-info float-left"
                      >
                        Ver Todos
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}

              {this.state.transacoes.length>0 && (
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header border-transparent">
                      <h3 className="card-title" style={{ fontWeight: "600" }}>
                        ÚLTIMAS TRANSAÇÕES Esitef
                      </h3>

                      <div className="card-tools" />
                    </div>

                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table m-0">
                          <thead>
                            <tr>
                              <th>USN</th>
                              <th>Nº Pedido</th>
                              <th>Data</th>
                              <th>Status</th>
                              <th>Code</th>
                              <th>Valor</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.transacoes &&
                              this.state.transacoes.map((transacao) => (
                                <tr key={transacao.id}>
                                  <td> {transacao.merchant_usn}</td>
                                  <td> {transacao.order_id}</td>
                                  <td>
                                    <Moment format="DD/MM/YYYY HH:mm:ss">
                                      {transacao.dataaltera}
                                    </Moment>
                                  </td>
                                  <td>
                                    <span>
                                      {transacao.status}- {transacao.rstatus}
                                    </span>
                                  </td>
                                  <td>
                                    {" "}
                                    {transacao.code}- {transacao.rcode}
                                  </td>

                                  <td>
                                    R${" "}
                                    {(Number(transacao.amount) / 100).toFixed(
                                      2
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="card-footer clearfix">
                      <NavLink
                        to={"/transacoes/"}
                        className="btn btn-sm btn-info float-left"
                      >
                        Ver Todos
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header border-transparent">
                    <h3 className="card-title" style={{ fontWeight: "600" }}>
                      ÚLTIMOS EVENTOS
                    </h3>

                    <div className="card-tools" />
                  </div>

                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table className="table m-0">
                        <thead>
                          <tr>
                            <th>Data Hora</th>
                            <th>Descrição</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.eventos &&
                            this.state.eventos.map((evento) => (
                              <tr key={evento.id}>
                                <td>
                                  <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {evento.dataaltera}
                                  </Moment>
                                </td>
                                <td>
                                  <span>{evento.descricao}</span>
                                </td>
                                <td>
                                  {evento.status === "ok" && (
                                    <span
                                      className={"badge badge-success"}
                                      style={{ width: "60%" }}
                                    >
                                      {evento.status}
                                    </span>
                                  )}
                                  {evento.status === "erro" && (
                                    <span
                                      className={"badge badge-danger"}
                                      style={{ width: "60%" }}
                                    >
                                      {evento.status}
                                    </span>
                                  )}
                                  {evento.status === "info" && (
                                    <div>
                                      <span
                                        className={"badge badge-info"}
                                        style={{ width: "60%" }}
                                      >
                                        {evento.status}
                                      </span>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="card-footer clearfix">
                    <NavLink
                      to={"/eventos/"}
                      className="btn btn-sm btn-info float-left"
                    >
                      Ver Todos
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
